<template>
    <div>
        <p style="margin-left: -0.2rem; font-size: 12px;">v2.11.3 - Página atualizada às {{ horaAtual }}</p>
    </div>
</template>

<script>
    export default {
        name: 'Versao',
        props: ['horaAtual']
    }
</script>