<template>
    <div>
        <MarcaDev />
        <Loader v-if="carregando" />
        <div v-show="autenticado && !carregando" style="padding-bottom: 40px">
            <section class="section">
                <div class="container">
                    <div class="barra-superior">
                        <div style="display: flex; align-items: center; justify-content: space-between; gap: 2rem;">
                            <img class="amplify-image" alt="Logo" src="/logo.png" style="width: 150px; margin-left: 1.5rem" />
                            <span style="font-size: 24px; margin: auto">Indicadores</span>
                        </div>
                        <div style="margin-right: 2rem; display: flex; gap: 1rem">
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Digitação`" 
                                :onclick="indicador"
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined is-active`" 
                                :titulo="`Pesquisa`" 
                                style="width: 7rem; border-color: #ccc;"
                            />

                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Complemento`" 
                                :onclick="complemento"
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Exportação`" 
                                :onclick="exportacao"
                                v-if="autenticacao.coordenadores || autenticacao.admin"
                                    style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Documentos`" 
                                :onclick="documentos"
                                v-if="autenticacao.admin"
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Endereços`" 
                                :onclick="enderecos"
                                v-if="autenticacao.admin"
                                style="width: 7rem; border-color: #ccc;"
                            />

                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Estatísticas`" 
                                :onclick="estatisticas"
                                v-if="autenticacao.coordenadores || autenticacao.admin"
                                style="width: 7rem; border-color: #ccc;"
                            />

                            <div class="dropdown is-right is-hoverable">
                                <div class="dropdown-trigger">
                                    <button class="button is-secondary" aria-haspopup="true" aria-controls="dropdown-menu3">
                                        <i class="gg-more-vertical-alt"></i>
                                    </button>
                                </div>
                                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                                    <div class="dropdown-content">
                                        <a class="dropdown-item" @click="usuariosPagina()" v-if="autenticacao.admin">
                                            Usuários
                                        </a>
                                        <a class="dropdown-item" @click="sair()">
                                            Sair
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="formulario">                              
                        <div class="columns">
                            <div class="column is-full">
                                <b style="font-size: 22px">Filtros da pesquisa</b>
                            </div>
                            <div class="column is-4 column-date">
                                <InputText
                                    :label="`Data inicial`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="form.dataInicial"
                                />
                                <span>a</span>
                                <InputText
                                    :label="`Data final`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="form.dataFinal"
                                />
                            </div>

                            <div class="column is-3">
                                <InputText
                                    :label="`Número de registro`" 
                                    :preenchido="true"
                                    :numeroRegistro="atualizarNumeroRegistro"
                                    v-model="form.numeroRegistro"
                                />
                            </div>

                            <div class="column is-5">
                                <Select 
                                    :label="`Usuário`" 
                                    :preenchido="true"
                                    :multiple="true"
                                    v-model="form.usuarios"
                                    ref="select"
                                >
                                    <option 
                                        v-for="(usuario, idx) in usuarios" 
                                        :value="usuario"
                                    >{{ usuario }}</option>
                                </Select>
                            </div>   
                            
                            <div class="column is-2">
                                <Select 
                                    :label="`Tipo do registro`" 
                                    :preenchido="true"
                                    v-model="form.tipoRegistro"
                                    ref="tipoRegistro"
                                >
                                    <option value="99">Todos</option>
                                    <option value="1">Matrícula</option>
                                    <option value="2">Matrícula MÃE</option>
                                    <option value="3">Livro 3-reg-aux</option>
                                    <option value="4">Transcrição</option>
                                </Select>
                            </div> 

                            <div class="column is-2">
                                <Select 
                                    :label="`Marcações`" 
                                    :preenchido="true"
                                    v-model="form.marcacoes"
                                    ref="marcacoes"
                                >
                                    <option value="99">Nenhuma opção</option>
                                    <option value="0">Sem marcação</option>
                                    <option value="1">Apenas livro 3 vinculado</option>
                                    <option value="2">Apenas anotações</option>
                                    <option value="3">Livro 3 vinculado e anotações</option>
                                </Select>
                            </div>            

                            <div class="column is-2">
                                <Select 
                                    :label="`Buscar por rascunho`" 
                                    :preenchido="true"
                                    v-model="form.rascunho"
                                    ref="rascunho"
                                >
                                    <option value="99">Ambos</option>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </Select>
                            </div>          

                            <div class="column is-2">
                                <Select 
                                    :label="`Ordenar por`" 
                                    :preenchido="true"
                                    v-model="form.campoOrdenacao"
                                    ref="campoOrdenacao"
                                >
                                    <option value="numeroRegistroInt">Número do registro</option>
                                    <option value="dataAtualizacao">Data da última atualização</option>
                                </Select>
                            </div>           

                            <div class="column is-2">
                                <Select 
                                    :label="`Tipo da ordenação`" 
                                    :preenchido="true"
                                    v-model="form.tipoOrdenacao"
                                    ref="tipoOrdenacao"
                                >
                                    <option value="-1">Decrescente</option>
                                    <option value="1">Ascendente</option>
                                </Select>
                            </div>            

                            <div class="column is-2">
                                <Select 
                                    :label="`Alerta`" 
                                    :preenchido="true"
                                    v-model="form.alerta"
                                    v-if="autenticacao.admin"
                                    ref="alerta"
                                >
                                    <option value="0">Nenhum</option>
                                    <option value="1">Personalizado</option>
                                </Select>
                            </div>  
                            
                            <div class="column is-half" v-if="form.alerta == 1">
                                <Textarea
                                    :label="`Busca personalizada`" 
                                    :preenchido="true"
                                    v-model="form.buscaPersonalizada"
                                />
                            </div>          

                            <div class="column is-2">
                                <Select 
                                    :label="`Buscar por conferido`" 
                                    :preenchido="true"
                                    v-model="form.conferido"
                                    ref="conferido"
                                >
                                    <option value="99">Ambos</option>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </Select>
                            </div>          

                            <div class="column is-2 full-multiselect">
                                <Select 
                                    :label="`Buscar por registro exportado`" 
                                    :preenchido="true"
                                    :multiple="true"
                                    v-model="form.exportacaoONR"
                                    ref="exportacaoONR"
                                >
                                    <option value="1">Exportado</option>
                                    <option value="2">Exportação desatualizada</option>
                                    <option value="0">Não exportada</option>
                                </Select>
                            </div>           

                            <div class="column is-2">
                                <Select 
                                    :label="`Buscar por inconsistência`" 
                                    :preenchido="true"
                                    v-model="form.inconsistencia"
                                    ref="inconsistencia"
                                >
                                    <option value="99">Todos</option>
                                    <option value="1">1 - CPF com inconsistência</option>
                                    <option value="2">2 - CIC em conjunto</option>
                                    <option value="3">3 - Nome diferente</option>
                                    <option value="4">4 - CIC dependente</option>
                                    <option value="5">5 - CPF dependente</option>
                                    <option value="6">6 - CNPJ com inconsistência</option>
                                    <option value="7">7 - Sem CPF (Decisão Judicial)</option>
                                    <option value="8">8 - Sem CNPJ (Decisão Judicial)</option>
                                    <option value="9">9 - Não consta no documento</option>
                                </Select>
                            </div>           

                            <div class="column is-2">
                                <Select 
                                    :label="`Buscar por movimento do dia`" 
                                    :preenchido="true"
                                    v-model="form.matriculaNova"
                                    ref="matriculaNova"
                                >
                                    <option value="99">Todos</option>
                                    <option value="1">Sim</option>
                                    <option value="2">Não</option>
                                    <option value="3">Não informado</option>
                                </Select>
                            </div> 
                            
                            <div class="column is-4 column-date">
                                <InputText
                                    :label="`Data inicial do ato`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="form.dataInicialAto"
                                />
                                <span>a</span>
                                <InputText
                                    :label="`Data final do ato`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="form.dataFinalAto"
                                />
                            </div>

                            <div class="column is-12" style="gap: 1rem; display: flex; justify-content: flex-end;">
                                <Button
                                    :classes="`is-danger is-outlined btn-limpar`" 
                                    :icone="`trash`" 
                                    :titulo="`Limpar filtro`" 
                                    :onclick="limparFiltro" 
                                    :parametro1="1"
                                    v-show="pesquisado"
                                    style="width: 15rem;"
                                />

                                <Button
                                    :classes="`is-link is-outlined btn-pesquisar`" 
                                    :icone="`search`" 
                                    :titulo="`Pesquisar`" 
                                    :onclick="pesquisar" 
                                    :parametro1="true" 
                                    :parametro2="true" 
                                    style="width: 15rem;"
                                />
                            </div>
                        </div>

                        <hr style="margin-top: -0.5rem;">

                        <div style="position: absolute; right: 20px;" v-if="autenticacao.escreventes || autenticacao.coordenadores || autenticacao.admin">
                            <div class="dropdown is-right is-hoverable" v-show="totalRegistros > 0 && !carregandoBusca">
                                <div class="dropdown-trigger">
                                    <button class="button is-purple" aria-haspopup="true" aria-controls="dropdown-menu3">
                                        <span>Exportar dados</span>
                                    </button>
                                </div>
                                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                                    <div class="dropdown-content">
                                    <a class="dropdown-item" @click="exportar('csv')" v-if="autenticacao.escreventes || autenticacao.coordenadores || autenticacao.admin">
                                        Listagem em CSV
                                    </a>
                                    <a class="dropdown-item" @click="exportarOnr()" v-if="autenticacao.admin">
                                        Dados ONR em JSON
                                    </a>
                                    <a class="dropdown-item" @click="exportarDoi()" v-if="autenticacao.escreventes || autenticacao.admin">
                                        DOI
                                    </a>
                                    <a class="dropdown-item" @click="exportarAtos()" v-if="autenticacao.coordenadores || autenticacao.escreventes || autenticacao.admin">
                                        Relatório de atos
                                    </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Loader 
                            v-if="carregandoBusca" 
                            style="margin-bottom: 2rem;"
                            :altura="20"
                        />
                            
                        <div class="columns column-table column-nao-encontrado" v-show="totalRegistros == 0 && !carregandoBusca">
                            <div>Nenhum registro encontrado</div>
                        </div>
                        
                        <Pagination 
                            :contadorPaginas.sync="contadorPaginas" 
                            :totalRegistros.sync="totalRegistros" 
                            :paginaAtual.sync="paginaAtual" 
                            :paginas.sync="paginas" 
                            :totalPaginas.sync="totalPaginas"
                            :numeroExibicaoRegistros.sync="numeroExibicaoRegistros"
                        v-show="dados != false"
                        />
                        
                        <div class="columns column-table" v-show="dados != false">                                        
                            <table class="table is-fullwidth is-hoverable">
                                <thead>
                                    <tr>
                                        <th style="min-width: 105px; width: 10%;">Número do registro</th>
                                        <th style="min-width: 135px; width: 10%;">Tipo do registro</th>
                                        <th style="min-width: 100px; width: 8%;">CNM</th>
                                        <th style="min-width: 125px; width: 12%;">Data da última atualização</th>
                                        <!-- <th style="min-width: 175px; width: 10%;">Marcações</th> -->
                                        <th style="min-width: 175px; width: 11%;">Usuário</th>
                                        <th style="min-width: 130px; width: 5%;">Observação</th>
                                        <th style="min-width: 60px; width: 4%; text-align: center;">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(dado, idx) in dados">
                                        <td>{{ String(dado.numeroRegistro).padStart(8, '0').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</td>
                                        <td>{{ tiposRegistro[dado.tipoRegistro] }}</td>
                                        <td>{{ dado.cnm }}</td>
                                        <td>{{ dado.dataAtualizacao }}</td>
                                        <!-- <td v-html="`${dado.livroVinculado == 1 ? 'Livro 3 vinculado' : ''} ${dado.anotacoes == 1 && dado.livroVinculado == 1 ? '<br>' : ''} ${dado.anotacoes == 1 ? 'Anotações' : ''} ${dado.anotacoes != 1 && dado.livroVinculado != 1 ? 'Nenhuma' : ''}`"></td> -->
                                        <td>{{ dado.usuario }}</td>
                                        <td>
                                            <Button
                                                :classes="`is-gray is-small`" 
                                                :titulo="`Rascunho`" 
                                                style="margin: 2px; width: 65px;"
                                                v-if="dado.rascunho"
                                            />
                                            <Button
                                                :classes="`is-success is-small`" 
                                                :icone="`check`" 
                                                :titulo="``" 
                                                title="Conferido"
                                                style="margin: 2px; width: 30px;"
                                                v-if="dado.conferido"
                                            />
                                            <Button
                                                :classes="dado.exportacaoONR == 1 ? 'is-purple is-small' : 'is-gray is-small'" 
                                                :icone="`software-download`" 
                                                :titulo="``" 
                                                :title="dado.exportacaoONR == 1 ? 'Exportado' : 'Exportação desatualizada'"
                                                style="margin: 2px; width: 30px; transform: rotate(180deg);"
                                                v-if="dado.exportacaoONR"
                                            />
                                            <Button
                                                :classes="`is-warning-alt is-outlined is-small`" 
                                                :titulo="`Inativo`" 
                                                style="margin: 2px; width: 65px;"
                                                v-if="dado.inativo ?? false"
                                            />
                                        </td>
                                        <td style="text-align: center">
                                            <div class="dropdown is-right is-hoverable">
                                                <div class="dropdown-trigger">
                                                    <button class="button" aria-haspopup="true" aria-controls="dropdown-menu3">
                                                        <span class="icon is-small">
                                                            <span>&#9881;</span>
                                                        </span>
                                                        <span>Opções</span>
                                                    </button>
                                                </div>
                                                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                                                    <div class="dropdown-content">
                                                        <a class="dropdown-item" @click="visualizarRegistro(dado.numeroRegistro, dado.tipoRegistro)">
                                                            Visualizar
                                                        </a>
                                                        <a class="dropdown-item" @click="editarRegistro(dado.numeroRegistro, dado.tipoRegistro)" v-if="!dado.inativo">
                                                            Editar
                                                        </a>
                                                        <a class="dropdown-item" @click="adicionarAtos(dado.numeroRegistro, dado.tipoRegistro)" v-if="!dado.inativo">
                                                            Adicionar atos
                                                        </a>
                                                        <a class="dropdown-item" @click="visualizarRegistro(dado.numeroRegistro, dado.tipoRegistro, true)">
                                                            Conferir
                                                        </a>
                                                        <a class="dropdown-item" @click="copiarRegistro(dado.numeroRegistro, dado.tipoRegistro)" v-if="!dado.inativo">
                                                            Copiar
                                                        </a>
                                                        <a class="dropdown-item" @click="alterarIndicador('inativar', dado._id, dado.numeroRegistro)" v-if="!dado.inativo">
                                                            Inativar
                                                        </a>
                                                        <a class="dropdown-item" @click="alterarIndicador('ativar', dado._id, dado.numeroRegistro)" v-if="dado.inativo ?? false">
                                                            Ativar
                                                        </a>
                                                        <a class="dropdown-item" @click="alterarIndicador('excluir', dado._id, dado.numeroRegistro)" v-if="dado.inativo ?? false">
                                                            Excluir
                                                        </a>
                                                        <a class="dropdown-item" @click="historico(dado.historicoUsuario)">
                                                            Histórico
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <hr style="margin-top: -0.5rem; margin-bottom: 0.5rem;">

                        <Pagination 
                            :contadorPaginas.sync="contadorPaginas" 
                            :totalRegistros.sync="totalRegistros" 
                            :paginaAtual.sync="paginaAtual" 
                            :paginas.sync="paginas" 
                            :totalPaginas.sync="totalPaginas"
                            :numeroExibicaoRegistros.sync="numeroExibicaoRegistros"
                        v-show="dados != false"
                        />
                    </div>
                    
                    <div class="formulario" style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 3rem;
                        margin-bottom: 4rem!important;
                    ">
                        <Versao :horaAtual="horaAtual" />
                        
                        <Button
                            :classes="`is-${contagemRegressivaClass} btn-small`" 
                            style="margin-right: -45px; width: 16rem;"
                            :onclick="refreshToken"
                            :titulo="`${contagemRegressiva}`" 
                        />
                    </div>
                </div>
            </section>
        </div>

        <div class="modal" id="modal-confimacao" :class="{ 'is-active': modalLimpeza }">
            <div class="modal-background" @click="modalLimpeza = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Limpar o filtro</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalLimpeza = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja realmente limpar o filtro?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-link is-outlined" tabindex="-1" @click="modalLimpeza = false">Não</button>
                    <button class="button is-danger is-outlined" @click="limparFiltro(0)">Sim</button>
                </footer>
            </div>
        </div>

        <div class="modal" id="modal-confimacao" :class="{ 'is-active': modalAlteracao }">
            <div class="modal-background" @click="modalAlteracao = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Atenção!</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalAlteracao = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja realmente <b>{{ tipoAlteracao }}</b> o registro <b>{{ numeroRegistro }}</b>?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-link is-outlined" tabindex="-1" @click="modalAlteracao = false">Não</button>
                    <button class="button is-success is-outlined" @click="alterarIndicador(tipoAlteracao, uuid, numeroRegistro, false)">Sim</button>
                </footer>
            </div>
        </div>

        <div class="modal" id="modal-confimacao" :class="{ 'is-active': dadosHistorico != false }">
            <div class="modal-background" @click="dadosHistorico = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Histórico de movimentação</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="dadosHistorico = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="columns column-table" v-if="dadosHistorico != null">                                    
                        <table class="table is-fullwidth is-hoverable">
                            <thead>
                                <tr>
                                    <th>Usuário</th>
                                    <th>Tipo da movimentação</th>
                                    <th>Data da movimentação</th>
                                    <th>IP</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dado, idx) in dadosHistorico">
                                    <td>{{ dado.usuario }}</td>
                                    <td>{{ tiposHistorico[dado.tipoAlteracao] ?? dado.tipoAlteracao }}</td>
                                    <td>{{ dado.dataAlteracao }}</td>
                                    <td>{{ dado.ip }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="text-align: center" v-else>    
                        Nenhum histórico registrado!
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: flex-end;">
                    <button class="button is-link is-outlined" tabindex="-1" @click="dadosHistorico = false">Fechar</button>
                </footer>
            </div>
        </div>   

        <div class="modal" id="printable_div" :class="{ 'is-active': visualizacao != null }">
            <div class="modal-background" @click="fecharVisualizacao()"></div>
            <div class="modal-card" style="width: 980px;">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{ visualizacao?.conferencia ? 'Conferência' : 'Visualização' }} - {{ tiposRegistro[visualizacao?.tipoRegistro] ?? '' }} - CNM {{ visualizacao?.cnm }}</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="fecharVisualizacao()"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content printable-div">
                        <p class="modal-card-title titulo-impressao">{{ tiposRegistro[visualizacao?.tipoRegistro] ?? '' }} - CNM {{ visualizacao?.cnm }}</p>
                        <div class="visualizacao-titulo">
                            <b>Indicador real</b>
                        </div>
                        <div class="visualizacao-dados">
                            <p v-if="(visualizacao?.tipoRegistro && visualizacao?.tipoRegistro != '') || visualizacao?.conferencia">
                                Tipo do registro: <b>{{ tiposRegistro[visualizacao?.tipoRegistro] ?? '' }}</b>
                            </p>
                            <p v-if="(visualizacao?.cnm && visualizacao?.cnm != '') || visualizacao?.conferencia">
                                CNM: <b>{{ visualizacao?.cnm }}</b>
                            </p>
                            <p v-if="(visualizacao?.numeroRegistro && visualizacao?.numeroRegistro != '') || visualizacao?.conferencia">
                                Número: <b>{{ String(visualizacao?.numeroRegistro ?? '').padStart(8, '0').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }}</b>
                            </p>
                            <p v-if="(visualizacao?.tipoLivro3 && visualizacao?.tipoLivro3 != '') || visualizacao?.conferencia">
                                Tipo do livro 3: <b>{{ tiposLivro3[visualizacao?.tipoLivro3] ?? '' }}</b>
                            </p>
                            <p v-if="(visualizacao?.letraRegistro && visualizacao?.letraRegistro != '') || visualizacao?.conferencia">
                                Letra complementar: <b>{{ visualizacao?.letraRegistro }}</b>
                            </p>
                            <p v-if="(visualizacao?.dataAbertura && visualizacao?.dataAbertura != '') || visualizacao?.conferencia">
                                Data da abertura: <b>{{ visualizacao?.dataAbertura }}</b>
                            </p>
                            <p v-if="(visualizacao?.vigenciaRegistro && visualizacao?.vigenciaRegistro != '') || visualizacao?.conferencia">
                                Vigência: <b>{{ tiposVigencia[visualizacao?.vigenciaRegistro] ?? '' }}</b>
                            </p>
                            <p v-if="(visualizacao?.livroVinculado && visualizacao?.livroVinculado != '') || visualizacao?.conferencia">
                                Possui livro 3 vinculado: <b>{{ visualizacao?.livroVinculado == 1 ? 'Sim' : 'Não' }}</b>
                            </p>
                            <p v-if="(visualizacao?.descricaoLivroVinculado && visualizacao?.descricaoLivroVinculado != '') || visualizacao?.conferencia" style="flex: 1 1 100%">
                                Descrição do livro 3 vinculado: <b>{{ visualizacao?.descricaoLivroVinculado }}</b>
                            </p>
                            <p v-if="(visualizacao?.anotacoes && visualizacao?.anotacoes != '') || visualizacao?.conferencia">
                                Possui anotações: <b>{{ visualizacao?.anotacoes == 1 ? 'Sim' : 'Não' }}</b>
                            </p>
                            <p v-if="(visualizacao?.descricaoAnotacoes && visualizacao?.descricaoAnotacoes != '') || visualizacao?.conferencia" style="flex: 1 1 100%">
                                Descrição das anotações: <b>{{ visualizacao?.descricaoAnotacoes }}</b>
                            </p>
                            <p v-if="(visualizacao?.livroTranscricao && visualizacao?.livroTranscricao != '') || visualizacao?.conferencia">
                                Livro da transcrição: <b>{{ visualizacao?.livroTranscricao }}</b>
                            </p>
                            <p v-if="(visualizacao?.conferencia || (visualizacao?.folhaTranscricao && visualizacao?.folhaTranscricao != ''))">
                                Folha da transcrição: <b>{{ visualizacao?.folhaTranscricao }}</b>
                            </p>
                            <p v-if="(visualizacao?.onusAtivo && visualizacao?.onusAtivo != '') || visualizacao?.conferencia">
                                Ônus ativo: <b>{{ visualizacao?.onusAtivo == 1 ? 'Sim' : 'Não' }}</b>
                            </p>
                            <p v-if="(visualizacao?.l2Vinculado && visualizacao?.l2Vinculado != '') || visualizacao?.conferencia">
                                L2 vinculado: <b>{{ visualizacao?.l2Vinculado == 1 ? 'Sim' : 'Não' }}</b>
                            </p>
                            <p v-if="(visualizacao?.numerosMatriculas && visualizacao?.numerosMatriculas != '') || visualizacao?.conferencia">
                                Nº da matrícula: <b>{{ visualizacao?.numerosMatriculas.join(', ') }}</b>
                            </p>
                            <p v-if="(visualizacao?.numeroCedula && visualizacao?.numeroCedula != '') || visualizacao?.conferencia">
                                Nº da cédula (contrato): <b>{{ visualizacao?.numeroCedula }}</b>
                            </p>  
                            <p v-if="(visualizacao?.numeroOficio && visualizacao?.numeroOficio != '') || visualizacao?.conferencia">
                                Nº do ofício: <b>{{ visualizacao?.numeroOficio }}</b>
                            </p> 
                            <p v-if="(visualizacao?.dataOficio && visualizacao?.dataOficio != '') || visualizacao?.conferencia">
                                Data do ofício: <b>{{ visualizacao?.dataOficio }}</b>
                            </p>                           
                        </div>
                        
                        <div class="visualizacao-subtitulo" v-if="visualizacao?.beneficiariosLivroVinculado && visualizacao?.beneficiariosLivroVinculado.length > 0 && visualizacao?.livroVinculado == 1">
                            <b>Beneficiário(s) do livro 3 vinculado</b>
                        </div>
                        <div class="visualizacao-dados" v-for="(beneficiario, indice) in visualizacao?.beneficiariosLivroVinculado" v-if="visualizacao?.beneficiariosLivroVinculado && visualizacao?.beneficiariosLivroVinculado.length > 0 && visualizacao?.livroVinculado == 1">
                            <p v-if="(beneficiario.naturezaTitulo && beneficiario.naturezaTitulo != '') || visualizacao?.conferencia">
                                Natureza do título: <b>{{ tiposNatureza[beneficiario.naturezaTitulo] ?? '' }}</b>
                            </p>
                            <p v-if="(beneficiario.numero && beneficiario.numero != '') || visualizacao?.conferencia">
                                Número: <b>{{ beneficiario.numero }}</b>
                            </p>
                            <p v-if="(beneficiario.documento && beneficiario.documento != '') || visualizacao?.conferencia">
                                CPF/CNPJ: <b>{{ beneficiario.documento }}</b>
                            </p>
                            <p style="flex: 1 1 100%"  v-if="(beneficiario.nome && beneficiario.nome != '') || visualizacao?.conferencia">
                                Nome/Razão social: <b>{{ beneficiario.nome }}</b>
                            </p>
                            <hr style="width: 100%; flex: 1 1 100%; margin: 0">
                        </div>

                        <div class="visualizacao-titulo" v-if="visualizacao?.tipoRegistro == 3">
                            <b>Descrição do Reg. Auxiliar</b>
                        </div>
                        <div class="visualizacao-dados" v-if="visualizacao?.tipoRegistro == 3">
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.numeroAto && visualizacao?.descricaoRegistroAuxiliar.numeroAto != '') || visualizacao?.conferencia">
                                Número do ato: <b>{{ visualizacao?.descricaoRegistroAuxiliar.numeroAto ?? '' }}</b>
                            </p>
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.dataRegistro && visualizacao?.descricaoRegistroAuxiliar.dataRegistro != '') || visualizacao?.conferencia">
                                Data do registro: <b>{{ visualizacao?.descricaoRegistroAuxiliar.dataRegistro }}</b>
                            </p> 
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.tipoAto && visualizacao?.descricaoRegistroAuxiliar.tipoAto != '') || visualizacao?.conferencia">
                                Tipo do ato: <b>{{ visualizacao?.descricaoRegistroAuxiliar.tipoAto }}</b>
                            </p> 
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.descricaoAto && visualizacao?.descricaoRegistroAuxiliar.descricaoAto != '') || visualizacao?.conferencia">
                                Descrição do ato: <b>{{ visualizacao?.descricaoRegistroAuxiliar.descricaoAto }}</b>
                            </p> 
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.tipoInstrumento && visualizacao?.descricaoRegistroAuxiliar.tipoInstrumento != '') || visualizacao?.conferencia">
                                Tipo de instrumento: <b>{{ visualizacao?.descricaoRegistroAuxiliar.tipoInstrumento }}</b>
                            </p>   
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.dataInstrumento && visualizacao?.descricaoRegistroAuxiliar.dataInstrumento != '') || visualizacao?.conferencia">
                                Data do instrumento: <b>{{ visualizacao?.descricaoRegistroAuxiliar.dataInstrumento }}</b>
                            </p> 
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.titulo && visualizacao?.descricaoRegistroAuxiliar.titulo != '') || visualizacao?.conferencia">
                                Título: <b>{{ visualizacao?.descricaoRegistroAuxiliar.titulo }}</b>
                            </p> 
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.bensPenhorados && visualizacao?.descricaoRegistroAuxiliar.bensPenhorados != '') || visualizacao?.conferencia">
                                Bens penhorados: <b>{{ visualizacao?.descricaoRegistroAuxiliar.bensPenhorados.join(', ') }}</b>
                            </p> 
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.dataVencimento && visualizacao?.descricaoRegistroAuxiliar.dataVencimento != '') || visualizacao?.conferencia">
                                Data do vencimento: <b>{{ visualizacao?.descricaoRegistroAuxiliar.dataVencimento }}</b>
                            </p> 
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.tipoMoeda && visualizacao?.descricaoRegistroAuxiliar.tipoMoeda != '') || visualizacao?.conferencia">
                                Tipo da moeda: <b>{{ visualizacao?.descricaoRegistroAuxiliar.tipoMoeda }}</b>
                            </p>  
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.valor && visualizacao?.descricaoRegistroAuxiliar.valor != '') || visualizacao?.conferencia">
                                Valor: <b>{{ visualizacao?.descricaoRegistroAuxiliar.valor }}</b>
                            </p>  
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.objetoGarantia && visualizacao?.descricaoRegistroAuxiliar.objetoGarantia != '') || visualizacao?.conferencia">
                                Objeto de garantia: <b>{{ visualizacao?.descricaoRegistroAuxiliar.objetoGarantia.join(', ') }}</b>
                            </p>  
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.denominacaoEmpreendimento && visualizacao?.descricaoRegistroAuxiliar.denominacaoEmpreendimento != '') || visualizacao?.conferencia">
                                Denominação do empreendimento: <b>{{ visualizacao?.descricaoRegistroAuxiliar.denominacaoEmpreendimento }}</b>
                            </p>  
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.valorAtribuido && visualizacao?.descricaoRegistroAuxiliar.valorAtribuido != '') || visualizacao?.conferencia">
                                Valor atribuído: <b>{{ visualizacao?.descricaoRegistroAuxiliar.valorAtribuido }}</b>
                            </p>  
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.contribuintes && visualizacao?.descricaoRegistroAuxiliar.contribuintes.length > 0) || visualizacao?.conferencia">
                                Contribuintes: <b>{{ visualizacao?.descricaoRegistroAuxiliar.contribuintes.join(', ') }}</b>
                            </p>   
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.subdistrito && visualizacao?.descricaoRegistroAuxiliar.subdistrito != '') || visualizacao?.conferencia">
                                Subdistrito: <b>{{ visualizacao?.descricaoRegistroAuxiliar.subdistrito }}</b>
                            </p>  
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.cidade && visualizacao?.descricaoRegistroAuxiliar.cidade != '') || visualizacao?.conferencia">
                                Cidade: <b>{{ visualizacao?.descricaoRegistroAuxiliar.cidade }} - {{ tiposEstados[visualizacao?.descricaoRegistroAuxiliar.uf] ?? '' }}</b>
                            </p>  
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.dataCasamento && visualizacao?.descricaoRegistroAuxiliar.dataCasamento != '') || visualizacao?.conferencia">
                                Data do casamento: <b>{{ visualizacao?.descricaoRegistroAuxiliar.dataCasamento }}</b>
                            </p>  
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.dataUniaoEstavel && visualizacao?.descricaoRegistroAuxiliar.dataUniaoEstavel != '') || visualizacao?.conferencia">
                                Data da união estável: <b>{{ visualizacao?.descricaoRegistroAuxiliar.dataUniaoEstavel }}</b>
                            </p>  
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.numeroProcesso && visualizacao?.descricaoRegistroAuxiliar.numeroProcesso != '') || visualizacao?.conferencia">
                                Número do processo: <b>{{ visualizacao?.descricaoRegistroAuxiliar.numeroProcesso }}</b>
                            </p>  
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.juizo && visualizacao?.descricaoRegistroAuxiliar.juizo != '') || visualizacao?.conferencia">
                                Juízo: <b>{{ visualizacao?.descricaoRegistroAuxiliar.juizo }}</b>
                            </p>  
                            <p style="flex: 1 1 100%" v-if="(visualizacao?.descricaoRegistroAuxiliar.tabelionato && visualizacao?.descricaoRegistroAuxiliar.tabelionato != '') || visualizacao?.conferencia">
                                Tabelionato: <b>{{ visualizacao?.descricaoRegistroAuxiliar.tabelionato }}</b>
                            </p>  
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.livro && visualizacao?.descricaoRegistroAuxiliar.livro != '') || visualizacao?.conferencia">
                                Livro: <b>{{ visualizacao?.descricaoRegistroAuxiliar.livro }}</b>
                            </p>   
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.folha && visualizacao?.descricaoRegistroAuxiliar.folha != '') || visualizacao?.conferencia">
                                Folha: <b>{{ visualizacao?.descricaoRegistroAuxiliar.folha }}</b>
                            </p>  
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.regimeBens && visualizacao?.descricaoRegistroAuxiliar.regimeBens != '') || visualizacao?.conferencia">
                                Regime de bens: <b>{{ tiposRegimeBens[visualizacao?.descricaoRegistroAuxiliar.regimeBens] ?? '' }}</b>
                            </p>  
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.regimeBensAnterior && visualizacao?.descricaoRegistroAuxiliar.regimeBensAnterior != '') || visualizacao?.conferencia">
                                Regime de bens anterior: <b>{{ tiposRegimeBens[visualizacao?.descricaoRegistroAuxiliar.regimeBensAnterior] }}</b>
                            </p>   
                            <p v-if="(visualizacao?.descricaoRegistroAuxiliar.regimeBensAtual && visualizacao?.descricaoRegistroAuxiliar.regimeBensAtual != '') || visualizacao?.conferencia">
                                Regime de bens atual: <b>{{ tiposRegimeBens[visualizacao?.descricaoRegistroAuxiliar.regimeBensAtual] }}</b>
                            </p>                      
                        </div>
                        <div class="visualizacao-subtitulo" v-if="visualizacao?.descricaoRegistroAuxiliar.enderecos && visualizacao?.descricaoRegistroAuxiliar.enderecos.length > 0 && visualizacao?.descricaoRegistroAuxiliar.enderecos[0].cep && visualizacao?.tipoRegistro == 3">
                            <b>Imóvel</b>
                        </div>
                        <div class="visualizacao-dados" v-for="(endereco, indice) in visualizacao?.descricaoRegistroAuxiliar.enderecos" v-if="visualizacao?.descricaoRegistroAuxiliar.enderecos && visualizacao?.descricaoRegistroAuxiliar.enderecos.length > 0 && visualizacao?.descricaoRegistroAuxiliar.enderecos[0].cep && visualizacao?.tipoRegistro == 3">
                            <p v-if="(endereco.tipoImovel && endereco.tipoImovel != '') || visualizacao?.conferencia">
                                Tipo do imóvel: <b>{{ tiposImovel[endereco.tipoImovel] ?? '' }}</b>
                            </p>
                            <p v-if="(endereco.unidade && endereco.unidade != '') || visualizacao?.conferencia">
                                Número: <b>{{ endereco.unidade }}</b>
                            </p>
                            <p v-if="(endereco.setor && endereco.setor != '') || visualizacao?.conferencia">
                                Setor: <b>{{ typeof endereco.setor == 'object' ? endereco.setor.join(', ') : endereco.setor }}</b>
                            </p>
                            <p v-if="(endereco.bloco && endereco.bloco != '') || visualizacao?.conferencia">
                                Bloco: <b>{{ typeof endereco.bloco == 'object' ? endereco.bloco.join(', ') : endereco.bloco }}</b>
                            </p>
                            <p v-if="(endereco.andar && endereco.andar != '') || visualizacao?.conferencia">
                                Andar: <b>{{ endereco.andar }}</b>
                            </p>
                            <p style="flex: 1 1 100%"  v-if="(endereco.nomeCondominio && endereco.nomeCondominio != '' && endereco.loteamentoCondominio == 1) || visualizacao?.conferencia">
                                Nome do empreendimento: <b>{{ endereco.nomeCondominio }}</b>
                            </p>
                            <p style="flex: 1 1 100%"  v-if="(endereco.nomeCondominio && endereco.nomeCondominio != '' && endereco.loteamentoCondominio == 2) || visualizacao?.conferencia">
                                Nome do condomínio: <b>{{ endereco.nomeCondominio }}</b>
                            </p>
                            <p v-if="(endereco.conjuntoCondominio && endereco.conjuntoCondominio != '') || visualizacao?.conferencia">
                                Conjunto do condomínio: <b>{{ endereco.conjuntoCondominio }}</b>
                            </p>
                            <p v-if="(endereco.loteamento && endereco.loteamento != '') || visualizacao?.conferencia">
                                Loteamento: <b>{{ endereco.loteamento }}</b>
                            </p>
                            <p v-if="(endereco.torreEdificio && endereco.torreEdificio != '') || visualizacao?.conferencia">
                                Torre/Edíficio: <b>{{ typeof endereco.torreEdificio == 'object' ? endereco.torreEdificio.join(', ') : endereco.torreEdificio }}</b>
                            </p>
                            <p v-if="(endereco.conjuntoEmpreeendimento && endereco.conjuntoEmpreeendimento != '') || visualizacao?.conferencia">
                                Conjunto/Empreeendimento: <b>{{ endereco.conjuntoEmpreeendimento }}</b>
                            </p>
                            <p v-if="(endereco.vagas && endereco.vagas != '') || visualizacao?.conferencia">
                                Vagas: <b>{{ endereco.vagas }}</b>
                            </p>
                            <div class="visualizacao-titulo" v-if="endereco.cep != ''" style="flex: 1 1 100%; margin-bottom: -5px">
                                <b>Endereço do imóvel</b>
                            </div>
                            <div class="visualizacao-dados" style="flex: 1 1 100%; margin: 0">
                                <p v-if="(endereco.cep && endereco.cep != '') || visualizacao?.conferencia">
                                    <b>
                                        {{ tiposLogradouro[endereco.tipoLogradouro] ?? '' }} 
                                        {{ endereco.nomeLogradouro }}
                                        {{ endereco.numero != '' ? `, ${endereco.numero}` : '' }}
                                        {{ endereco.complemento != '' ? ` - ${endereco.complemento}` : '' }}
                                        {{ endereco.bairro != '' ? ` - ${endereco.bairro}` : '' }}
                                        {{ endereco.cep != '' ? ` - ${endereco.cep}` : '' }}
                                    </b>
                                </p>
                            </div>
                            <div class="visualizacao-dados" style="flex: 1 1 100%; margin: -25px 0px 0px" v-if="endereco.loteQuadras && endereco.loteQuadras.length > 0">
                                <div style="flex: 1 1 100%;" v-if="endereco.loteQuadras && endereco.loteQuadras.length > 0 && loteQuadra.lote != '' && loteQuadra.quadra != ''" v-for="(loteQuadra, idx) in endereco.loteQuadras">
                                    Lote: <b>{{ loteQuadra.lote != '' ? loteQuadra.lote : 'n/a' }}</b> -
                                    Quadra: <b>{{ loteQuadra.quadra != '' ? loteQuadra.lote : 'n/a' }}</b>
                                </div>
                            </div>
                            <hr style="width: 100%; flex: 1 1 100%; margin: 0">
                        </div>

                        <div class="visualizacao-subtitulo" v-if="visualizacao?.tipoRegistro != 3">
                            <b>Imóvel</b>
                        </div>
                        <div class="visualizacao-dados" v-if="visualizacao?.tipoRegistro != 3">
                            <p v-if="(visualizacao?.tipoImovel && visualizacao?.tipoImovel != '') || visualizacao?.conferencia">
                                Localização: <b>{{ visualizacao?.localizacao == 1 ? 'Rural' : 'Urbano' }}</b>
                            </p>
                            <p v-if="(visualizacao?.tipoImovel && visualizacao?.tipoImovel != '') || visualizacao?.conferencia">
                                Tipo do imóvel: <b>{{ tiposImovel[visualizacao?.tipoImovel] ?? '' }}</b>
                            </p>
                            <p v-if="(visualizacao?.unidade && visualizacao?.unidade.length > 0) || visualizacao?.conferencia">
                                Unidade: <b>{{ visualizacao?.unidade.join(', ') }}</b>
                            </p>
                            <p v-if="(visualizacao?.andar && visualizacao?.andar.length > 0) || visualizacao?.conferencia">
                                Andar: <b>{{ visualizacao?.andar.join(', ') }}</b>
                            </p>
                            <p v-if="(visualizacao?.tipoVaga && visualizacao?.tipoVaga != '') || visualizacao?.conferencia">
                                Tipo da vaga: <b>{{ visualizacao?.tipoVaga == 1 ? 'Determinada' : visualizacao?.tipoVaga == 2 ? 'Indeterminada' : 'Sem vaga' }}</b>
                            </p>
                            <p v-if="(visualizacao?.naturezaJuridica && visualizacao?.naturezaJuridica != '') || visualizacao?.conferencia">
                                Natureza jurídica: <b>{{ visualizacao?.naturezaJuridica == '' ? '' : visualizacao?.naturezaJuridica == 1 ? 'Comum' : visualizacao?.naturezaJuridica == 2 ? 'Acessória' : 'Autônoma' }}</b>
                            </p>
                            <p v-if="(visualizacao?.vagas && visualizacao?.vagas != '') || visualizacao?.conferencia">
                                Qtde. vagas: <b>{{ visualizacao?.vagas }}</b>
                            </p>
                            <p v-if="(visualizacao?.numeroVagas && visualizacao?.numeroVagas.length > 0) || visualizacao?.conferencia">
                                Número da vaga: <b>{{ visualizacao?.numeroVagas.join(', ') }}</b>
                            </p>
                            <p v-if="(visualizacao?.andarVaga && visualizacao?.andarVaga.length > 0) || visualizacao?.conferencia">
                                Andar da vaga: <b>{{ visualizacao?.andarVaga.join(', ') }}</b>
                            </p>
                            <p v-if="(visualizacao?.bloco && visualizacao?.bloco.length > 0) || visualizacao?.conferencia">
                                Bloco: <b>{{ visualizacao?.bloco.join(', ') }}</b>
                            </p>
                            <p v-if="(visualizacao?.torre && visualizacao?.torre.length > 0) || visualizacao?.conferencia">
                                Torre/Edíficio: <b>{{ visualizacao?.torre.join(', ') }}</b>
                            </p>
                            <p v-if="(visualizacao?.setor && visualizacao?.setor.length > 0) || visualizacao?.conferencia">
                                Setor: <b>{{ visualizacao?.setor.join(', ') }}</b>
                            </p>
                            <p style="flex: 1 1 100%"  v-if="(visualizacao?.nomeCondominio && visualizacao?.nomeCondominio != '') || visualizacao?.conferencia">
                                Nome do condomínio: <b>{{ visualizacao?.nomeCondominio }}</b>
                            </p>
                            <p v-if="(visualizacao?.loteamento && visualizacao?.loteamento != '') || visualizacao?.conferencia">
                                Loteamento: <b>{{ visualizacao?.loteamento }}</b>
                            </p>
                            <p v-if="(visualizacao?.conjunto && visualizacao?.conjunto != '') || visualizacao?.conferencia">
                                Conjunto/Empreendimento: <b>{{ visualizacao?.conjunto }}</b>
                            </p>
                            <div class="visualizacao-titulo" v-if="visualizacao?.enderecos.length > 0" style="flex: 1 1 100%; margin-bottom: -5px">
                                <b>Endereço do imóvel</b>
                            </div>
                            <div class="visualizacao-dados" :style="`flex: 1 1 100%; margin: 0; padding-bottom: ${indice == visualizacao?.enderecos.length - 1 ? '-1' : '0'};`" v-for="(endereco, indice) in visualizacao?.enderecos">
                                <p v-if="endereco.cep && endereco.cep != ''" style="flex: 1 1 100%;">
                                    <b>
                                        {{ tiposLogradouro[endereco.tipoLogradouro] ?? '' }} 
                                        {{ endereco.nomeLogradouro }}, 
                                        {{ endereco.numero }}
                                        {{ endereco.complemento != '' ? ` - ${endereco.complemento}` : '' }}
                                        {{ endereco.bairro != '' ? ` - ${endereco.bairro}` : '' }}
                                        {{ endereco.cep != '' ? ` - ${endereco.cep}` : '' }}
                                    </b>
                                </p>    
                                <div style="flex: 1 1 100%;" v-if="endereco.loteQuadras && endereco.loteQuadras.length > 0" v-for="(loteQuadra, idx) in endereco.loteQuadras">
                                    Lote: <b>{{ loteQuadra.lote != '' ? loteQuadra.lote : 'n/a' }}</b> -
                                    Quadra: <b>{{ loteQuadra.quadra != '' ? loteQuadra.lote : 'n/a' }}</b>
                                </div>
                                <hr style="width: 100%; flex: 1 1 100%; margin: 0">
                            </div>
                            <p v-if="(visualizacao?.subdistrito && visualizacao?.subdistrito != '') || visualizacao?.conferencia">
                                Subdistrito: <b>{{ visualizacao?.subdistrito }}</b>
                            </p>
                            <p v-if="(visualizacao?.cidade && visualizacao?.cidade != '') || visualizacao?.conferencia">
                                Cidade: <b>{{ visualizacao?.cidade }} - {{ tiposEstados[visualizacao?.uf] ?? '' }}</b>
                            </p>
                            <p v-if="(visualizacao?.fracaoAreaComum && visualizacao?.fracaoAreaComum != '') || visualizacao?.conferencia">
                                Área privativa: <b>{{ visualizacao?.fracaoAreaComum }}</b>
                            </p>
                            <p v-if="(visualizacao?.fracaoTempo && visualizacao?.fracaoTempo != '') || visualizacao?.conferencia">
                                Área total: <b>{{ visualizacao?.fracaoTempo }}</b>
                            </p>
                            <p v-if="(visualizacao?.contribuintes && visualizacao?.contribuintes.length > 0) || visualizacao?.conferencia">
                                Contribuintes: <b>{{ visualizacao?.contribuintes.join(', ') }}</b>
                            </p>
                            <p v-if="(visualizacao?.situacaoConstrucao && visualizacao?.situacaoConstrucao != '') || visualizacao?.conferencia">
                                Situação da construção: <b>{{ visualizacao?.situacaoConstrucao == 0 ? 'Construção Averbada' : visualizacao?.situacaoConstrucao == 1 ? 'Em construção' : visualizacao?.situacaoConstrucao == 2 ? 'Inscrição' : 'Sem construção' }}</b>
                            </p>
                            <p v-if="(visualizacao?.sncrCcir && visualizacao?.sncrCcir != '') || visualizacao?.conferencia">
                                SNCR/CCIR: <b>{{ visualizacao?.sncrCcir }}</b>
                            </p>
                            <p v-if="(visualizacao?.car && visualizacao?.car != '') || visualizacao?.conferencia">
                                CAR: <b>{{ visualizacao?.car }}</b>
                            </p>
                            <p v-if="(visualizacao?.denominacaoRural && visualizacao?.denominacaoRural != '') || visualizacao?.conferencia">
                                Denominação rural: <b>{{ visualizacao?.denominacaoRural }}</b>
                            </p>
                            <p v-if="(visualizacao?.numeroIncra && visualizacao?.numeroIncra != '') || visualizacao?.conferencia">
                                Número INCRA: <b>{{ visualizacao?.numeroIncra }}</b>
                            </p>
                            <p v-if="(visualizacao?.acidenteGeografico && visualizacao?.acidenteGeografico != '') || visualizacao?.conferencia">
                                Acidente geográfico: <b>{{ visualizacao?.acidenteGeografico }}</b>
                            </p>
                            <p v-if="(visualizacao?.cib && visualizacao?.cib != '') || visualizacao?.conferencia">
                                CIB: <b>{{ visualizacao?.cib }}</b>
                            </p>
                            <p v-if="(visualizacao?.area && visualizacao?.area != '') || visualizacao?.conferencia">
                                Área: <b>{{ visualizacao?.area }}</b>
                            </p>
                            <p v-if="(visualizacao?.sigef && visualizacao?.sigef != '') || visualizacao?.conferencia">
                                SIGEF: <b>{{ visualizacao?.sigef }}</b>
                            </p>
                            <p v-if="(visualizacao?.nirf && visualizacao?.nirf != '') || visualizacao?.conferencia">
                                NIRF: <b>{{ visualizacao?.nirf }}</b>
                            </p>
                        </div>

                        <div class="visualizacao-titulo" v-if="visualizacao?.descricaoRegistroAuxiliar.pessoal && visualizacao?.descricaoRegistroAuxiliar.pessoal.length > 0">
                            <b>Partes</b>
                        </div>
                        <div class="visualizacao-dados" v-for="(pessoal, indice) in visualizacao?.descricaoRegistroAuxiliar.pessoal">
                            <p v-if="(pessoal.classificacao && pessoal.classificacao != '') || visualizacao?.conferencia">
                                Classificação: <b>{{ tiposClassificacao[pessoal.classificacao] ?? '' }}</b>
                            </p>
                            <p v-if="(pessoal.tipoDocumento && pessoal.tipoDocumento != '') || visualizacao?.conferencia">
                                Tipo do documento: <b>{{ pessoal.tipoDocumento }}</b>
                            </p>
                            <p v-if="(pessoal.documento && pessoal.documento != '') || visualizacao?.conferencia">
                                Nº do documento: <b>{{ pessoal.documento }}</b>
                            </p>
                            <p style="flex: 1 1 100%"  v-if="(pessoal.nome && pessoal.nome != '') || visualizacao?.conferencia">
                                Nome/Razão social: <b>{{ pessoal.nome }}</b>
                            </p>
                            <p v-if="(pessoal.nacionalidade && pessoal.nacionalidade != '') || visualizacao?.conferencia">
                                Nacionalidade: <b>{{ tiposNacionalidade[pessoal.nacionalidade] ?? '' }}</b>
                            </p>
                            <p v-if="(pessoal.estadoCivil && pessoal.estadoCivil != '') || visualizacao?.conferencia">
                                Estado civil: <b>{{ tiposEstadosCivil[pessoal.estadoCivil] ?? '' }}</b>
                            </p>
                            <p v-if="(pessoal.regimeBens && pessoal.regimeBens != '') || visualizacao?.conferencia">
                                Regime de bens: <b>{{ tiposRegimeBens[pessoal.regimeBens] ?? '' }}</b>
                            </p>
                            <p v-if="(pessoal.uniaoEstavel && pessoal.uniaoEstavel != '') || visualizacao?.conferencia">
                                União estável: <b>{{ pessoal.uniaoEstavel == 1 ? 'Sim' : 'Não' }}</b>
                            </p>
                            <p v-if="(pessoal.inconsistencia && pessoal.inconsistencia != '') || visualizacao?.conferencia">
                                Inconsistência: <b>{{ tiposInconsistencia[pessoal.inconsistencia] ?? '' }}</b>
                            </p>
                            <p v-if="(pessoal.tipoDocumentoConjuge && pessoal.tipoDocumentoConjuge != '') || visualizacao?.conferencia">
                                Tipo do documento do cônjuge: <b>{{ pessoal.tipoDocumentoConjuge }}</b>
                            </p>
                            <p v-if="(pessoal.documentoConjuge && pessoal.documentoConjuge != '') || visualizacao?.conferencia">
                                Nº do documento do cônjuge: <b>{{ pessoal.documentoConjuge }}</b>
                            </p>
                            <p style="flex: 1 1 100%" v-if="(pessoal.nomeConjuge && pessoal.nomeConjuge != '') || visualizacao?.conferencia">
                                Nome do cônjuge: <b>{{ pessoal.nomeConjuge }}</b>
                            </p>
                            <p v-if="pessoal.nomes && pessoal.nomes.length > 0" style="flex: 1 1 100%">
                                Nome(s) semelhante(s) da parte: <b>{{ pessoal.nomes.join(' / ') }}</b>
                            </p>
                            <hr style="width: 100%; flex: 1 1 100%; margin: 0">
                        </div>
                        
                        <div class="visualizacao-titulo" v-if="visualizacao?.descricaoRegistroAuxiliar.averbacoes && visualizacao?.descricaoRegistroAuxiliar.averbacoes.length > 0 && visualizacao?.descricaoRegistroAuxiliar.averbacoes[0].numeroAto && visualizacao?.descricaoRegistroAuxiliar.averbacoes[0].numeroAto != ''">
                            <b>Atos</b>
                        </div>
                        <div class="visualizacao-dados" v-for="(averbacao, indice) in visualizacao?.descricaoRegistroAuxiliar.averbacoes" v-if="(averbacao.numeroAto && averbacao.numeroAto != '') || visualizacao?.conferencia">
                            <p v-if="(averbacao.numeroAto && averbacao.numeroAto != '') || visualizacao?.conferencia">
                                Nº do ato: <b>{{ averbacao.numeroAto }}</b>
                            </p>
                            <p v-if="(averbacao.dataRegistro && averbacao.dataRegistro != '') || visualizacao?.conferencia">
                                Data do ato: <b>{{ averbacao.dataRegistro }}</b>
                            </p>
                            <p v-if="(averbacao.descricaoAto && averbacao.descricaoAto != '') || visualizacao?.conferencia">
                                Descrição do ato: <b>{{ averbacao.descricaoAto }}</b>
                            </p>
                            <p v-if="(averbacao.dataInstrumento && averbacao.dataInstrumento != '') || visualizacao?.conferencia">
                                Data do instrumento: <b>{{ averbacao.dataInstrumento }}</b>
                            </p>
                            <div class="visualizacao-titulo" style="flex: 1 1 100%;" v-if="averbacao.pessoal && averbacao.pessoal.length > 0">
                                <b>Partes</b>
                            </div>
                            <div class="visualizacao-dados" style="flex: 1 1 100%;" v-for="(pessoal, idx) in averbacao.pessoal">
                                <p v-if="(pessoal.classificacao && pessoal.classificacao != '') || visualizacao?.conferencia">
                                    Classificação: <b>{{ tiposClassificacao[pessoal.classificacao] ?? '' }}</b>
                                </p>
                                <p v-if="(pessoal.tipoDocumento && pessoal.tipoDocumento != '') || visualizacao?.conferencia">
                                    Tipo do documento: <b>{{ pessoal.tipoDocumento }}</b>
                                </p>
                                <p v-if="(pessoal.documento && pessoal.documento != '') || visualizacao?.conferencia">
                                    Nº do documento: <b>{{ pessoal.documento }}</b>
                                </p>
                                <p style="flex: 1 1 100%"  v-if="(pessoal.nome && pessoal.nome != '') || visualizacao?.conferencia">
                                    Nome/Razão social: <b>{{ pessoal.nome }}</b>
                                </p>
                                <p v-if="(pessoal.nacionalidade && pessoal.nacionalidade != '') || visualizacao?.conferencia">
                                    Nacionalidade: <b>{{ tiposNacionalidade[pessoal.nacionalidade] ?? '' }}</b>
                                </p>
                                <p v-if="(pessoal.estadoCivil && pessoal.estadoCivil != '') || visualizacao?.conferencia">
                                    Estado civil: <b>{{ tiposEstadosCivil[pessoal.estadoCivil] ?? '' }}</b>
                                </p>
                                <p v-if="(pessoal.regimeBens && pessoal.regimeBens != '') || visualizacao?.conferencia">
                                    Regime de bens: <b>{{ tiposRegimeBens[pessoal.regimeBens] ?? '' }}</b>
                                </p>
                                <p v-if="(pessoal.uniaoEstavel && pessoal.uniaoEstavel != '') || visualizacao?.conferencia">
                                    União estável: <b>{{ pessoal.uniaoEstavel == 1 ? 'Sim' : 'Não' }}</b>
                                </p>
                                <p v-if="(pessoal.inconsistencia && pessoal.inconsistencia != '') || visualizacao?.conferencia">
                                    Inconsistência: <b>{{ tiposInconsistencia[pessoal.inconsistencia] ?? '' }}</b>
                                </p>
                                <p v-if="(pessoal.tipoDocumentoConjuge && pessoal.tipoDocumentoConjuge != '') || visualizacao?.conferencia">
                                    Tipo do documento do cônjuge: <b>{{ pessoal.tipoDocumentoConjuge }}</b>
                                </p>
                                <p v-if="(pessoal.documentoConjuge && pessoal.documentoConjuge != '') || visualizacao?.conferencia">
                                    Nº do documento do cônjuge: <b>{{ pessoal.documentoConjuge }}</b>
                                </p>
                                <p style="flex: 1 1 100%" v-if="(pessoal.nomeConjuge && pessoal.nomeConjuge != '') || visualizacao?.conferencia">
                                    Nome do cônjuge: <b>{{ pessoal.nomeConjuge }}</b>
                                </p>
                                <p v-if="pessoal.nomes && pessoal.nomes.length > 0" style="flex: 1 1 100%">
                                    Nome(s) semelhante(s) da parte: <b>{{ pessoal.nomes.join(' / ') }}</b>
                                </p>
                                <hr style="width: 100%; flex: 1 1 100%; margin: 0" v-if="idx != averbacao.pessoal.length - 1">
                            </div>
                            <hr style="width: 100%; flex: 1 1 100%; margin: 0">
                        </div>

                        <div class="visualizacao-titulo" v-if="visualizacao?.registroAnterior && visualizacao?.registroAnterior.length > 0">
                            <b>Registro anterior</b>
                        </div>
                        <div class="visualizacao-dados" v-for="(registroAnterior, indice) in visualizacao?.registroAnterior">
                            <p v-if="(registroAnterior.tipoRegistroAnterior && registroAnterior.tipoRegistroAnterior != '') || visualizacao?.conferencia">
                                Tipo: <b>{{ tiposRegistro[registroAnterior.tipoRegistroAnterior] }}</b>
                            </p>
                            <p v-if="(registroAnterior.ato && registroAnterior.ato != '') || visualizacao?.conferencia">
                                Ato: <b>{{ registroAnterior.ato }}</b>
                            </p>
                            <p v-if="(registroAnterior.numeroRegistroAnterior && registroAnterior.numeroRegistroAnterior != '') || visualizacao?.conferencia">
                                Número: <b>{{ registroAnterior.numeroRegistroAnterior }}</b>
                            </p>
                            <p v-if="(registroAnterior.dataRegistroAnterior && registroAnterior.dataRegistroAnterior != '') || visualizacao?.conferencia">
                                Data do registro: <b>{{ registroAnterior.dataRegistroAnterior }}</b>
                            </p>
                            <p v-if="(registroAnterior.cnsRegistroAnterior && registroAnterior.cnsRegistroAnterior != '') || visualizacao?.conferencia">
                                CNS: <b>{{ registroAnterior.cnsRegistroAnterior }}</b>
                            </p>
                            <p v-if="(registroAnterior.orgaoEmissor && registroAnterior.orgaoEmissor != '') || visualizacao?.conferencia">
                                Órgão emissor: <b>{{ registroAnterior.orgaoEmissor }}</b>
                            </p>
                            <div class="visualizacao-titulo" style="flex: 1 1 100%;" v-if="registroAnterior.beneficiario && registroAnterior.beneficiario.length > 0 && registroAnterior.beneficiario[0]?.documento && registroAnterior.beneficiario[0]?.documento != ''">
                                <b>Beneficiário</b>
                            </div>
                            <div class="visualizacao-dados" style="flex: 1 1 100%;" v-for="(beneficiario, indice) in registroAnterior.beneficiario" v-if="beneficiario.documento && beneficiario.documento != ''">
                                <p v-if="(beneficiario.documento && beneficiario.documento != '') || visualizacao?.conferencia">
                                    CPF/CNPJ: <b>{{ beneficiario.documento }}</b>
                                </p>
                                <p style="flex: 1 1 100%"  v-if="(beneficiario.nome && beneficiario.nome != '') || visualizacao?.conferencia">
                                    Nome/Razão social: <b>{{ beneficiario.nome }}</b>
                                </p>
                                <hr style="width: 100%; flex: 1 1 100%; margin: 0" v-if="indice != registroAnterior.beneficiario.length - 1">
                            </div>
                            <hr style="width: 100%; flex: 1 1 100%; margin: 0">
                        </div>

                        <div class="visualizacao-titulo" v-if="visualizacao?.ato && visualizacao?.ato.length > 0 && visualizacao?.ato[0].numeroAto && visualizacao?.ato.length > 0">
                            <b>Atos</b>
                        </div>
                        <div class="visualizacao-dados" v-for="(ato, indice) in visualizacao?.ato">
                            <p v-if="(ato.numeroAto && ato.numeroAto != '') || visualizacao?.conferencia">
                                Número do ato: <b>{{ ato.numeroAto }}</b>
                            </p>
                            <p v-if="(ato.dataRegistro && ato.dataRegistro != '') || visualizacao?.conferencia">
                                Data do registro: <b>{{ ato.dataRegistro }}</b>
                            </p>
                            <p v-if="(ato.descricao && ato.descricao != '') || visualizacao?.conferencia">
                                Tipo do ato: <b>{{ ato.descricao }}</b>
                            </p>
                            <p v-if="(ato.tipo && ato.tipo != '') || visualizacao?.conferencia">
                                Título do ato: <b>{{ tiposAto[ato.tipo] ?? '' }}</b>
                            </p>
                            <p v-if="(ato.descricaoAdicional && ato.descricaoAdicional != '') || visualizacao?.conferencia">
                                Descrição: <b>{{ ato.descricaoAdicional }}</b>
                            </p>
                            <p v-if="(ato.dataInstrumento && ato.dataInstrumento != '') || visualizacao?.conferencia">
                                Data do instrumento: <b>{{ ato.dataInstrumento }}</b>
                            </p>
                            <p v-if="(ato.tipoMoeda && ato.tipoMoeda != '') || visualizacao?.conferencia">
                                Tipo da moeda: <b>{{ tiposMoedas[ato.tipoMoeda] ?? '' }}</b>
                            </p>
                            <p v-if="(ato.valorDeclarado && ato.valorDeclarado != '') || visualizacao?.conferencia">
                                Valor declarado: <b>{{ ato.valorDeclarado }}</b>
                            </p>
                            <p v-if="(ato.valorFiscal && ato.valorFiscal != '') || visualizacao?.conferencia">
                                Valor fiscal: <b>{{ ato.valorFiscal }}</b>
                            </p>
                            <p v-if="(ato.onus && ato.onus != '') || visualizacao?.conferencia">
                                Ônus: <b>{{ ato.onus == 1 ? 'Sim' : 'Não' }}</b>
                            </p>
                            <p v-if="(ato.tipoTransacao && ato.tipoTransacao != '') || visualizacao?.conferencia">
                                Tipo de transação: <b>{{ ato.tipoTransacao }}</b>
                            </p>
                            <p v-if="(ato.cancelamento && ato.cancelamento.length > 0) || visualizacao?.conferencia">
                                Cancelamento: <b>{{ ato.cancelamento.join(', ') }}</b>
                            </p>
                            <p v-if="(ato.seloFiscalizacao && ato.seloFiscalizacao != '') || visualizacao?.conferencia">
                                Selo de fiscalização: <b>{{ ato.seloFiscalizacao }}</b>
                            </p>
                            <div class="visualizacao-titulo" style="flex: 1 1 100%;" v-if="ato.pessoal && ato.pessoal.length > 0">
                                <b>Partes</b>
                            </div>
                            <div class="visualizacao-dados" style="flex: 1 1 100%;" v-for="(pessoal, indice) in ato.pessoal">
                                <p v-if="(pessoal.classificacao && pessoal.classificacao != '') || visualizacao?.conferencia">
                                    Classificação: <b>{{ tiposClassificacao[pessoal.classificacao] ?? '' }}</b>
                                </p>
                                <p v-if="(pessoal.tipoDocumento && pessoal.tipoDocumento != '') || visualizacao?.conferencia">
                                    Tipo do documento: <b>{{ pessoal.tipoDocumento }}</b>
                                </p>
                                <p v-if="(pessoal.documento && pessoal.documento != '') || visualizacao?.conferencia">
                                    Nº do documento: <b>{{ pessoal.documento }}</b>
                                </p>
                                <p style="flex: 1 1 100%"  v-if="(pessoal.nome && pessoal.nome != '') || visualizacao?.conferencia">
                                    Nome/Razão social: <b>{{ pessoal.nome }}</b>
                                </p>
                                <p v-if="(pessoal.fracao && pessoal.fracao != '') || visualizacao?.conferencia">
                                    Fração: <b>{{ pessoal.fracao }}</b>
                                </p>
                                <p v-if="(pessoal.valorFracional && pessoal.valorFracional != '') || visualizacao?.conferencia">
                                    Valor fracional: <b>{{ pessoal.valorFracional }}</b>
                                </p>
                                <p v-if="(pessoal.nacionalidade && pessoal.nacionalidade != '') || visualizacao?.conferencia">
                                    Nacionalidade: <b>{{ tiposNacionalidade[pessoal.nacionalidade] ?? '' }}</b>
                                </p>
                                <p v-if="(pessoal.estadoCivil && pessoal.estadoCivil != '') || visualizacao?.conferencia">
                                    Estado civil: <b>{{ tiposEstadosCivil[pessoal.estadoCivil] ?? '' }}</b>
                                </p>
                                <p v-if="(pessoal.regimeBens && pessoal.regimeBens != '') || visualizacao?.conferencia">
                                    Regime de bens: <b>{{ tiposRegimeBens[pessoal.regimeBens] ?? '' }}</b>
                                </p>
                                <p v-if="(pessoal.uniaoEstavel && pessoal.uniaoEstavel != '') || visualizacao?.conferencia">
                                    União estável: <b>{{ pessoal.uniaoEstavel == 1 ? 'Sim' : 'Não' }}</b>
                                </p>
                                <p v-if="(pessoal.inconsistencia && pessoal.inconsistencia != '') || visualizacao?.conferencia">
                                    Inconsistência: <b>{{ tiposInconsistencia[pessoal.inconsistencia] ?? '' }}</b>
                                </p>
                                <p v-if="(pessoal.tipoDocumentoConjuge && pessoal.tipoDocumentoConjuge != '') || visualizacao?.conferencia">
                                    Tipo do documento do cônjuge: <b>{{ pessoal.tipoDocumentoConjuge }}</b>
                                </p>
                                <p v-if="(pessoal.documentoConjuge && pessoal.documentoConjuge != '') || visualizacao?.conferencia">
                                    Nº do documento do cônjuge: <b>{{ pessoal.documentoConjuge }}</b>
                                </p>
                                <p style="flex: 1 1 100%" v-if="(pessoal.nomeConjuge && pessoal.nomeConjuge != '') || visualizacao?.conferencia">
                                    Nome do cônjuge: <b>{{ pessoal.nomeConjuge }}</b>
                                </p>
                                <p v-if="pessoal.nomes && pessoal.nomes.length > 0" style="flex: 1 1 100%">
                                    Nome(s) semelhante(s) da parte: <b>{{ pessoal.nomes.join(' / ') }}</b>
                                </p>
                                <hr style="width: 100%; flex: 1 1 100%; margin: 0" v-if="indice != ato.pessoal.length - 1">
                            </div>
                            <hr style="width: 100%; flex: 1 1 100%; margin: 0">
                        </div>

                        <div class="visualizacao-titulo" v-if="visualizacao?.proprietarios && visualizacao?.proprietarios.length > 0 && visualizacao?.proprietarios[0].ato && visualizacao?.proprietarios.length > 0">
                            <b>Proprietários atuais do imóvel</b>
                        </div>
                        <div class="visualizacao-dados" v-for="(proprietario, indice) in visualizacao?.proprietarios">
                            <p v-if="(proprietario.ato && proprietario.ato != '') || visualizacao?.conferencia">
                                Ato: <b>{{ proprietario.ato }}</b>
                            </p>
                            <p v-if="(proprietario.data && proprietario.data != '') || visualizacao?.conferencia">
                                Data: <b>{{ proprietario.data }}</b>
                            </p>
                            <p v-if="(proprietario.documento && proprietario.documento != '') || visualizacao?.conferencia">
                                Nº do documento: <b>{{ proprietario.documento }}</b>
                            </p>
                            <p style="flex: 1 1 100%"  v-if="(proprietario.nome && proprietario.nome != '') || visualizacao?.conferencia">
                                Nome/Razão social: <b>{{ proprietario.nome }}</b>
                            </p>
                            <p v-if="(proprietario.classificacao && proprietario.classificacao != '') || visualizacao?.conferencia">
                                Classificação: <b>{{ proprietario.classificacao }}</b>
                            </p>
                            <p v-if="(proprietario.tipoTransacao && proprietario.tipoTransacao != '') || visualizacao?.conferencia">
                                Tipo da transação: <b>{{ proprietario.tipoTransacao }}</b>
                            </p>
                            <br>
                            <hr style="width: 100%; flex: 1 1 100%; margin: 0">
                        </div>

                        <div class="visualizacao-titulo" v-if="visualizacao?.indisponibilidade && visualizacao?.indisponibilidade.length > 0">
                            <b>Indisponibilidade</b>
                        </div>                        
                        <div class="visualizacao-dados" v-for="(indisponibilidade, indice) in visualizacao?.indisponibilidade">
                            <p v-if="(indisponibilidade.ato && indisponibilidade.ato != '') || visualizacao?.conferencia">
                                Ato: <b>{{ indisponibilidade.ato }}</b>
                            </p>
                            <p v-if="(indisponibilidade.dataIndisponibilidade && indisponibilidade.dataIndisponibilidade != '') || visualizacao?.conferencia">
                                Data da indisponibilidade: <b>{{ indisponibilidade.dataIndisponibilidade }}</b>
                            </p>
                            <p v-if="(indisponibilidade.dataRevogacao && indisponibilidade.dataRevogacao != '') || visualizacao?.conferencia">
                                Data da revogação: <b>{{ indisponibilidade.dataRevogacao }}</b>
                            </p>
                            <p v-if="(indisponibilidade.motivo && indisponibilidade.motivo != '') || visualizacao?.conferencia">
                                Motivo: <b>{{ indisponibilidade.motivo }}</b>
                            </p>
                            <p v-if="(indisponibilidade.documento && indisponibilidade.documento != '') || visualizacao?.conferencia">
                                CPF/CNPJ: <b>{{ indisponibilidade.documento }}</b>
                            </p>
                            <p style="flex: 1 1 100%"  v-if="(indisponibilidade.nome && indisponibilidade.nome != '') || visualizacao?.conferencia">
                                Nome/Razão social: <b>{{ indisponibilidade.nome }}</b>
                            </p>
                            <hr style="width: 100%; flex: 1 1 100%; margin: 0">
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <div>
                        <button class="button is-gray is-outlined" tabindex="-1" @click="imprimirDiv()">Imprimir</button>
                        <button class="button is-success is-outlined" tabindex="-1" v-show="(visualizacao?.conferencia ?? false) && !visualizacao?.conferido" @click="alterarIndicador('conferir', visualizacao._id, visualizacao.numeroRegistro)">Marcar como conferido</button>
                        <button class="button is-success" tabindex="-1" v-show="(visualizacao?.conferencia ?? false) && visualizacao?.conferido">Conferido</button>
                    </div>
                    <button class="button is-link is-outlined" tabindex="-1" @click="fecharVisualizacao()">Fechar</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import qs from "qs"
    import moment from "moment"
    import { autenticar } from '../../services/auth.js'

    export default {
        name: "Pesquisa",
        components: {},
        data(){
            return {
                modalLimpeza: false,
                modalAlteracao: false,
                uuid: '',
                tipoAlteracao: '',
                numeroRegistro: '',
                pesquisado: false,
                carregando: false,
                carregandoBusca: false,
                autenticado: true,
                token: {},
                form: {
                    dataInicial: '',
                    dataFinal: '',
                    dataInicialAto: '',
                    dataFinalAto: '',
                    numeroRegistro: '',
                    marcacoes: '',
                    tipoRegistro: '',
                    rascunho: '',
                    conferido: '',
                    inconsistencia: '',
                    matriculaNova: '',
                    exportacaoONR: '',
                    campoOrdenacao: '',
                    tipoOrdenacao: '',
                    alerta: '',
                    buscaPersonalizada: '',
                    usuarios: []
                },
                autenticacao: {
                    autenticacao: false
                },
                tiposVigencia: {
                    '1': 'Ativo (Matrícula aberta e vigente)',
                    '2': 'Encerrado pelo cartório',
                    '3': 'Cancelado Administrativo',
                    '4': 'Cancelado Judicial',
                    '5': 'Requalificado (Prov. 13 CGJ/PA)',
                    '6': 'Inexistente'
                },
                tiposRegistro: {
                    '1': 'Matrícula',
                    '2': 'Matrícula MÃE',
                    '3': 'Livro 3-reg-aux',
                    '4': 'Transcrição'
                },
                tiposLivro3: {
                    '1': 'Cédulas',
                    '2': 'Convenção, instituição e especificação de condomínio',
                    '3': 'Bem de Família',
                    '4': 'Pacto Antenupcial/Pós-Nupcial',
                    '5': 'União Estável',
                    '6': 'Processo administrativo decorrente da falha de numeração',
                    '7': 'Penhor por instrumento público/particular',
                    '8': 'Emissão de debêntures',
                    '9': 'Alteração do regime de bens',
                    '10': 'Tombamento',
                    '11': 'Outros'
                },
                tiposNatureza: {
                    '1': 'Convenção de Condomínio',
                    '2': 'Instituição de Condomínio',
                    '3': 'Especificação de Condomínio',
                    '4': 'Bem de Família',
                    '5': 'Pacto Antenupcial',
                    '6': 'União Estável',
                    '7': 'Cédula de Crédito bancário',
                    '8': 'Cédula de Crédito Comercial',
                    '9': 'Cédula de Crédito Industrial',
                    '10': 'Cédula Rural Hipotecária',
                    '11': 'Cédula Rural Pignoratícia e Hipotecária',
                    '12': 'Cédula Rural Pignoratícia',
                    '13': 'Outros'
                },
                tiposEstados: {
                    '11': 'RO',
                    '12': 'AC',
                    '13': 'AM',
                    '14': 'RR',
                    '15': 'PA',
                    '16': 'AP',
                    '17': 'TO',
                    '21': 'MA',
                    '22': 'PI',
                    '23': 'CE',
                    '24': 'RN',
                    '25': 'PB',
                    '26': 'PE',
                    '27': 'AL',
                    '28': 'SE',
                    '29': 'BA',
                    '31': 'MG',
                    '32': 'ES',
                    '33': 'RJ',
                    '35': 'SP',
                    '41': 'PR',
                    '42': 'SC',
                    '43': 'RS',
                    '50': 'MS',
                    '51': 'MT',
                    '52': 'GO',
                    '53': 'DF'
                },
                tiposRegimeBens: {
                    '1': 'Comunhão de bens',
                    '2': 'Comunhão de bens vigente na Venezuela',
                    '3': 'Comunhão parcial de bens',
                    '4': 'Comunhão parcial de bens às Leis da Noruega',
                    '5': 'Comunhão parcial de bens, à Lei Suiça',
                    '6': 'Comunhão parcial de bens, antes da vigência da Lei 6.515/77',
                    '7': 'Comunhão parcial de bens, na vigência da Lei 6.515/77',
                    '8': 'Comunhão universal de bens',
                    '9': 'Comunhão universal de bens, antes da vigência da Lei 6.515/77',
                    '10': 'Comunhão universal de bens, às Leis de Ângola',
                    '11': 'Comunhão universal de bens, às Leis italianas',
                    '12': 'Comunhão universal de bens, na vigência da Lei 6.515/77',
                    '13': 'Conforme a lei vigente em Israel',
                    '14': 'Leis da Alemanha',
                    '15': 'Leis da Argentina',
                    '16': 'Leis da Austrália',
                    '17': 'Leis da Bolívia',
                    '18': 'Leis da China',
                    '19': 'Leis da Colômbia',
                    '20': 'Leis da Costa do Marfim',
                    '21': 'Leis da Costa Rica',
                    '22': 'Leis da Dinamarca',
                    '23': 'Leis da Espanha',
                    '24': 'Leis da Finlândia',
                    '25': 'Leis da França',
                    '26': 'Leis da Guatemala',
                    '27': 'Leis da Holanda',
                    '28': 'Leis da Inglaterra',
                    '29': 'Leis da Itália',
                    '30': 'Leis da Jordania',
                    '31': 'Leis da Jordânia',
                    '32': 'Leis da Polonia',
                    '33': 'Leis da República da Coréia',
                    '34': 'Leis da Suíça',
                    '35': 'Leis de Angola',
                    '36': 'Leis de Cuba',
                    '37': 'Leis de Moscou',
                    '38': 'Leis de Taiwan',
                    '39': 'Leis do Canadá',
                    '40': 'Leis do Japão',
                    '41': 'Leis do Líbano',
                    '42': 'Leis do Paraguai',
                    '43': 'Leis do Uruguai',
                    '44': 'Leis dos Estados Unidos',
                    '45': 'Leis Egípcias',
                    '46': 'Leis Portuguesas',
                    '47': 'Participação final nos aquestos',
                    '48': 'Regime de bens conforme as Leis americanas',
                    '49': 'Regime vigente no Chile',
                    '50': 'Separação de bens',
                    '51': 'Separação de bens conforme as Leis da Áustria',
                    '52': 'Separação de bens, antes da vigência da Lei 6.515/77',
                    '53': 'Separação de bens, na vigência da Lei 6.515/77',
                    '54': 'Separação obrigatória de bens',
                    '55': 'Separação parcial, antes da vigência da Lei 6.515/77',
                    '56': 'Separação parcial, na vigência da Lei 6.515/77',
                    '57': 'Separação total de bens, na vigência da Lei 6.515/77'
                },
                tiposMoedas: {
                    'Real': '(R$) Real',
                    'Cruzeiro Real': '(CR$) Cruzeiro Real',
                    'Cruzeiro': '(Cr$) Cruzeiro',
                    'Cruzado Novo': '(NCz$) Cruzado Novo',
                    'Cruzado': '(Cz$) Cruzado',
                    'Réis': '(Rs) Réis',
                    'Cruzeiro Novo': '(NCr$) Cruzeiro Novo'
                },
                tiposInconsistencia: {
                    '1': 'CPF com inconsistência',
                    '2': 'CIC em conjunto',
                    '3': 'Nome diferente',
                    '4': 'CIC dependente',
                    '5': 'CPF dependente',
                    '6': 'CNPJ com inconsistência',
                    '7': 'Sem CPF (Decisão Judicial)',
                    '8': 'Sem CNPJ (Decisão Judicial)',
                    '9': 'Não consta no documento'
                },
                tiposAto: {
                    '15': '15 - Adjudicação',
                    '41': '41 - Arrematação em Hasta Pública',
                    '37': '37 - Cessão de Direitos',
                    '11': '11 - Compra e Venda',
                    '19': '19 - Dação em Pagamento',
                    '25': '25 - Desapropriação, exceto para Reforma Agrária',
                    '43': '43 - Dissolução de Sociedade',
                    '21': '21 - Distrato de Negócio',
                    '53': '53 - Doação, exceto em Adiantamento de Legítima',
                    '55': '55 - Doação em adiantamento da legítima',
                    '27': '27 - Herança, Legado ou Meação (adjudicação)',
                    '45': '45 - Incorporação e loteamento',
                    '47': '47 - Integralização/Subscrição de capital',
                    '49': '49 - Partilha em Separação/Divórcio/União Estável',
                    '13': '13 - Permuta',
                    '31': '31 - Procuração em Causa Própria',
                    '35': '35 - Promessa de Cessão de Direitos',
                    '33': '33 - Promessa de Compra e Venda',
                    '51': '51 - Retorno de Capital Próprio na Extinção de Pessoa Jurídica',
                    '99': '99 - Outros',
                    '101': '101 - Usucapião',
                    '102': '102 - Alienação Fiduciária',
                    '103': '103 - Consolidação da Propriedade em Nome do Fiduciário',
                    '104': '104 - Hipoteca',
                    '201': '201 - Encerramento da matrícula pelo cartório',
                    '202': '202 - Cancelamento da matrícula administrativamente',
                    '203': '203 - Cancelamento da matrícula judicialmente',
                    '204': '204 - Requalificação da matrícula (Prov. 13 CGJ/PA)',
                    '205': '205 - Abertura da matrícula',
                    '206': '206 - Averbação',
                    '207': '207 - Cancelamento de ônus',
                    '208': '208 - Compromisso de Compra e Venda',
                    '209': '209 - Sub-rogação',
                    '210': '210 - Transporte de ônus',
                    '211': '211 - Formal de Partilha',
                    '212': '212 - Construção, Reforma ou Ampliação',
                    '213': '213 - Demolição',
                    '214': '214 - Desmembramento',
                    '215': '215 - Atualização de Dados do Imóvel',
                    '216': '216 - Retificação de Dados do Imóvel',
                    '217': '217 - Ex-Officio',
                    '218': '218 - Casamento',
                    '219': '219 - Divórcio',
                    '220': '220 - Separação',
                    '221': '221 - União Estável ',
                    '222': '222 - Aditivo',
                    '223': '223 - Ratificação',
                    '224': '224 - Retificação ',
                    '225': '225 - Óbito',
                    '226': '226 - Confrontações',
                    '227': '227 - Cláusula',
                    '228': '228 - Arresto',
                    '229': '229 - Assunção de Dívida',
                    '230': '230 - Constituição de Sociedade',
                    '231': '231 - Transferência de bens',
                    '232': '232 - Cisão total ou parcial',
                    '233': '233 - Penhora',
                    '234': '234 - Usufruto',
                    '235': '235 - Endosso ',
                    '236': '236 - Locação',
                    '237': '237 - Caução',
                    '238': '238 - Indisponibilidade de bens',
                    '239': '239 - Cédula de Crédito',
                    '240': '240 - Instituição de Condomínio',
                    '241': '241 - Instituição de Bem de Família',
                    '242': '242 - Cessão de Crédito',
                    '243': '243 - Bloqueio de Matrícula ',
                    '244': '244 - Ação de Execução',
                    '245': '245 - Pacto Comissório ',
                    '246': '246 - Desdobro',
                    '247': '247 - Arrolamento de bens',
                    '248': '248 - Divisão ',
                    '249': '249 - Incorporação de Condomínio',
                    '250': '250 - Sequestro',
                    '251': '251 - Servidão',
                    '252': '252 - Penhor',
                    '253': '253 - Alvará de Execução',
                    '254': '254 - Patrimônio de Afetação ',
                    '255': '255 - Unificação',
                    '256': '256 - Domínio',
                    '257': '257 - Nota Promissória ',
                    '258': '258 - Transporte de atos',
                    '259': '259 - Incomunicabilidade (Consórcio - p.5º do art. 5º da lei 11.795/2008)',
                    '260': '260 - Suspensão do procedimento de execução',
                    '261': '261 - Transformação de Natureza Jurídica',
                    '262': '262 - Endosso para efeito de caução',
                    '263': '263 - Concessão de Direito Real de Uso (CDRU)',
                    '264': '264 - Auto de Arrecadação',
                    '265': '265 - Cláusula de Reversão',
                    '266': '266 - Incorporação de Sociedade',
                    '267': '267 - Conferência de bens',
                    '268': '268 - Existência de Ação',
                    '269': '269 - Compra e venda do Usufruto',
                    '270': '270 - Termo de Restauração',
                    '271': '271 - Alteração do Estado Civil',
                    '272': '272 - Retificação do Estado Civil ',
                    '273': '273 - Naturalização',
                    '274': '274 - Emancipação',
                    '275': '275 - Inclusão de Documentação',
                    '276': '276 - Retificação de Documentação',
                    '277': '277 - Alteração de Razão Social',
                    '278': '278 - Atribuição',
                    '279': '279 - Cancelamento parcial de ônus',
                    '280': '280 - Compra e venda da nua-propriedade',
                    '281': '281 - Emissão',
                    '282': '282 - Fusão',
                    '283': '283 - Tombamento',
                    '284': '284 - Cadastro/Cadastro Atual',
                    '285': '285 - Decesso',
                    '289': '289 - Partilha de Bens',
                    '290': '290 - Título de Domínio - TD',
                    '291': '291 - Reserva de Usufruto (Ônus)',
                    '292': '292 - Renúncia de Usufruto (Ônus)',
                    '293': '293 - Cancelamento de Caução (Ônus)',
                    '294': '294 - Mudança de Denominação Social/Alteração da Razão Social',
                    '295': '295 - Instituição de Usufruto',
                    '296': '296 - Proporção Aquisitiva',
                    '297': '297 - Proporção Aquisitiva Atual',
                    '298': '298 - Levantamento/cancelamento de Indisponibilidade',
                    '299': '299 - Decisão Judicial',
                    '300': '300 - Cancelamento da Locação',
                    '301': '301 - Qualificação Subjetiva',
                    '302': '302 - Condição Resolutiva',
                    '303': '303 - Desapropriação para fins de Reforma Agrária',
                    '304': '304 - Retorno de Capital Próprio, exceto na Extinção de Pessoa Jurídica',
                    '305': '305 - Casamento em comunhão universal de bens',
                    '306': '306 - Concessão de Uso Especial para Fins de Moradia (CUEM)',
                    '307': '307 - Inventário',
                    '308': '308 - Aforamento',
                    '309': '309 - Alienação por iniciativa particular ou leilão judicial',
                    '310': '310 - Compra e venda de imóvel gravado por anfiteuse',
                    '311': '311 - Direito de laje',
                    '312': '312 - Direito de superficie'
                },
                tiposEstadosCivil: {
                    '1': 'Casada',
                    '2': 'Casado',
                    '3': 'Divorciada',
                    '4': 'Divorciado',
                    '5': 'Espólio',
                    '6': 'Separada',
                    '7': 'Separada judicialmente',
                    '8': 'Separado',
                    '9': 'Separado judicialmente',
                    '10': 'Solteira',
                    '11': 'Solteira, maior',
                    '12': 'Solteira, menor impúbere',
                    '13': 'Solteira, menor púbere',
                    '14': 'Solteiro',
                    '15': 'Solteiro, maior',
                    '16': 'Solteiro, menor impúbere',
                    '17': 'Solteiro, menor púbere',
                    '18': 'Viúva',
                    '19': 'Viúvo',
                    '20': 'Menor emancipado',
                    '21': 'Outros',
                    '22': 'Desquitado',
                    '23': 'Desquitada'
                },
                tiposClassificacao: {
                    'Proprietário': 'Proprietário',
                    'Adquirente': 'Adquirente',
                    'Transmitente': 'Transmitente',
                    'Devedor': 'Devedor',
                    'Credor': 'Credor',
                    'Anuente': 'Anuente',
                    'Interveniente': 'Interveniente',
                    'Fiador': 'Fiador',
                    'Avalista': 'Avalista',
                    'Requerente': 'Requerente',
                    'Autor': 'Autor',
                    'Réu': 'Réu',
                    'Exequente': 'Exequente',
                    'Executado': 'Executado',
                    'Instituição de condomínio': 'Instituição de condomínio',
                    'Convenção de Condomínio': 'Convenção de Condomínio',
                    'Especificação de Condomínio': 'Especificação de Condomínio',
                    'Retificação de instituição': 'Retificação de instituição',
                    'Retificação de convenção': 'Retificação de convenção',
                    'Retificação de Especificação': 'Retificação de Especificação',
                    'Cônjuge': 'Cônjuge',
                    'Convivente': 'Convivente',
                    'Endossatário': 'Endossatário',
                    'Endossante': 'Endossante',
                    'Outorgante': 'Outorgante',
                    'Outorgado': 'Outorgado',
                    'Emissor': 'Emissor',
                    'Emissora': 'Emissora',
                    'Agente fiduciário': 'Agente fiduciário',
                    'Interveniente garantidor': 'Interveniente garantidor',
                    'Garantidor': 'Garantidor',
                    'Cedente': 'Cedente',
                    'Cessionário': 'Cessionário',
                    'Promitente vendedor': 'Promitente vendedor',
                    'Promitente comprador': 'Promitente comprador',
                    'Expropriante': 'Expropriante',
                    'Expropriado': 'Expropriado',
                    'Interessado': 'Interessado',
                    'Nu-proprietário': 'Nu-proprietário',
                    'Usufrutuário': 'Usufrutuário',
                    'Incorporadora': 'Incorporadora',
                    'Incorporada': 'Incorporada',
                    'Instituição Custodiante': 'Instituição Custodiante',
                    'Agente de Garantia': 'Agente de Garantia',
                    'Agente de Cobrança': 'Agente de Cobrança'
                },  
                tiposCns: {
                    '112482': '112482 - 2\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '111450': '111450 - 1\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '113282': '113282 - 3\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '113498': '113498 - 4\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '113571': '113571 - 5\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '142935': '142935 - 6\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '124594': '124594 - 7\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '113746': '113746 - 8\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '113779': '113779 - 9\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '111138': '111138 - 10\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '111179': '111179 - 11\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '111187': '111187 - 12\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '111195': '111195 - 13\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '111211': '111211 - 14\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '111252': '111252 - 15\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '111278': '111278 - 16\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '111294': '111294 - 17\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                    '111328': '111328 - 18\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP'
                },   
                tiposNacionalidade: {
                    'Brasileira': 'Brasileira',
                    'Afegãne': 'Afegãne',
                    'Alemã': 'Alemã',
                    'Albanesa': 'Albanesa',
                    'Angolana': 'Angolana',
                    'Anguillana': 'Anguillana',
                    'Antiguana': 'Antiguana',
                    'Argelina': 'Argelina',
                    'Argentina': 'Argentina',
                    'Armênia': 'Armênia',
                    'Arubana': 'Arubana',
                    'Australiana': 'Australiana',
                    'Austríaca': 'Austríaca',
                    'Azerbaijano': 'Azerbaijano',
                    'Bahamense': 'Bahamense',
                    'Barenita': 'Barenita',
                    'Bengali': 'Bengali',
                    'Barbadiana': 'Barbadiana',
                    'Bielo-russa': 'Bielo-russa',
                    'Belga': 'Belga',
                    'Belizenha': 'Belizenha',
                    'Beninense': 'Beninense',
                    'Bermudiana': 'Bermudiana',
                    'Boliviana': 'Boliviana',
                    'Bósnia': 'Bósnia',
                    'Britânica': 'Britânica',
                    'Bruneiana': 'Bruneiana',
                    'Búlgara': 'Búlgara',
                    'Burquinês': 'Burquinês',
                    'Burundinesa': 'Burundinesa',
                    'Butanesa': 'Butanesa',
                    'Cabo-verdiana': 'Cabo-verdiana',
                    'Camaronesa': 'Camaronesa',
                    'Cambojana': 'Cambojana',
                    'Canadense': 'Canadense',
                    'Canário': 'Canário',
                    'Cazaque': 'Cazaque',
                    'Chilena': 'Chilena',
                    'Chinesa': 'Chinesa',
                    'Cipriota': 'Cipriota',
                    'Cingapuriana': 'Cingapuriana',
                    'Colombiana': 'Colombiana',
                    'Comorense': 'Comorense',
                    'Congolesa': 'Congolesa',
                    'Costarriquenha': 'Costarriquenha',
                    'Coreana': 'Coreana',
                    'Croata': 'Croata',
                    'Cubana': 'Cubana',
                    'Curaçauense': 'Curaçauense',
                    'Chagositano': 'Chagositano',
                    'Dinamarquesa': 'Dinamarquesa',
                    'Djibutiana': 'Djibutiana',
                    'Dominiquense': 'Dominiquense',
                    'Egípcia': 'Egípcia',
                    'Salvadorenha': 'Salvadorenha',
                    'Árabe': 'Árabe',
                    'Equatoriana': 'Equatoriana',
                    'Eritreia': 'Eritreia',
                    'Eslovaco': 'Eslovaco',
                    'Esloveno': 'Esloveno',
                    'Espanhola': 'Espanhola',
                    'Norte-americana': 'Norte-americana',
                    'Estoniana': 'Estoniana',
                    'Etíope': 'Etíope',
                    'Fijiana': 'Fijiana',
                    'Filipina': 'Filipina',
                    'Finlandesa': 'Finlandesa',
                    'Francesa': 'Francesa',
                    'Gabonesa': 'Gabonesa',
                    'Gambiana': 'Gambiana',
                    'Ganense': 'Ganense',
                    'Georgiana': 'Georgiana',
                    'Gibraltariana': 'Gibraltariana',
                    'Inglesa': 'Inglesa',
                    'Granadina': 'Granadina',
                    'Grega': 'Grega',
                    'Groenlandesa': 'Groenlandesa',
                    'Guadalupense': 'Guadalupense',
                    'Guamês': 'Guamês',
                    'Guatemalteca': 'Guatemalteca',
                    'Guernesiano': 'Guernesiano',
                    'Guianense': 'Guianense',
                    'Franco-guianense': 'Franco-guianense',
                    'Guinéu-equatoriano ou equatoguineana': 'Guinéu-equatoriano ou equatoguineana',
                    'Guinéu-equatoriano': 'Guinéu-equatoriano',
                    'Guineense': 'Guineense',
                    'Haitiana': 'Haitiana',
                    'Holandês': 'Holandês',
                    'Hondurenha': 'Hondurenha',
                    'Hong-konguiana ou chinesa': 'Hong-konguiana ou chinesa',
                    'Húngara': 'Húngara',
                    'Iemenita': 'Iemenita',
                    'Da ilha Bouvet': 'Da ilha Bouvet',
                    'Da ilha de Ascensão': 'Da ilha de Ascensão',
                    'Da ilha de Clipperton': 'Da ilha de Clipperton',
                    'Manês': 'Manês',
                    'Natalense': 'Natalense',
                    'Pitcairnense': 'Pitcairnense',
                    'Reunionense': 'Reunionense',
                    'Alandês': 'Alandês',
                    'Caimanês': 'Caimanês',
                    'Coquense': 'Coquense',
                    'Cookense': 'Cookense',
                    'Faroense': 'Faroense',
                    'Das ilhas Geórgia do Sul e Sandwich do Sul': 'Das ilhas Geórgia do Sul e Sandwich do Sul',
                    'Das ilhas Heard e McDonald': 'Das ilhas Heard e McDonald',
                    'Malvinense': 'Malvinense',
                    'Norte-marianense': 'Norte-marianense',
                    'Marshallino': 'Marshallino',
                    'Das ilhas Menores Afastadas': 'Das ilhas Menores Afastadas',
                    'Norfolquino': 'Norfolquino',
                    'Salomônico': 'Salomônico',
                    'Seichelense': 'Seichelense',
                    'Toquelauano': 'Toquelauano',
                    'Turquês': 'Turquês',
                    'Virginense': 'Virginense',
                    'Indiano': 'Indiano',
                    'Indonésia': 'Indonésia',
                    'Iraniano': 'Iraniano',
                    'Iraquiana': 'Iraquiana',
                    'Irlandesa': 'Irlandesa',
                    'Islandesa': 'Islandesa',
                    'Israelense': 'Israelense',
                    'Italiana': 'Italiana',
                    'Jamaicana': 'Jamaicana',
                    'Japonesa': 'Japonesa',
                    'Canalina': 'Canalina',
                    'Jordaniana': 'Jordaniana',
                    'Kiribatiana': 'Kiribatiana',
                    'Kosovar': 'Kosovar',
                    'Kuwaitiano': 'Kuwaitiano',
                    'Laosiana': 'Laosiana',
                    'Lesota': 'Lesota',
                    'Letão': 'Letão',
                    'Libanesa': 'Libanesa',
                    'Liberiana': 'Liberiana',
                    'Líbia': 'Líbia',
                    'Liechtensteiniense': 'Liechtensteiniense',
                    'Lituana': 'Lituana',
                    'Luxemburguesa': 'Luxemburguesa',
                    'Macauense': 'Macauense',
                    'Macedônio': 'Macedônio',
                    'Malgaxe': 'Malgaxe',
                    'Malaia': 'Malaia',
                    'Malauiano': 'Malauiano',
                    'Maldívia': 'Maldívia',
                    'Malinesa': 'Malinesa',
                    'Maltesa': 'Maltesa',
                    'Marroquina': 'Marroquina',
                    'Martiniquense': 'Martiniquense',
                    'Mauriciana': 'Mauriciana',
                    'Mauritana': 'Mauritana',
                    'Maiotense': 'Maiotense',
                    'Mexicana': 'Mexicana',
                    'Micronésia': 'Micronésia',
                    'Moçambicana': 'Moçambicana',
                    'Moldavo': 'Moldavo',
                    'Monegasca': 'Monegasca',
                    'Mongol': 'Mongol',
                    'Montenegra': 'Montenegra',
                    'Montserratiano': 'Montserratiano',
                    'Birmanês': 'Birmanês',
                    'Namíbia': 'Namíbia',
                    'Nauruana': 'Nauruana',
                    'Nepalesa': 'Nepalesa',
                    'Nicaraguense': 'Nicaraguense',
                    'Nigerina': 'Nigerina',
                    'Nigeriana': 'Nigeriana',
                    'Niueana': 'Niueana',
                    'Norueguesa': 'Norueguesa',
                    'Caledônia': 'Caledônia',
                    'Neozelandesa': 'Neozelandesa',
                    'Omani': 'Omani',
                    'Dos países Baixos Caribenhos': 'Dos países Baixos Caribenhos',
                    'Palauense': 'Palauense',
                    'Palestino': 'Palestino',
                    'Zona do Canal do Panamá': 'Zona do Canal do Panamá',
                    'Pauásia': 'Pauásia',
                    'Paquistanesa': 'Paquistanesa',
                    'Paraguaia': 'Paraguaia',
                    'Peruana': 'Peruana',
                    'Franco-polinésia': 'Franco-polinésia',
                    'Polonesa': 'Polonesa',
                    'Portorriquenha': 'Portorriquenha',
                    'Portuguesa': 'Portuguesa',
                    'Catarense': 'Catarense',
                    'Queniano': 'Queniano',
                    'Quirguiz': 'Quirguiz',
                    'Centro-africana': 'Centro-africana',
                    'Dominicana': 'Dominicana',
                    'Tcheco': 'Tcheco',
                    'Romena': 'Romena',
                    'Ruandesa': 'Ruandesa',
                    'Russa': 'Russa',
                    'Saariano': 'Saariano',
                    'Pedro-miquelonense': 'Pedro-miquelonense',
                    'Samoana': 'Samoana',
                    'Samoano': 'Samoano',
                    'Samarinês': 'Samarinês',
                    'Helenense': 'Helenense',
                    'Santa-lucense': 'Santa-lucense',
                    'São-bartolomeense': 'São-bartolomeense',
                    'São-cristovense': 'São-cristovense',
                    'São-martinhense': 'São-martinhense',
                    'São-tomense': 'São-tomense',
                    'Sao-vicentino': 'Sao-vicentino',
                    'Senegalesa': 'Senegalesa',
                    'Leonesa': 'Leonesa',
                    'Sérvia': 'Sérvia',
                    'Síria': 'Síria',
                    'Somali': 'Somali',
                    'Cingalesa': 'Cingalesa',
                    'Suazi': 'Suazi',
                    'Sudanesa': 'Sudanesa',
                    'Sul-sudanês': 'Sul-sudanês',
                    'Sueca': 'Sueca',
                    'Suíça': 'Suíça',
                    'Surinamesa': 'Surinamesa',
                    'Svalbardense': 'Svalbardense',
                    'Tadjique': 'Tadjique',
                    'Tailandesa': 'Tailandesa',
                    'Taiwanês': 'Taiwanês',
                    'Tanzaniana': 'Tanzaniana',
                    'Do Território Britânico do Oceano Índico': 'Do Território Britânico do Oceano Índico',
                    'Do Territórios do Sul da França': 'Do Territórios do Sul da França',
                    'Timorense': 'Timorense',
                    'Togolesa': 'Togolesa',
                    'Tonganesa': 'Tonganesa',
                    'Trinitário-tobagense': 'Trinitário-tobagense',
                    'Tristanita': 'Tristanita',
                    'Tunisiana': 'Tunisiana',
                    'Turcomana': 'Turcomana',
                    'Turca': 'Turca',
                    'Tuvaluana': 'Tuvaluana',
                    'Ucraniana': 'Ucraniana',
                    'Ugandense': 'Ugandense',
                    'Uruguaia': 'Uruguaia',
                    'Uzbeque': 'Uzbeque',
                    'Vanuatuense': 'Vanuatuense',
                    'Vaticano': 'Vaticano',
                    'Venezuelana': 'Venezuelana',
                    'Vietnamita': 'Vietnamita',
                    'Wallis-futunense': 'Wallis-futunense',
                    'Zambiana': 'Zambiana',
                    'Zimbabuana': 'Zimbabuana'
                },    
                tiposLogradouro: {
                    '1': 'Acampamento',
                    '2': 'Acesso',
                    '3': 'Açude',
                    '4': 'Adro',
                    '5': 'Aeroporto',
                    '6': 'Afluente',
                    '7': 'Aglomerado',
                    '8': 'Agrovila',
                    '9': 'Alagado',
                    '10': 'Alameda',
                    '11': 'Aldeia',
                    '12': 'Aleia',
                    '13': 'Alto',
                    '14': 'Anel',
                    '15': 'Antiga',
                    '16': 'Antigo',
                    '17': 'Área',
                    '18': 'Areal',
                    '19': 'Arraial',
                    '20': 'Arroio',
                    '21': 'Artéria',
                    '22': 'Assentamento',
                    '23': 'Atalho',
                    '24': 'Aterro',
                    '25': 'Autódromo',
                    '26': 'Avenida',
                    '27': 'Baia',
                    '28': 'Bairro',
                    '29': 'Baixa',
                    '30': 'Baixada',
                    '31': 'Baixadão',
                    '32': 'Baixão',
                    '33': 'Baixo',
                    '34': 'Balão',
                    '35': 'Balneário',
                    '36': 'Barra',
                    '37': 'Barragem',
                    '38': 'Barranca',
                    '39': 'Barranco',
                    '40': 'Barreiro',
                    '41': 'Barro',
                    '42': 'Beco',
                    '43': 'Beira',
                    '44': 'Beirada',
                    '45': 'Belvedere',
                    '46': 'Bloco',
                    '47': 'Bocaina',
                    '48': 'Boqueirão',
                    '49': 'Bosque',
                    '50': 'Boulevard',
                    '51': 'Brejo',
                    '52': 'Buraco',
                    '53': 'Cabeceira',
                    '54': 'Cachoeira',
                    '55': 'Cachoeirinha',
                    '56': 'Cais',
                    '57': 'Calcada',
                    '58': 'Calçadão',
                    '59': 'Caminho',
                    '60': 'Campo',
                    '61': 'Canal',
                    '62': 'Canteiro',
                    '63': 'Capão',
                    '64': 'Capoeira',
                    '65': 'Cartódromo',
                    '66': 'Central',
                    '67': 'Centro',
                    '68': 'Cerca',
                    '69': 'Cerrado',
                    '70': 'Cerro',
                    '71': 'Chácara',
                    '72': 'Chapada',
                    '73': 'Chapadão',
                    '74': 'Charco',
                    '75': 'Cidade',
                    '76': 'Circular',
                    '77': 'Cohab',
                    '78': 'Colina',
                    '79': 'Colônia',
                    '80': 'Comunidade',
                    '81': 'Condomínio',
                    '82': 'Conjunto',
                    '83': 'Continuação',
                    '84': 'Contorno',
                    '85': 'Corredor',
                    '86': 'Córrego',
                    '87': 'Costa',
                    '88': 'Coxilha',
                    '89': 'Cruzamento',
                    '90': 'Descida',
                    '91': 'Desvio',
                    '92': 'Dique',
                    '93': 'Distrito',
                    '94': 'Divisa',
                    '95': 'Divisão',
                    '96': 'Divisor',
                    '97': 'Edifício',
                    '98': 'Eixo',
                    '99': 'Elevado',
                    '100': 'Encosta',
                    '101': 'Engenho',
                    '102': 'Enseada',
                    '103': 'Entrada',
                    '104': 'Entreposto',
                    '105': 'Entroncamento',
                    '106': 'Escada',
                    '107': 'Escadão',
                    '108': 'Escadaria',
                    '109': 'Escadinha',
                    '110': 'Espigão',
                    '111': 'Esplanada',
                    '112': 'Esquina',
                    '113': 'Estação',
                    '114': 'Estacionamento',
                    '115': 'Estádio',
                    '116': 'Estância',
                    '117': 'Estrada',
                    '118': 'Extensão',
                    '119': 'Faixa',
                    '120': 'Favela',
                    '121': 'Fazenda',
                    '122': 'Feira',
                    '123': 'Ferrovia',
                    '124': 'Final',
                    '125': 'Floresta',
                    '126': 'Folha',
                    '127': 'Fonte',
                    '128': 'Fortaleza',
                    '129': 'Freguesia',
                    '130': 'Fundos',
                    '131': 'Furo',
                    '132': 'Galeria',
                    '133': 'Gameleira',
                    '134': 'Garimpo',
                    '135': 'Gleba',
                    '136': 'Granja',
                    '137': 'Grota',
                    '138': 'Habitacional',
                    '139': 'Haras',
                    '140': 'Hipódromo',
                    '141': 'Horto',
                    '142': 'Igarapé',
                    '143': 'Ilha',
                    '144': 'Inaplicável',
                    '145': 'Invasão',
                    '146': 'Jardim',
                    '147': 'Jardinete',
                    '148': 'Ladeira',
                    '149': 'Lado',
                    '150': 'Lago',
                    '151': 'Lagoa',
                    '152': 'Lagoinha',
                    '153': 'Largo',
                    '154': 'Lateral',
                    '155': 'Leito',
                    '156': 'Ligação',
                    '157': 'Limeira',
                    '158': 'Limite',
                    '159': 'Limites',
                    '160': 'Linha',
                    '161': 'Lote',
                    '162': 'Loteamento',
                    '163': 'Lugarejo',
                    '164': 'Maloca',
                    '165': 'Manancial',
                    '166': 'Mangue',
                    '167': 'Margem',
                    '168': 'Margens',
                    '169': 'Marginal',
                    '170': 'Marina',
                    '171': 'Mata',
                    '172': 'Mato',
                    '173': 'Módulo',
                    '174': 'Monte',
                    '175': 'Morro',
                    '176': 'Muro',
                    '177': 'Não Especificado',
                    '178': 'Núcleo',
                    '179': 'Oca',
                    '180': 'Oleoduto',
                    '181': 'Olho',
                    '182': 'Olhos',
                    '183': 'Orla',
                    '184': 'Outros',
                    '185': 'Paco',
                    '186': 'Palafita',
                    '187': 'Pântano',
                    '188': 'Parada',
                    '189': 'Paradouro',
                    '190': 'Paralela',
                    '191': 'Parque',
                    '192': 'Particular',
                    '193': 'Passagem',
                    '194': 'Passarela',
                    '195': 'Passeio',
                    '196': 'Passo',
                    '197': 'Pasto',
                    '198': 'Pátio',
                    '199': 'Pavilhão',
                    '200': 'Pedra',
                    '201': 'Pedras',
                    '202': 'Pedreira',
                    '203': 'Penhasco',
                    '204': 'Perimetral',
                    '205': 'Perímetro',
                    '206': 'Perto',
                    '207': 'Planalto',
                    '208': 'Plataforma',
                    '209': 'Ponta',
                    '210': 'Ponte',
                    '211': 'Ponto',
                    '212': 'Porto',
                    '213': 'Posto',
                    '214': 'Povoado',
                    '215': 'Praça',
                    '216': 'Praia',
                    '217': 'Projeção',
                    '218': 'Projetada',
                    '219': 'Projeto',
                    '220': 'Prolongamento',
                    '221': 'Propriedade',
                    '222': 'Próximo',
                    '223': 'Quadra',
                    '224': 'Quarteirão',
                    '225': 'Quilombo',
                    '226': 'Quilometro',
                    '227': 'Quinta',
                    '228': 'Quintas',
                    '229': 'Rachão',
                    '230': 'Ramal',
                    '231': 'Rampa',
                    '232': 'Rancho',
                    '233': 'Recanto',
                    '234': 'Região',
                    '235': 'Represa',
                    '236': 'Residencial',
                    '237': 'Reta',
                    '238': 'Retiro',
                    '239': 'Retorno',
                    '240': 'Riacho',
                    '241': 'Ribanceira',
                    '242': 'Ribeirão',
                    '243': 'Rincão',
                    '244': 'Rio',
                    '245': 'Rocha',
                    '246': 'Rochedo',
                    '247': 'Rodovia',
                    '248': 'Rotatória',
                    '249': 'Rotula',
                    '250': 'Rua',
                    '251': 'Ruela',
                    '252': 'Saco',
                    '253': 'Saída',
                    '254': 'Sanga',
                    '255': 'Sede',
                    '256': 'Sem',
                    '257': 'Seringal',
                    '258': 'Serra',
                    '259': 'Sertão',
                    '260': 'Servidão',
                    '261': 'Seta',
                    '262': 'Setor',
                    '263': 'Sitio',
                    '264': 'Sopé',
                    '265': 'Subida',
                    '266': 'Superquadra',
                    '267': 'Tapera',
                    '268': 'Terminal',
                    '269': 'Terra',
                    '270': 'Terreno',
                    '271': 'Terrenos',
                    '272': 'Transversal',
                    '273': 'Travessa',
                    '274': 'Travessão',
                    '275': 'Travessia',
                    '276': 'Trecho',
                    '277': 'Trevo',
                    '278': 'Trilha',
                    '279': 'Trilho',
                    '280': 'Trilhos',
                    '281': 'Trincheira',
                    '282': 'Túnel',
                    '283': 'Unidade',
                    '284': 'Usina',
                    '285': 'Vala',
                    '286': 'Valão',
                    '287': 'Vale',
                    '288': 'Vargem',
                    '289': 'Variante',
                    '290': 'Várzea',
                    '291': 'Velódromo',
                    '292': 'Vereda',
                    '293': 'Vertente',
                    '294': 'Via',
                    '295': 'Viaduto',
                    '296': 'Vicinal',
                    '297': 'Viela',
                    '298': 'Vila',
                    '299': 'Vilarejo',
                    '300': 'Volta',
                    '301': 'Zona',
                    '302': '1a Travessa da Avenida',
                    '303': '1a Travessa da Rua',
                    '304': '2a Travessa da Avenida',
                    '305': '2a Travessa da Rua',
                    '306': '3a Travessa da Avenida',
                    '307': '3a Travessa da Rua',
                    '308': '4a Travessa da Avenida',
                    '309': '4a Travessa da Rua',
                    '310': '5a Travessa da Avenida',
                    '311': '5a Travessa da Rua'
                },
                tiposImovel: {
                    '1': 'Casa',
                    '2': 'Apartamento',
                    '3': 'Loja',
                    '4': 'Sala/conjunto',
                    '5': 'Terreno/fração',
                    '6': 'Galpão',
                    '7': 'Prédio comercial',
                    '8': 'Prédio residencial',
                    '9': 'Fazenda/sítio/chácara',
                    '10': 'Garagem/Vaga',
                    '11': 'Box/Depósito',
                    // '12': 'Outros'
                },       
                dados: false,
                dadosHistorico: false,
                tiposHistorico: {
                    'cadastro': 'Cadastro',
                    'edição': 'Edição',
                    'inativar': 'Inativado',
                    'ativar': 'Ativado',
                    'excluir': 'Excluído',
                    'conferir': 'Marcado como conferido',
                    'exportação': 'Exportado'
                },
                paginaAtual: 1, 
                totalRegistros: -1,
                totalPaginas: 1,
                contadorPaginas: 100,
                numeroExibicaoRegistros: 100,
                usuarios: [],
                expiracaoInterval: null,
                horaAtual: moment().format('HH:mm'),
                contagemRegressiva: '00:00:00',
                contagemRegressivaClass: 'default',
                visualizacao: null
            }
        },
        async created(){
            /**
             * Realizar a autenticação
             */
            await autenticar(this)

            this.usuarios = await this.buscarUsuarios()

            sessionStorage.removeItem('form')
        },
        async mounted() {
            /**
             * Realizar a autenticação
             */
            await autenticar(this)

            /**
             * Realiza a contagem da expiração
             */
            this.calcularExpiracao()
            this.expiracaoInterval = setInterval(this.calcularExpiracao, 1000)

            /**
             * Eventos para capturar o comando CTRL + Q
             */
            document.addEventListener('keydown', (event) => {
                if (event.ctrlKey && event.key === 'Enter') {
                    const oInputs = document.querySelectorAll('input')

                    oInputs.forEach(input => {
                        input.blur()
                    })

                    this.pesquisar()
                }

                if (event.key === 'Enter') {
                    if (document.activeElement && document.activeElement.tagName.toLowerCase() === "textarea") {
                        return
                    }

                    const oInputs = document.querySelectorAll('input')

                    oInputs.forEach(input => {
                        input.blur()
                    })

                    this.pesquisar()
                }
            });
        },
        watch: {
            paginaAtual: {
                handler: function (novoValor, valorAntigo) {
                    if(novoValor != valorAntigo){
                        this.pesquisar()
                    }
                }
            }
        },
        computed: {
            paginas() {
                let intervalo = 2

                intervalo = this.paginaAtual == 1 ? 4 : intervalo
                intervalo = this.paginaAtual == 2 ? 3 : intervalo
                intervalo = this.paginaAtual == this.totalPaginas ? 4 : intervalo
                intervalo = this.paginaAtual == this.totalPaginas - 1 ? 3 : intervalo
                
                const primeiraPagina = Math.max(1,  this.paginaAtual - intervalo);
                const ultimaPagina = Math.min(this.totalPaginas,  this.paginaAtual + intervalo);
                
                return Array.from({ length: ultimaPagina - primeiraPagina + 1 }, (_, index) => primeiraPagina + index);
            }
        },
        methods: {
            /**
             * Função para limpar o filtro
             */
            limparFiltro(iTipo){
                if(iTipo == 1){
                    this.modalLimpeza = true
                }else{
                    this.modalLimpeza = false
                    this.pesquisado = false
                    this.totalRegistros = -1
                    this.dados = false
                    this.form = {
                        dataInicial: '',
                        dataFinal: '',
                        dataInicialAto: '',
                        dataFinalAto: '',
                        numeroRegistro: '',
                        marcacoes: '',
                        tipoRegistro: '',
                        rascunho: '',
                        conferido: '',
                        inconsistencia: '',
                        matriculaNova: '',
                        exportacaoONR: '',
                        campoOrdenacao: '',
                        tipoOrdenacao: '',
                        alerta: '',
                        buscaPersonalizada: '',
                        usuarios: []
                    }

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectTipoRegistro
                     */
                    const oSelectTipoRegistro = this.$refs.tipoRegistro;
                    oSelectTipoRegistro.clearSelectize()

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelect
                     */
                    const oSelect = this.$refs.select;
                    oSelect.clearSelectize()

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectMarcacoes
                     */
                    const oSelectMarcacoes = this.$refs.marcacoes;
                    oSelectMarcacoes.clearSelectize()

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectRascunho
                     */
                    const oSelectRascunho = this.$refs.rascunho;
                    oSelectRascunho.clearSelectize()

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectConferido
                     */
                    const oSelectConferido = this.$refs.conferido;
                    oSelectConferido.clearSelectize()
                    
                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectInconsistencia
                     */
                    const oSelectInconsistencia = this.$refs.inconsistencia;
                    oSelectInconsistencia.clearSelectize()
                    
                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectMatriculaNova
                     */
                    const oSelectMatriculaNova = this.$refs.matriculaNova;
                    oSelectMatriculaNova.clearSelectize()

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectExportacaoONR
                     */
                    const oSelectExportacaoONR = this.$refs.exportacaoONR;
                    oSelectExportacaoONR.clearSelectize()

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectCampoOrdenacao
                     */
                    const oSelectCampoOrdenacao = this.$refs.campoOrdenacao;
                    oSelectCampoOrdenacao.clearSelectize()

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectTipoOrdenacao
                     */
                    const oSelectTipoOrdenacao = this.$refs.tipoOrdenacao;
                    oSelectTipoOrdenacao.clearSelectize()

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectAlerta
                     */
                    const oSelectAlerta = this.$refs.alerta;
                    oSelectAlerta.clearSelectize()
                    
                    this.pesquisar();
                }
            },
            /**
             * Função para pesquisar
             */
            async pesquisar(bFiltro = true, bResetarPagina = false){
                if(this.form.dataInicial != '' && this.form.dataFinal != '' && moment(this.form.dataInicial).isAfter(moment(this.form.dataFinal))){
                    toastAlert('A data inicial não pode ser maior que a data final')

                    return false
                }

                if(this.form.dataInicialAto != '' && this.form.dataFinalAto != '' && moment(this.form.dataInicialAto).isAfter(moment(this.form.dataFinalAto))){
                    toastAlert('A data inicial não pode ser maior que a data final')

                    return false
                }

                this.carregado = true
                this.carregandoBusca = true
                this.dados = false

                /**
                * Realizar a autenticação
                */
                await autenticar(this)

                if(sessionStorage.getItem('parametros') != null && !bFiltro){
                    /**
                     * Pega os parametros do storage
                     * 
                     * @var {object} oParametros
                     */
                    const oParametros = JSON.parse(sessionStorage.getItem('parametros'))

                    this.form.dataInicial = oParametros.dataInicial
                    this.form.dataFinal = oParametros.dataFinal
                    this.form.dataInicialAto = oParametros.dataInicialAto
                    this.form.dataFinalAto = oParametros.dataFinalAto
                    this.form.numeroRegistro = oParametros.numeroRegistro
                    this.form.tipoRegistro = oParametros.tipoRegistro
                    this.form.marcacoes = oParametros.marcacoes
                    this.form.rascunho = oParametros.rascunho
                    this.form.conferido = oParametros.conferido
                    this.form.inconsistencia = oParametros.inconsistencia
                    this.form.matriculaNova = oParametros.matriculaNova
                    this.form.exportacaoONR = oParametros.exportacaoONR
                    this.form.campoOrdenacao = oParametros.campoOrdenacao
                    this.form.tipoOrdenacao = oParametros.tipoOrdenacao
                    this.form.alerta = oParametros.alerta
                    this.form.buscaPersonalizada = oParametros.buscaPersonalizada
                    this.form.usuarios = oParametros.usuarios
                    this.paginaAtual = oParametros.pagina
                }

                if(bResetarPagina){
                    this.paginaAtual = 1
                }
                
                /**
                 * Define os parametros para a requisição
                 * 
                 * @var {string} sParametros
                 */
                const sParametros = qs.stringify({
                    dataInicial: this.form.dataInicial,
                    dataFinal: this.form.dataFinal,
                    dataInicialAto: this.form.dataInicialAto,
                    dataFinalAto: this.form.dataFinalAto,
                    numeroRegistro: this.form.numeroRegistro,
                    tipoRegistro: this.form.tipoRegistro,
                    marcacoes: this.form.marcacoes,
                    rascunho: this.form.rascunho,
                    conferido: this.form.conferido,
                    inconsistencia: this.form.inconsistencia,
                    matriculaNova: this.form.matriculaNova,
                    exportacaoONR: this.form.exportacaoONR,
                    campoOrdenacao: this.form.campoOrdenacao,
                    tipoOrdenacao: this.form.tipoOrdenacao,
                    alerta: this.form.alerta,
                    buscaPersonalizada: this.form.buscaPersonalizada && this.form.buscaPersonalizada != '' ? JSON.parse(this.form.buscaPersonalizada) : '',
                    usuarios: this.form.usuarios,
                    pagina: this.paginaAtual,
                    limiteRegistros: this.numeroExibicaoRegistros
                })

                sessionStorage.setItem('parametros', JSON.stringify({
                    dataInicial: this.form.dataInicial,
                    dataFinal: this.form.dataFinal,
                    dataInicialAto: this.form.dataInicialAto,
                    dataFinalAto: this.form.dataFinalAto,
                    numeroRegistro: this.form.numeroRegistro,
                    tipoRegistro: this.form.tipoRegistro,
                    marcacoes: this.form.marcacoes,
                    rascunho: this.form.rascunho,
                    conferido: this.form.conferido,
                    inconsistencia: this.form.inconsistencia,
                    matriculaNova: this.form.matriculaNova,
                    exportacaoONR: this.form.exportacaoONR,
                    campoOrdenacao: this.form.campoOrdenacao,
                    tipoOrdenacao: this.form.tipoOrdenacao,
                    alerta: this.form.alerta,
                    buscaPersonalizada: this.form.buscaPersonalizada,
                    usuarios: this.form.usuarios,
                    pagina: this.paginaAtual,
                    limiteRegistros: this.numeroExibicaoRegistros
                }))

                await axios.request({
                    method: 'get',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/indicadores?${sParametros}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    }
                })
                .then((response) => {
                    if(this.form.dataInicial == '' && this.form.dataFinal == '' && this.form.dataInicialAto == '' && this.form.dataFinalAto == '' && this.form.numeroRegistro == '' && this.form.tipoRegistro == '' && this.form.marcacoes == '' && this.form.rascunho == '' && this.form.conferido == '' && this.form.inconsistencia == '' && this.form.matriculaNova == '' && this.form.exportacaoONR == '' && this.form.campoOrdenacao == '' && this.form.tipoOrdenacao == '' && this.form.buscaPersonalizada == '' && this.form.alerta == '' && this.form.usuarios.length == 0){
                        this.pesquisado = false
                    }else{
                        this.pesquisado = true
                    }

                    this.totalPaginas = Math.ceil(response.data.totalRegistros / this.numeroExibicaoRegistros)
                    this.totalRegistros = response.data.totalRegistros
                    this.contadorPaginas = this.numeroExibicaoRegistros * this.paginaAtual

                    if(this.contadorPaginas > response.data.totalRegistros){
                        this.contadorPaginas = response.data.totalRegistros
                    }

                    response.data.data.map((oDado) => {
                        oDado.dataAtualizacao = moment(oDado.dataAtualizacao).format('DD/MM/YYYY HH:mm')
                    })

                    this.dados = response.data.data
                })
                .catch((error) => {
                    this.totalRegistros = 0
                    console.log(error)
                    toastAlert(`Erro ao buscar dados${error?.response?.data?.descricao ? `: ${error?.response?.data?.descricao}` : ''}`)
                });

                this.carregandoBusca = false
            },
            /**
             * Função para buscar usuarios
             */
            async buscarUsuarios(){
                await axios.request({
                    method: 'get',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/usuarios`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    }
                })
                .then((response) => {
                    if(response.data.usuarios){
                        response.data.usuarios = response.data.usuarios.reduce((acc, valor, index) => {
                            acc[valor] = valor;
                            return acc;
                        }, {});

                        this.usuarios = response.data.usuarios
                        
                        /**
                         * Define o select pela referencia
                         *
                         * @var {object} oSelect
                         */
                        const oSelect = this.$refs.select;
                        oSelect.updateOptions(response.data.usuarios)
                        
                        oSelect.clearSelectize()
                    }

                })
                .catch((error) => {
                    console.log(error)
                    toastAlert(`Erro ao buscar os usuários${error?.response?.data?.descricao ? `: ${error.response.data.descricao}` : ''}`)
                });

                this.pesquisar(false)
            },
            /**
             * Função para ir para o cadastro
             */
            indicador(){
                window.location.href = '/indicadores'
            },
            /**
             * Função para ir para o complemento
             */
            complemento(){
                window.location.href = '/complemento'
            },
            /**
             * Função para ir para os documentos
             */
            documentos(){
                window.location.href = '/documentos'
            },
            /**
             * Função para ir para os usuarios
             */
            usuariosPagina(){
                window.location.href = '/usuarios'
            },
            /**
             * Função para ir para as estatisticas
             */
            estatisticas(){
                window.location.href = '/estatisticas'
            },
            /**
             * Função para ir para os enderecos
             */
            enderecos(){
                window.location.href = '/enderecos'
            },
            /**
             * Função para ir para a exportacao
             */
            exportacao(){
                window.location.href = '/exportacao'
            },
            /**
             * Função para visualizar
             */
            async visualizarRegistro(iNumeroRegistro, iTipoRegistro, bConferencia = false){
                this.carregando = true

                /**
                 * Realizar a autenticação
                 */
                await autenticar(this)

                /**
                 * Define a variavel dos dados
                 * 
                 * @var object oDados
                 */
                let oDados = {}

                await axios.request({
                    method: 'get',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/verificarRegistro/${iNumeroRegistro}?tipoRegistro=${iTipoRegistro}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    }
                })
                .then(async (response) => {  
                    response.data.ato = []

                    response.data.quantidadeAtos = response.data.quantidadeAtos ?? 0

                    for(var iPagina = 0; iPagina < Math.ceil(response.data.quantidadeAtos/500); iPagina++){
                        await axios.request({
                            method: 'get',
                            url: `${process.env.VUE_APP_ENDPOINT_API}/v1/atos/${response.data._id}?pagina=${iPagina}&registrosPorPagina=500`,
                            headers: { 
                                'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                            }
                        })
                        .then((responseAto) => {   
                            response.data.ato = [...response.data.ato , ...responseAto.data.dados]
                        })
                    }

                    if(response.data.ato.length > 0){
                        response.data.ato.sort((a, b) => a.numeroAto - b.numeroAto)
                    }
                        
                    oDados = response.data
                })
                .catch((error) => {
                    toastAlert('Erro ao visualizar os dados')

                    this.carregando = false

                    return
                });
                
                oDados.beneficiariosLivroVinculado = oDados.beneficiariosLivroVinculado.filter(objeto => !Object.values(objeto).every(valor => valor === ""))
                oDados.descricaoRegistroAuxiliar.contribuintes = oDados.descricaoRegistroAuxiliar.contribuintes.filter(objeto => !Object.values(objeto).every(valor => valor === ""))

                /**
                 * Define a variavel dos dados
                 * 
                 * @var object aDatas
                 */
                const aDatas = [
                    'dataAbertura',
                    'dataOficio',
                    'dataRegistro',
                    'dataRegistroAnterior',
                    'dataInstrumento',
                    'dataVencimento',
                    'dataCasamento',
                    'dataUniaoEstavel',
                    'dataRevogacao',
                    'dataIndisponibilidade',
                ]

                aDatas.forEach((sData) => {
                    if(oDados[sData] && oDados[sData] != ''){
                        oDados[sData] = moment(oDados[sData]).format('DD/MM/YYYY')
                    }

                    if(oDados.descricaoRegistroAuxiliar[sData] && oDados.descricaoRegistroAuxiliar[sData] != ''){
                        oDados.descricaoRegistroAuxiliar[sData] = moment(oDados.descricaoRegistroAuxiliar[sData]).format('DD/MM/YYYY')
                    }
                    
                    oDados.descricaoRegistroAuxiliar.averbacoes.forEach((oAverbacao) => {
                        if(oAverbacao[sData] && oAverbacao[sData] != ''){
                            oAverbacao[sData] = moment(oAverbacao[sData]).format('DD/MM/YYYY')
                        }
                    })

                    oDados.registroAnterior.forEach((oRegistro) => {
                        if(oRegistro[sData] && oRegistro[sData] != ''){
                            oRegistro[sData] = moment(oRegistro[sData]).format('DD/MM/YYYY')
                        }
                    })

                    oDados.ato.forEach((oAto) => {
                        if(oAto[sData] && oAto[sData] != ''){
                            oAto[sData] = moment(oAto[sData]).format('DD/MM/YYYY')
                        }

                        oAto.cancelamento = oAto.cancelamento.filter(objeto => !Object.values(objeto).every(valor => valor === ""))
                    })

                    oDados.indisponibilidade.forEach((oIndisponibilidade) => {
                        if(oIndisponibilidade[sData] && oIndisponibilidade[sData] != ''){
                            oIndisponibilidade[sData] = moment(oIndisponibilidade[sData]).format('DD/MM/YYYY')
                        }
                    })
                })

                this.carregando = false
                this.visualizacao = oDados
                this.visualizacao.conferencia = bConferencia

                document.documentElement.style.cssText = "overflow: hidden!important"
            },
            /**
             * Função para fechar o modal de visualização
             */
            fecharVisualizacao(){
                this.visualizacao = null
                document.documentElement.style.cssText = "overflow: auto!important"
            },
            /**
             * Função para imprimir a visualização
             */
            imprimirDiv() {
                let styles = ''
                
                // Pegar todos os estilos internos
                for (let i = 0; i < document.styleSheets.length; i++) {
                    try {
                        const cssRules = document.styleSheets[i].cssRules || document.styleSheets[i].rules;
                        if (cssRules) {
                            for (let j = 0; j < cssRules.length; j++) {
                                styles += cssRules[j].cssText + '\n'
                            }
                        }
                    } catch (e) {
                        console.warn('Não foi possível acessar as regras de um stylesheet: ', e.message)
                    }
                }
                
                var conteudo = document.getElementById('printable_div').innerHTML,
                tela_impressao = window.open('about:blank')

                tela_impressao.document.write(`
                    <style>
                        html {
                            visibility: hidden;
                        }
                        .modal-card, .modal-content, .modal-card-body{
                            max-height: max-content!important;
                        }
                        .modal-card-foot, .modal-card-head{
                            display: none!important;
                        }
                        .modal-card{
                            top: 0px!important;
                        }
                        ${styles} 
                    </style>
                    ${conteudo}
                `)

                tela_impressao.window.print()
                tela_impressao.window.close()
            },
            /**
             * Função para editar
             */
            editarRegistro(sNumeroRegistro, iTipoRegistro){
                sessionStorage.setItem('numeroRegistro', sNumeroRegistro)
                sessionStorage.setItem('tipoRegistro', iTipoRegistro)

                window.location.href = '/indicadores/editar'
            },
            /**
             * Função para editar
             */
            adicionarAtos(sNumeroRegistro, iTipoRegistro){
                sessionStorage.setItem('numeroRegistro', sNumeroRegistro)
                sessionStorage.setItem('tipoRegistro', iTipoRegistro)

                window.location.href = '/indicadores/editar/atos'
            },
            /**
             * Função para copiar
             */
            copiarRegistro(sNumeroRegistro, iTipoRegistro){
                sessionStorage.setItem('numeroRegistro', sNumeroRegistro)
                sessionStorage.setItem('tipoRegistro', iTipoRegistro)

                window.location.href = '/indicadores/copiar'
            },
            /**
             * Função inativar o registro
             */
            async alterarIndicador(sTipoAlteracao, sUuid, sNumeroRegistro, bAbrirModal = true){
                if(bAbrirModal){
                    this.fecharVisualizacao()
                    this.modalAlteracao = true
                    this.tipoAlteracao = sTipoAlteracao
                    this.uuid = sUuid
                    this.numeroRegistro = sNumeroRegistro

                    return false
                }

                /**
                 * Realizar a autenticação
                 */
                await autenticar(this)

                this.modalAlteracao = false
                this.carregando = true

                await axios.request({
                    method: 'patch',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/indicadores/${sTipoAlteracao}/${sUuid}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    },
                    data: qs.stringify({
                        usuario: this.autenticacao.usuario
                    })
                })
                .then((response) => {
                    /**
                     * Define os tipos da mensagem
                     *
                     * @var {object} oTipos
                     */
                    const oTipos = {
                        'excluir': 'excluído',
                        'inativar': 'inativado',
                        'ativar': 'ativado',
                        'conferir': 'conferido'
                    }

                    toastAlert(`Registro ${oTipos[sTipoAlteracao]} com sucesso`, 'success')
                })
                .catch((error) => {
                    console.log(error)
                    toastAlert(`Erro ao alterar o indicador`)
                });

                this.carregando = false
                this.pesquisar(false)
            },
            /**
             * Função para exibir o histórico
             */
            historico(oHistoricos){
                if(!oHistoricos || oHistoricos.length == 0){
                    this.dadosHistorico = null

                    return
                }

                oHistoricos = JSON.parse(JSON.stringify(oHistoricos))

                oHistoricos.forEach((oHistorico) => {
                    if(oHistorico.dataAlteracao && oHistorico.dataAlteracao != ''){
                        oHistorico.dataAlteracao = moment(oHistorico.dataAlteracao).format('DD/MM/YYYY HH:mm')
                    }
                })

                this.dadosHistorico = oHistoricos
            },
            /**
             * Função para sair da aplicação
             */
            sair(){
                sessionStorage.removeItem('access_token')

                window.location.href = '/'
            },
            /**
             * Função para cancular a expiracao
             */
            calcularExpiracao() {
                const dataAtualUnix = Math.floor(Date.now() / 1000)
                const diferencaSegundos = this.autenticacao.exp - dataAtualUnix

                if(diferencaSegundos <= 10){
                    this.contagemRegressivaClass = 'danger'
                }else{
                    this.contagemRegressivaClass = 'default'
                }

                if (diferencaSegundos <= 0) {
                    clearInterval(this.expiracaoInterval)
                    this.contagemRegressiva = "Sessão expirada"
                } else {
                    const horas = Math.floor((diferencaSegundos % 86400) / 3600)
                    const minutos = Math.floor((diferencaSegundos % 3600) / 60)
                    const segundos = diferencaSegundos % 60

                    this.contagemRegressiva = `A sessão expira em ${horas < 10 ? '0'+horas : horas}:${minutos < 10 ? '0'+minutos : minutos}:${segundos < 10 ? '0'+segundos : segundos}`
                }
            },
            /**
             * Função para atualizar o token
             */
            async refreshToken(){
                /**
                * Define os headers da requisição
                * 
                * @var {object} oHeaders
                */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")

                /**
                * Define os opções da requisição
                * 
                * @var {object} oOpcoes
                */
                const oOpcoes = {  
                    method: 'POST',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow',
                    body: JSON.stringify({ token: this.autenticacao.refresh_token })
                }

                /**
                * Realiza a requisição para buscar os detalhes do usuario
                * 
                * @var {object} oLogin
                */
                const oLogin = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/refreshToken`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }

                        return response
                    })

                if(!oLogin){
                    toastAlert('Sua sessão expirou, redirecionando para a autenticação...')

                    setTimeout(() => {
                        /**
                        * Desloga da aplicação
                        */
                        this.sair()
                    }, 2000)

                    /**
                    * Define os dados nas variaveis
                    */
                    this.autenticacao.autenticado = false

                    return false
                }    

                if(oLogin?.AccessToken){
                    sessionStorage.setItem('access_token', oLogin.AccessToken)
                }

                /**
                * Realizar a autenticação
                */
                await autenticar(this)

                /**
                * Realiza a contagem da expiração
                */
                this.calcularExpiracao()
                this.expiracaoInterval = setInterval(this.calcularExpiracao, 1000)
            },
            /**
             * Função para exportar o relatorio
             */
            async exportar(sTipo){
                /**
                 * Define os parametros para a requisição
                 * 
                 * @var {string} sParametros
                 */
                 const sParametros = qs.stringify({
                    dataInicial: this.form.dataInicial,
                    dataFinal: this.form.dataFinal,
                    dataInicialAto: this.form.dataInicialAto,
                    dataFinalAto: this.form.dataFinalAto,
                    numeroRegistro: this.form.numeroRegistro,
                    tipoRegistro: this.form.tipoRegistro,
                    marcacoes: this.form.marcacoes,
                    rascunho: this.form.rascunho,
                    conferido: this.form.conferido,
                    inconsistencia: this.form.inconsistencia,
                    matriculaNova: this.form.matriculaNova,
                    exportacaoONR: this.form.exportacaoONR,
                    campoOrdenacao: this.form.campoOrdenacao,
                    tipoOrdenacao: this.form.tipoOrdenacao,
                    alerta: this.form.alerta,
                    buscaPersonalizada: this.form.buscaPersonalizada,
                    usuarios: this.form.usuarios,
                    usuario: this.autenticacao.usuario
                })

                /**
                * Define os headers da requisição
                * 
                * @var {object} oHeaders
                */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")
                
                this.carregando = true

                /**
                 * Realizar a autenticação
                 */
                await autenticar(this)

                await axios.request({
                    method: 'get',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/relatorio/csv?${sParametros}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    toastAlert('Exportação solicitada com sucesso', 'success')
                
                    this.carregando = false
                })
                .catch((error) => {
                    console.log(error)
                    toastAlert(`Erro ao exportar os dados`)
                
                    this.carregando = false
                })
            },
            /**
             * Função para exportar o relatorio
             */
            async exportarOnr(sTipo){
                /**
                 * Define os parametros para a requisição
                 * 
                 * @var {string} sParametros
                 */
                const sParametros = qs.stringify({
                    dataInicial: this.form.dataInicial,
                    dataFinal: this.form.dataFinal,
                    dataInicialAto: this.form.dataInicialAto,
                    dataFinalAto: this.form.dataFinalAto,
                    numeroRegistro: this.form.numeroRegistro,
                    tipoRegistro: this.form.tipoRegistro,
                    marcacoes: this.form.marcacoes,
                    rascunho: this.form.rascunho,
                    conferido: this.form.conferido,
                    inconsistencia: this.form.inconsistencia,
                    matriculaNova: this.form.matriculaNova,
                    campoOrdenacao: this.form.campoOrdenacao,
                    tipoOrdenacao: this.form.tipoOrdenacao,
                    alerta: this.form.alerta,
                    buscaPersonalizada: this.form.buscaPersonalizada,
                    exportacaoONR: this.form.exportacaoONR,
                    usuarios: this.form.usuarios,
                    usuario: this.autenticacao.usuario
                })

                /**
                * Define os headers da requisição
                * 
                * @var {object} oHeaders
                */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")
                
                this.carregando = true

                /**
                 * Realizar a autenticação
                 */
                await autenticar(this)

                await axios.request({
                    method: 'post',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/exportacao/onr?${sParametros}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    toastAlert('Exportação solicitada com sucesso', 'success')
                
                    this.carregando = false
                })
                .catch((error) => {
                    console.log(error)
                    toastAlert(`Erro ao exportar os dados`)
                
                    this.carregando = false
                })
            },
            async exportarDoi(){
                if(this.form.dataInicialAto == ''){
                    toastAlert('Informe a data inicial do ato')

                    return false
                }

                if(this.form.dataFinalAto == ''){
                    toastAlert('Informe a data final do ato')

                    return false
                }

                /**
                 * Define os parametros para a requisição
                 * 
                 * @var {string} sParametros
                 */
                const sParametros = qs.stringify({
                    dataInicialAto: this.form.dataInicialAto,
                    dataFinalAto: this.form.dataFinalAto,
                    numeroRegistro: this.form.numeroRegistro,
                    usuarios: this.form.usuarios,
                    usuario: this.autenticacao.usuario
                })

                /**
                * Define os headers da requisição
                * 
                * @var {object} oHeaders
                */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")
                
                this.carregando = true

                /**
                 * Realizar a autenticação
                 */
                await autenticar(this)

                await axios.request({
                    method: 'post',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/exportacao/doi?${sParametros}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    toastAlert('Exportação solicitada com sucesso', 'success')
                
                    this.carregando = false
                })
                .catch((error) => {
                    console.log(error)
                    toastAlert(`Erro ao exportar os dados`)
                
                    this.carregando = false
                })
            },
            async exportarAtos(){
                /**
                 * Define os parametros para a requisição
                 * 
                 * @var {string} sParametros
                 */
                const sParametros = qs.stringify({
                    dataInicial: this.form.dataInicial,
                    dataFinal: this.form.dataFinal,
                    dataInicialAto: this.form.dataInicialAto,
                    dataFinalAto: this.form.dataFinalAto,
                    numeroRegistro: this.form.numeroRegistro,
                    tipoRegistro: this.form.tipoRegistro,
                    marcacoes: this.form.marcacoes,
                    rascunho: this.form.rascunho,
                    conferido: this.form.conferido,
                    inconsistencia: this.form.inconsistencia,
                    matriculaNova: this.form.matriculaNova,
                    campoOrdenacao: this.form.campoOrdenacao,
                    tipoOrdenacao: this.form.tipoOrdenacao,
                    alerta: this.form.alerta,
                    buscaPersonalizada: this.form.buscaPersonalizada,
                    exportacaoONR: this.form.exportacaoONR,
                    usuarios: this.form.usuarios,
                    usuario: this.autenticacao.usuario
                })

                /**
                * Define os headers da requisição
                * 
                * @var {object} oHeaders
                */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")
                
                this.carregando = true

                /**
                 * Realizar a autenticação
                 */
                await autenticar(this)

                await axios.request({
                    method: 'post',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/exportacao/atos?${sParametros}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    toastAlert('Exportação solicitada com sucesso', 'success')
                
                    this.carregando = false
                })
                .catch((error) => {
                    console.log(error)
                    toastAlert(`Erro ao exportar os dados`)
                
                    this.carregando = false
                })
            },
            atualizarNumeroRegistro(sValor){
                this.form.numeroRegistro = sValor.replace(/[^0-9,]/g, '')
            }
        }
    }
</script>

<style>
    html{
        overflow: hidden;
        background-color: #E5DDD5;
    }

    .title:not(:last-child), .subtitle:not(:last-child){
        margin-bottom: 0.5em;
    }

    .div-principal{
        height: 100vh;
        overflow: hidden;
    }

    .barra-superior{
        margin: 0;
        height: 50px;
        background: #EDEDED;
        border-right: 1px solid #E1E1E1;
        border-bottom: 1px solid rgb(200, 200, 200);
        display: flex;
        justify-content: space-between;
    }

    .barra-superior .gg-search{
        position: absolute;
        margin: 9px 27px;
        transform: scale(var(--ggs,0.6));
    }

    .barra-superior input{
        width: 100%;
        border: none;
        margin: 0;
        height: 100%;
        outline: none;
        padding-left: 50px;
        color: gray;
    }

    .barra-superior-filtro{
        height: 35px;
    }

    .barra-superior button{
        margin: 0.5rem 2rem;
        font-size: 0.8rem;
    }

    .barra-superior span{
        line-height: 50px;
        margin-left: 25px;
        font-weight: 500;
    }

    .indicador{
        padding: 0;
        background:#E5DDD5;
        position: relative;
    }

    .indicadores{
        height: 83vh;
        margin: 10px 0;
        overflow-y: auto;
        display: grid;
    }

    .columns:last-child{
        margin: 0 -8px;
    }

    .section{
        padding: unset;
    }

    .container{
        max-width: unset;
        overflow-x: hidden;
    }

    .lista-indicadores{
        display: none;
        justify-content: flex-end;
        flex-direction: column;
    }

    .formulario{
        margin: 0 auto;
        position: relative;
        width: 96vw;
        background: #fff;
        padding: 1rem 1rem;
        border-radius: 1rem;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 8rem;
    }

    .indicador{
        height: 98%!important;
        right: -2px;
        top: -11px;
        padding-top: 12px;
    }

    select, .select{
        width: 100%;
    }

    .label{
        margin-top: 0.8rem
    }

    .btn-control{
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        border-top: 1px solid #ddd;
        width: 96vw;
        padding: 1rem 2rem 0rem 2rem;
        margin-left: -1rem;
    }

    .selectize-input{
        padding: 10px 8px!important;
    }

    .formulario .columns{
        flex-wrap: wrap;
        padding: 0.5rem;
        margin-top: -0.5rem!important;
        align-items: flex-end;
        padding-bottom: 15px;
    }

    .formulario .column{
        padding: 0 0.5rem!important;
        margin-top: 0rem!important;
    }

    .formulario .column.is-one-tenth,.column.is-one-tenth-tablet {
        flex: none;
        width: 10%
    }

    .formulario .label {
        margin-top: 0.5rem;
        margin-bottom: 0.2rem!important;
        font-size: 12px;
    }

    .tabs{
        align-items: flex-end;
        margin-bottom: 0px;
        margin-left: -1rem;
        width: 96vw;
    }
    
    .tabs ul{
        padding-left: 2rem;
    }

    .formulario .numero{
        position: absolute;
        right: 25px;
        top: 1rem;
        font-size: 18px;
    }

    .btn-rotate > .icon{
        transform: rotate(270deg);
    }
    
    .column-date{
        display: flex; 
        gap: 1rem;
    }

    .column-date > span{
        margin-top: 2.25rem;
    }

    .column-date > .control{
        width: 100%;
    }

    .selectize-control.multi .selectize-input.has-items{
        padding: 6px 8px!important;
    }

    .column-table{
        border-bottom: none!important;
        padding-top: 0!important;
        padding-bottom: 0!important;
    }

    .btn-limpar{
        margin-top: 1rem;
    }

    .btn-pesquisar{
        margin-top: 1rem;
        float: right;
    }

    .pagination{
        gap: 1rem;
    }

    .pagination > b{
        margin-left: -1.8rem;
        width: 6rem;
    }

    .pagination, .pagination-list{
        display: inline-flex;
    }

    .pagination-previous{
        order: 0;
    }

    .pagination-previous.is-disabled, .pagination-next.is-disabled{
        opacity: .7;
        pointer-events: none;
    }

    .column-nao-encontrado{
        justify-content: center;
        align-items: center;
    }

    .column-nao-encontrado > div{
        margin: 1rem 1rem 1.5rem 1rem;
    }

    .pagination-link.is-current{
        background-color: #000;
        border-color: #dbdbdb;
    }

    .visualizacao-titulo{
        border-bottom: 1px solid #ccc;
        padding: 5px;
        margin: 10px 0;
        text-transform: uppercase; 
    }

    .visualizacao-dados{
        display: flex;
        justify-content: flex-start;
        padding: 1rem;
        flex-wrap: wrap;
        gap: 0 1rem;
    }

    .visualizacao-dados p{
        flex: 0 0 49%;
    }

    .visualizacao-subtitulo{
        border-bottom: 1px solid #ccc;
        padding: 5px;
        margin: -10px 10px 0px 10px;
        text-transform: uppercase; 
    }

    .dropdown-menu{
        min-width: 7rem!important;
    }

    a.dropdown-item{
        padding-right: 1rem!important;
    }

    .icon.is-small{
        font-weight: bolder;
        font-size: larger;
        width: 1.5rem!important;
    }
        
    .titulo-impressao{
        display: none;
        text-align: center;
    }

    @media print {
        .printable-div, .printable-div * {
            visibility: visible;
        }
        .titulo-impressao{
            display: block!important;
        }
    }
</style>
