<template>
    <div v-show="autenticacao.autenticado">
        <MarcaDev />
        <Loader v-if="carregando" />
        <div v-show="!carregando">
            <section class="section">
                <div class="container" :key="rerenderKeyGeral">
                    <div class="barra-superior">
                        <div style="display: flex; align-items: center; justify-content: space-between; gap: 2rem;">
                            <img class="amplify-image" alt="Logo" src="/logo.png"
                                style="width: 150px; margin-left: 1.5rem" />
                            <span style="font-size: 24px; margin: auto">Indicadores</span>
                        </div>
                        <div style="margin-right: 2rem; display: flex; gap: 1rem">
                            <Button :classes="`is-blue-steel is-outlined is-active`" :titulo="`Digitação`" :onclick="indicador"
                                style="width: 7rem; border-color: #ccc;" />

                            <Button :classes="`is-blue-steel is-outlined`" :titulo="`Pesquisa`" :onclick="pesquisa"
                                style="width: 7rem; border-color: #ccc;" />

                            <Button :classes="`is-blue-steel is-outlined`" :titulo="`Complemento`" :onclick="complemento"
                                style="width: 7rem; border-color: #ccc;" />

                            <Button :classes="`is-blue-steel is-outlined`" :titulo="`Exportação`" :onclick="exportacao"
                                v-if="autenticacao.admin" style="width: 7rem; border-color: #ccc;" />

                            <Button :classes="`is-blue-steel is-outlined`" :titulo="`Documentos`" :onclick="documentos"
                                v-if="autenticacao.admin" style="width: 7rem; border-color: #ccc;" />

                            <Button :classes="`is-blue-steel is-outlined`" :titulo="`Endereços`" :onclick="enderecos"
                                v-if="autenticacao.admin" style="width: 7rem; border-color: #ccc;" />

                            <Button :classes="`is-blue-steel is-outlined`" :titulo="`Estatísticas`"
                                :onclick="estatisticas" v-if="autenticacao.coordenadores || autenticacao.admin"
                                style="width: 7rem; border-color: #ccc;" />

                            <div class="dropdown is-right is-hoverable">
                                <div class="dropdown-trigger">
                                    <button class="button is-secondary" aria-haspopup="true"
                                        aria-controls="dropdown-menu3">
                                        <i class="gg-more-vertical-alt"></i>
                                    </button>
                                </div>
                                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                                    <div class="dropdown-content">
                                        <a class="dropdown-item" @click="usuarios()" v-if="autenticacao.admin">
                                            Usuários
                                        </a>
                                        <a class="dropdown-item" @click="sair()">
                                            Sair
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="formulario">
                        <div class="columns">
                            <div class="column is-half listaAtos" style="padding: 0px!important; margin-bottom: 1rem;">
                                <Select 
                                    :label="`Lista de atos`" 
                                    :labelTotal="`Total de atos: ${listaAtos.length}`" 
                                    :textoOption="`Selecione um ato para editar`" 
                                    :preenchido="true"
                                    ref="selectAto"
                                    :selecionarOpcao="selecionarAto"
                                ></Select>
                            </div>

                            <div class="numero">{{ tipoRegistro ? tiposRegistro[tipoRegistro].split(" - ")[1] : '' }} <b>{{ numeroRegistro ? numeroRegistro.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '' }}</b></div>

                            <div class="columns ato" ref="ato">
                                <div class="accordion">
                                    <div class="accordion-item">
                                        <div class="accordion-header" style="background-color: #f1f1f1;">
                                            <span :class="{ onusAtivo: ato.onus == 1 }" style="margin-left: 0.7rem;">
                                                Ato {{ ato.numeroAto }} {{ ato.descricao != '' ? '-' : '' }} {{ ato.descricao }} {{ tiposAto[ato.tipo] ? ` - ${tiposAto[ato.tipo].split(' - ')[1]}` : '' }} {{ ato.descricaoAdicional ? ` - ${ato.descricaoAdicional}` : '' }} {{ ato.onus == 1 ? ` - Ônus ativo` : '' }}
                                            </span>
                                            <div :style="`display: flex; align-items: center;`">
                                                <Button
                                                    :classes="`is-secondary is-outlined btn-small-alt`" 
                                                    :icone="``" 
                                                    :titulo="`Cancelar ${ato.cadastrado == 1 ? 'visualização' : ato.edicao ? 'edição' : 'cadastro'} do ato`" 
                                                    :onclick="cancelarEdicaoAto"
                                                    :parametro1="true"
                                                    :tabindex="'-1'"
                                                />

                                                <Button
                                                    :classes="`is-secondary is-outlined btn-small-alt`" 
                                                    :icone="``" 
                                                    :titulo="`Copiar ato`" 
                                                    :onclick="copiarAto"
                                                    :parametro1="ato.indice"
                                                    :tabindex="'-1'"
                                                />

                                                <Button
                                                    :classes="`is-danger btn-small-alt`" 
                                                    :icone="``" 
                                                    :titulo="`Remover ato`" 
                                                    :onclick="removerAto"
                                                    :parametro1="ato.indice"
                                                    :parametro2="1"
                                                    :tabindex="'-1'"
                                                    v-show="ato.cadastrado != 1 && ato.edicao"
                                                />
                                            </div>
                                        </div>
                                        <div :class="`accordion-content show`">
                                            <div class="column is-2">
                                                <InputText
                                                    :label="`Número do ato`"
                                                    :preenchido="true"
                                                    v-model="ato.numeroAto"
                                                />
                                            </div>
                                            
                                            <div class="column is-2">
                                                <InputText
                                                    :label="`Data do registro`"
                                                    :preenchido="true"
                                                    :tipo="`date`"
                                                    v-model="ato.dataRegistro"
                                                />
                                            </div>

                                            <div class="column is-2">
                                                <Select 
                                                    :label="`Tipo do ato`" 
                                                    :preenchido="true"
                                                    v-model="ato.descricao"
                                                    ref="selectTipoAto"
                                                >
                                                    <option value="AB">0 - AB</option>
                                                    <option value="AV">1 - AV</option>
                                                    <option value="R">2 - R</option>
                                                </Select>
                                            </div>

                                            <div class="column is-3">
                                                <Select 
                                                    :label="`Título do ato`" 
                                                    :preenchido="true"
                                                    v-model="ato.tipo"
                                                    ref="selectTituloAto"
                                                    :onchange="definirAto"
                                                >
                                                    <option 
                                                        v-for="(tipo, idx) in tiposAto" 
                                                        :value="idx"
                                                    >{{ tipo }}</option>
                                                </Select>
                                            </div>

                                            <div class="column is-3">
                                                <InputText
                                                    :label="`Descrição`" 
                                                    :preenchido="true"
                                                    v-model="ato.descricaoAdicional"
                                                />
                                            </div>

                                            <div class="column is-2">
                                                <InputText
                                                    :label="`Data do instrumento`" 
                                                    :preenchido="true"
                                                    :tipo="`date`"
                                                    v-model="ato.dataInstrumento"
                                                />
                                            </div>

                                            <div class="column is-2">
                                                <Select 
                                                    :label="`Tipo da moeda`" 
                                                    :preenchido="true"
                                                    v-model="ato.tipoMoeda"
                                                    ref="selectTipoMoeda"
                                                >
                                                    <option 
                                                        v-for="(tipo, iMoeda) in tiposMoedas" 
                                                        :value="iMoeda"
                                                    >{{ tipo }}</option>
                                                </Select>
                                            </div>

                                            <div class="column is-2">
                                                <InputText
                                                    :label="`Valor declarado`"
                                                    :preenchido="true"
                                                    v-model="ato.valorDeclarado"
                                                    :mascaraMonetaria="mascaraMonetaria"
                                                />
                                            </div>

                                            <div class="column is-2">
                                                <InputText
                                                    :label="`Base cálculo ITBI`"
                                                    :preenchido="true"
                                                    v-model="ato.valorBaseCalculoItbiItcmd"
                                                    :mascaraMonetaria="mascaraMonetaria"
                                                />
                                            </div>

                                            <div class="column is-2" v-if="false">
                                                <InputText
                                                    :label="`Valor fiscal`"
                                                    :preenchido="true"
                                                    v-model="ato.valorFiscal"
                                                    :mascaraMonetaria="mascaraMonetaria"
                                                />
                                            </div>

                                            <div class="column is-2">
                                                <Select 
                                                    :label="`Ônus`" 
                                                    :preenchido="true"
                                                    v-model="ato.onus"
                                                    ref="selectOnus"
                                                >
                                                    <option value="0">0 - Não</option>
                                                    <option value="1">1 - Sim</option>
                                                </Select>
                                            </div>

                                            <div class="column is-2">
                                                <Select 
                                                    :label="`Tipo de transação`" 
                                                    :preenchido="true"
                                                    v-model="ato.tipoTransacao"
                                                    ref="selectTipoTransacao"
                                                >
                                                    <option value="Total">0 - Total</option>
                                                    <option value="Parcial">1 - Parcial</option>
                                                </Select>
                                            </div>

                                            <div class="column is-2">
                                                <InputText
                                                    :label="`Folha`"
                                                    :preenchido="true"
                                                    v-model="ato.folha"
                                                />
                                            </div>

                                            <div class="column is-2">
                                                <Select :label="`Indicador da parte transacionada`" :preenchido="true"
                                                    v-model="ato.tipoParteTransacionada" ref="selectTipoParteTransacionada">
                                                    <option value="1">1 - %</option>
                                                    <option value="2">2 - ha/m²</option>
                                                </Select>
                                            </div>

                                            <div class="column is-2">
                                                <InputText :label="`Parte transacionada`"
                                                    :preenchido="true" v-model="ato.porcentagemParticipacaoArea"
                                                    :mascaraMonetaria="mascaraMonetaria" />
                                            </div>

                                            <div class="column is-2">
                                                <Select 
                                                    :label="`Forma de pagamento`" 
                                                    :preenchido="true"
                                                    v-model="ato.formaPagamento"
                                                    ref="selectFormaPagamento"
                                                >
                                                    <option value="5">5 - Quitado à vista</option>
                                                    <option value="7">7 - A prazo</option>
                                                    <option value="9">9 - Não se aplica</option>
                                                    <option value="10">10 - Quitado a prazo</option>
                                                    <option value="11">11 - Quitado sem informação da forma de pagamento</option>
                                                </Select>
                                            </div>

                                            <div class="column is-2" v-if="ato.formaPagamento == 7">
                                                <InputText
                                                    :label="`Data da primeira parcela`"
                                                    :mascara="'##/##/####'"
                                                    :preenchido="true"
                                                    v-model="ato.primeiraDataParcela"
                                                    :blur="definirUltimaParcela"
                                                />
                                            </div>

                                            <div class="column is-2" v-if="ato.formaPagamento == 7">
                                                <InputText
                                                    :label="`Nº de parcelas`"
                                                    :preenchido="true"
                                                    v-model="ato.numeroParcelas"
                                                    :blur="definirUltimaParcela"
                                                />
                                            </div>

                                            <div class="column is-2" v-if="ato.formaPagamento == 7">
                                                <InputText
                                                    :label="`Data da última parcela`"
                                                    :mascara="'##/##/####'"
                                                    :preenchido="true"
                                                    v-model="ato.mesAnoUltimaParcela"
                                                />
                                            </div>

                                            <div class="column is-2" v-if="ato.formaPagamento == 7">
                                                <InputText
                                                    :label="`Valor pago até a data do ato`"
                                                    :preenchido="true"
                                                    v-model="ato.valorPagoAteDataAto"
                                                    :mascaraMonetaria="mascaraMonetaria"
                                                />
                                            </div>

                                            <div class="column is-2">
                                                <Select 
                                                    :label="`Houve permuta de bens?`" 
                                                    :preenchido="true"
                                                    v-model="ato.indicadorPermutaBens"
                                                >
                                                    <option value="0">0 - Não</option>
                                                    <option value="1">1 - Sim</option>
                                                </Select>
                                            </div>

                                            <div class="column is-2">
                                                <Select 
                                                    :label="`Houve pagamento em dinheiro?`" 
                                                    :preenchido="true"
                                                    v-model="ato.indicadorPagamentoDinheiro"
                                                >
                                                    <option value="0">0 - Não</option>
                                                    <option value="1">1 - Sim</option>
                                                </Select>
                                            </div>

                                            <div class="column is-2" v-if="ato.indicadorPagamentoDinheiro == 1">
                                                <InputText
                                                    :label="`Valor pago em espécie até a data do ato`"
                                                    :preenchido="true"
                                                    v-model="ato.valorPagoMoedaCorrenteDataAto"
                                                    :mascaraMonetaria="mascaraMonetaria"
                                                />
                                            </div>

                                            <div class="column is-2">
                                                <Select 
                                                    :label="`Existe DOI anterior?`" 
                                                    :preenchido="true"
                                                    v-model="ato.existeDoiAnterior"
                                                    ref="selectExisteDOIAnterior"
                                                >
                                                    <option value="0">0 - Não</option>
                                                    <option value="1">1 - Sim</option>
                                                </Select>
                                            </div>

                                            <div class="column is-2">
                                                <Select 
                                                    :label="`Natureza do título`" 
                                                    :preenchido="true"
                                                    v-model="ato.naturezaTitulo"
                                                    ref="selectNaturezaTitulo"
                                                >
                                                    <option 
                                                        v-for="(tipo, idx) in tiposNaturezaTitulo" 
                                                        :value="idx"
                                                    >{{ tipo }}</option>
                                                </Select>
                                            </div>

                                            <div class="column is-4" v-show="ato.tipo == 207">
                                                <Select 
                                                    :label="`Cancelamento`" 
                                                    :preenchido="true"
                                                    :multiple="true"
                                                    v-model="ato.cancelamento"
                                                    ref="selectCancelamento"
                                                >
                                                </Select>
                                            </div>

                                            <div class="column is-2" v-show="autenticacao.escreventes">
                                                <InputText
                                                    :label="`Selo de fiscalização`"
                                                    :preenchido="true"
                                                    v-model="ato.seloFiscalizacao"
                                                />
                                            </div>

                                            <hr style="width: 100%; margin-bottom: 0.5rem;">

                                            <div class="column is-half listaPartes" style="margin-left: 2rem; margin-bottom: 1rem;" v-show="form.ato[ato.indice]?.pessoal && form.ato[ato.indice].pessoal.length > 0">
                                                <Select 
                                                    :label="`Lista de partes`" 
                                                    :labelTotal="`Total de partes: ${form.ato[ato.indice]?.pessoal.length ?? 0}`" 
                                                    :textoOption="`Selecione uma parte para editar`" 
                                                    :preenchido="true"
                                                    ref="selectParteAto"
                                                    key="selectParteAto"
                                                    :tabindex="'-1'"
                                                    :selecionarOpcao="selecionarParteAto"
                                                >
                                                    <option 
                                                        v-for="(pessoal, idx) in form.ato[ato.indice]?.pessoal" 
                                                        :value="idx"
                                                    >{{ idx+1 }} - {{ pessoal.classificacao }} {{ pessoal.documento != '' ? ` - ${pessoal.documento}` : '' }} {{ pessoal.nome != '' ? ` - ${pessoal.nome}` : '' }}</option>
                                                </Select>
                                            </div>

                                            <div class="columns pessoal" :style="`width: 100%; margin: 0rem 2rem;`" ref="pessoal" :key="`pessoal`">
                                                <div class="accordion">
                                                    <div class="accordion-item">
                                                        <div class="accordion-header">
                                                            <span style="margin-left: 0.7rem;">
                                                                Parte {{ ato.pessoal.documento != '' ? ` - ${ato.pessoal.documento}` : ''  }} {{ ato.pessoal.nome != '' ? ` - ${ato.pessoal.nome}` : ''  }}
                                                            </span>
                                                            <div :style="`display: flex; align-items: center; justify-content: flex-end`">
                                                                <Button
                                                                    :classes="`is-secondary is-outlined btn-small-alt`" 
                                                                    :icone="``" 
                                                                    :titulo="`Cancelar ${ato.cadastrado == 1 ? 'visualização' : ato.pessoal.edicao ? 'edição' : 'cadastro'} da parte`" 
                                                                    :onclick="cancelarEdicaoParteAto"
                                                                    :parametro1="false"
                                                                    :tabindex="'-1'"
                                                                />
                                                                
                                                                <Button
                                                                    :classes="`is-danger btn-small-alt`" 
                                                                    :icone="``" 
                                                                    :titulo="`Remover parte`" 
                                                                    :onclick="removerParteAto"
                                                                    :tabindex="'-1'"
                                                                    v-show="ato.cadastrado != 1 && ato.pessoal.edicao"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div :class="`accordion-content show`">
                                                            <div class="column is-2" :ref="`parte_ato`">
                                                                <Select 
                                                                    :label="`Classificação`" 
                                                                    :preenchido="true"
                                                                    v-model="ato.pessoal.classificacao"
                                                                    ref="selectClassificacaoAto"
                                                                    :onchange="definirClassificacao"
                                                                >
                                                                    <option 
                                                                        v-for="(tipo, idx) in tiposClassificacao" 
                                                                        :value="idx"
                                                                    >{{ tipo }}</option>
                                                                </Select>
                                                            </div>

                                                            <div class="column is-2">
                                                                <Select 
                                                                    :label="`Tipo do documento`" 
                                                                    :preenchido="true"
                                                                    v-model="ato.pessoal.tipoDocumento"
                                                                    ref="selectTipoDocumentoAto"
                                                                >
                                                                    <option value="CPF">1 - CPF</option>
                                                                    <option value="CIC">2 - CIC</option>
                                                                    <option value="CNPJ">3 - CNPJ</option>
                                                                    <option value="RG">4 - RG</option>
                                                                    <option value="CGC">5 - CGC</option>
                                                                    <option value="OUTROS">6 - OUTROS</option>
                                                                    <option value="OUTROS PF">7 - OUTROS PF</option>
                                                                    <option value="OUTROS PJ">8 - OUTROS PJ</option>
                                                                </Select>
                                                            </div>

                                                            <div class="column is-one-fifth">
                                                                <InputText
                                                                    :label="`Nº do documento`" 
                                                                    :preenchido="true"
                                                                    :mascara="ato.pessoal.mascara"
                                                                    :inconsistencia="definirInconsistencia"
                                                                    v-model="ato.pessoal.documento"
                                                                    :buscarDocumento="buscarDocumento"
                                                                    :aba="`ato`"
                                                                />
                                                            </div>

                                                            <div class="column is-5">
                                                                <InputText
                                                                    :label="verificaCpfCnpj(ato.pessoal?.documento) != undefined ? verificaCpfCnpj(ato.pessoal?.documento) ? `Nome` : `Razão social` : `Nome/Razão social`" 
                                                                    :preenchido="true"
                                                                    :loading="ato.pessoal.loading ?? false"
                                                                    v-model="ato.pessoal.nome"
                                                                />
                                                            </div>

                                                            <div style="display: flex">             
                                                                <Button
                                                                    :classes="`is-warning-alt is-outlined btn-small`" 
                                                                    :icone="`danger`" 
                                                                    :titulo="``" 
                                                                    :onclick="definirInconsistencia"
                                                                    :parametro1="`ato`"
                                                                    :tabindex="'-1'"
                                                                    style="margin-left: 0;"
                                                                />
                                                            </div>

                                                            <div class="column is-2" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && ato.pessoal.tipoDocumento != 'OUTROS PJ'">
                                                                <Select 
                                                                    :label="`Nacionalidade`" 
                                                                    :preenchido="true"
                                                                    v-model="ato.pessoal.nacionalidade"
                                                                    ref="selectNacionalidadeAto"
                                                                >
                                                                    <option 
                                                                        v-for="(tipo, idx) in tiposNacionalidade" 
                                                                        :value="idx"
                                                                    >{{ tipo }}</option>
                                                                </Select>
                                                            </div>

                                                            <div class="column is-2" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && ato.pessoal.tipoDocumento != 'OUTROS PJ'">
                                                                <Select 
                                                                    :label="`Estado civil`" 
                                                                    :preenchido="true"
                                                                    v-model="ato.pessoal.estadoCivil" 
                                                                    ref="selectEstadoCivilAto"                                                                      
                                                                >
                                                                    <option 
                                                                        v-for="(tipo, idx) in tiposEstadosCivil" 
                                                                        :value="idx"
                                                                    >{{ tipo }}</option>
                                                                </Select>
                                                            </div>

                                                            <div class="column is-one-third" v-show="(ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2) && ato.pessoal.tipoDocumento != 'CNPJ'">
                                                                <Select 
                                                                    :label="`Regime de bens`" 
                                                                    :preenchido="true"
                                                                    v-model="ato.pessoal.regimeBens"
                                                                    ref="selectRegimeAto"
                                                                >
                                                                    <option 
                                                                        v-for="(tipo, idx) in tiposRegimeBens" 
                                                                        :value="idx"
                                                                    >{{ tipo }}</option>
                                                                </Select>
                                                            </div>

                                                            <div class="column is-one-fifth" v-show="(ato.pessoal.estadoCivil == '10' || ato.pessoal.estadoCivil == '14')">
                                                                <Select 
                                                                    :label="`União estável`" 
                                                                    :preenchido="true"
                                                                    v-model="ato.pessoal.uniaoEstavel"
                                                                    ref="selectUniaoEstavelAto"
                                                                >
                                                                    <option value="0">0 - Não</option>
                                                                    <option value="1">1 - Sim</option>
                                                                </Select>
                                                            </div>
                                                            
                                                            <div class="column is-2">
                                                                <InputText
                                                                    :label="`Porcentagem de participação na operação`"
                                                                    :preenchido="true"
                                                                    :mascaraMonetaria="mascaraMonetaria"
                                                                    v-model="ato.pessoal.fracao"
                                                                />
                                                            </div>

                                                            <div class="column is-2">
                                                                <InputText
                                                                    :label="`Valor em moeda corrente`"
                                                                    :preenchido="true"
                                                                    v-model="ato.pessoal.valorFracional"
                                                                    :mascaraMonetaria="mascaraMonetaria"
                                                                />
                                                            </div>

                                                            <div 
                                                                v-if="!(ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2))"
                                                                :class="{
                                                                    column: true,
                                                                    'is-1': ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15),
                                                                    'is-3': ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil != 1 && ato.pessoal.estadoCivil != 2 && ato.pessoal.estadoCivil != 10 && ato.pessoal.estadoCivil != 14)
                                                                }"
                                                            ></div>

                                                            <div class="column is-2" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2 || ((ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15) && ato.pessoal.uniaoEstavel == 1))">
                                                                <Select 
                                                                    :label="`Tipo do documento do cônjuge`" 
                                                                    :preenchido="true"
                                                                    v-model="ato.pessoal.tipoDocumentoConjuge"
                                                                    ref="selectTipoDocumentoConjugeAto"
                                                                >
                                                                    <option value="CPF">1 - CPF</option>
                                                                    <option value="CIC">2 - CIC</option>
                                                                    <option value="CNPJ">3 - CNPJ</option>
                                                                    <option value="RG">4 - RG</option>
                                                                    <option value="CGC">5 - CGC</option>
                                                                    <option value="OUTROS">6 - OUTROS</option>
                                                                    <option value="OUTROS PF">7 - OUTROS PF</option>
                                                                    <option value="OUTROS PJ">8 - OUTROS PJ</option>
                                                                </Select>
                                                            </div>

                                                            <div class="column is-2" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2 || ((ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15) && ato.pessoal.uniaoEstavel == 1))">
                                                                <InputText
                                                                    :label="`Nº do documento do cônjuge`" 
                                                                    :preenchido="true"
                                                                    :mascara="ato.pessoal.mascaraConjuge"
                                                                    v-model="ato.pessoal.documentoConjuge"
                                                                    :buscarDocumentoConjuge="buscarDocumentoConjuge"
                                                                    :aba="`ato`"
                                                                />
                                                            </div>

                                                            <div class="column is-4" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2 || ((ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15) && ato.pessoal.uniaoEstavel == 1))">
                                                                <InputText
                                                                    :label="`Nome do cônjuge`" 
                                                                    :preenchido="true"
                                                                    :loading="ato.pessoal.loadingConjuge"
                                                                    :adicionarConjuge="adicionarConjugeAto"
                                                                    v-model="ato.pessoal.nomeConjuge"
                                                                />
                                                            </div>

                                                            <div class="column is-2" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2 || ((ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15) && ato.pessoal.uniaoEstavel == 1))">
                                                                <Select 
                                                                    :label="`Cônjuge é participante na operação?`" 
                                                                    :preenchido="true"
                                                                    v-model="ato.pessoal.indicadorConjugeParticipa"
                                                                    ref="selectIndicadorConjugeParticipa"
                                                                    :key="'selectIndicadorConjugeParticipa'"
                                                                >
                                                                    <option value="0">0 - Não</option>
                                                                    <option value="1">1 - Sim</option>
                                                                </Select>
                                                            </div>

                                                            <div class="column is-2" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2 || ((ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15) && ato.pessoal.uniaoEstavel == 1))">
                                                                <Select 
                                                                    :label="`Cônjuge possui CPF?`" 
                                                                    :preenchido="true"
                                                                    v-model="ato.pessoal.indicadorCpfConjugeIdentificado"
                                                                    ref="selectIndicadorCpfConjugeIdentificado"
                                                                    :key="'selectIndicadorCpfConjugeIdentificado'"
                                                                >
                                                                    <option value="0">0 - Não</option>
                                                                    <option value="1">1 - Sim</option>
                                                                </Select>
                                                            </div>

                                                            <div class="column is-12"></div>
                                                            
                                                            <div class="column is-one-quarter" v-show="(ato.pessoal.opcaoInconsistencia ?? false)">
                                                                <Select 
                                                                    :label="`Inconsistência`" 
                                                                    :preenchido="true"
                                                                    v-model="ato.pessoal.inconsistencia"
                                                                    ref="selectInconsistenciaAto"
                                                                    :key="'selectInconsistenciaAto'"
                                                                >
                                                                    <option value="1">1 - CPF com inconsistência</option>
                                                                    <option value="2">2 - CIC em conjunto</option>
                                                                    <option value="3">3 - Nome diferente</option>
                                                                    <option value="4">4 - CIC dependente</option>
                                                                    <option value="5">5 - CPF dependente</option>
                                                                    <option value="6">6 - CNPJ com inconsistência</option>
                                                                    <option value="7">7 - Sem CPF (Decisão Judicial)</option>
                                                                    <option value="8">8 - Sem CNPJ (Decisão Judicial)</option>
                                                                    <option value="9">9 - Não consta no documento</option>
                                                                </Select>
                                                            </div>

                                                            <div class="linha-divisoria" v-show="ato.pessoal.nomes && ato.pessoal.nomes.length > 0"><span>Nomes semelhantes</span></div>

                                                            <div class="column is-one-quarter" ref="nomeSemelhante" v-for="(nomes, indiceNome) in ato.pessoal.nomes" style="display: contents;">
                                                                <InputText
                                                                    :label="`Nome semelhante`"
                                                                    :preenchido="true"
                                                                    v-bind:ref="`nomes_ato_${indiceNome}`"
                                                                    v-model="ato.pessoal.nomes[indiceNome]"
                                                                    style="width: 26%; margin-left: 9px"
                                                                />
                                                                
                                                                <Button
                                                                    :classes="`is-danger is-outlined btn-small`" 
                                                                    :icone="`close`" 
                                                                    :titulo="``" 
                                                                    :onclick="removerNome"
                                                                    :parametro1="`ato`"
                                                                    :parametro2="indiceNome"
                                                                    :tabindex="'-1'"
                                                                    style="margin-left: 0px; margin-right: 1%;"
                                                                />
                                                            </div>

                                                            <div class="column is-full">
                                                                <Button
                                                                    :classes="`is-primary btn-small`" 
                                                                    :icone="`math-plus`" 
                                                                    :titulo="`Adicionar nome semelhante`" 
                                                                    :onclick="adicionarNome"
                                                                    :parametro1="`ato`"
                                                                    :parametro2="`nomes_ato`"
                                                                    style="margin-left: -3.3rem; width: 22rem"
                                                                />
                                                            </div>

                                                            <div class="linha-divisoria" v-show="ato.pessoal.representantes && ato.pessoal.representantes.length > 0"><span>Representantes</span></div>

                                                            <div class="column is-full" ref="representante" v-for="(representante, indiceRepresentante) in ato.pessoal.representantes" style="display: contents;">
                                                                <div class="column is-2">
                                                                    <Select 
                                                                        :label="`Classificação`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.representantes[indiceRepresentante].classificacao"
                                                                        :key="`selectClassificacaoAtoRepresentante_${indiceRepresentante}`"
                                                                    >
                                                                        <option 
                                                                            v-for="(tipo, idx) in tiposClassificacao" 
                                                                            :value="idx"
                                                                        >{{ tipo }}</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-3">
                                                                    <InputText
                                                                        :label="`CPF`" 
                                                                        :preenchido="true"
                                                                        :buscarDocumentoBeneficiario="buscarDocumentoRepresentante"
                                                                        :indice="indiceRepresentante"
                                                                        :mascara="'###.###.###-##'"
                                                                        v-model="ato.pessoal.representantes[indiceRepresentante].documento"
                                                                    />
                                                                </div>

                                                                <div class="column is-6">
                                                                    <InputText
                                                                        :label="`Nome`" 
                                                                        :preenchido="true"
                                                                        :loading="ato.pessoal.representantes[indiceRepresentante].loading"
                                                                        v-model="ato.pessoal.representantes[indiceRepresentante].nome"
                                                                    />
                                                                </div>
                                                                
                                                                <Button
                                                                    :classes="`is-danger is-outlined btn-small`" 
                                                                    :icone="`close`" 
                                                                    :titulo="``" 
                                                                    :onclick="removerRepresentante"
                                                                    :parametro1="indiceRepresentante"
                                                                    :tabindex="'-1'"
                                                                    style="margin-left: 0px; margin-right: 1%;"
                                                                />
                                                            </div>

                                                            <div class="column is-full">
                                                                <Button
                                                                    :classes="`is-blue-steel btn-small`" 
                                                                    :icone="`math-plus`" 
                                                                    :titulo="`Adicionar representante`" 
                                                                    :onclick="adicionarRepresentante"
                                                                    style="margin-left: -3.3rem; margin-top: 0.3rem; width: 22rem"
                                                                />
                                                            </div>

                                                            <div class="linha-divisoria" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2 || ((ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15) && ato.pessoal.uniaoEstavel == 1)) && ato.pessoal.conjuge.nome != ''"><span>Dados do cônjuge</span></div>

                                                            <div class="columns" v-show="ato.pessoal.tipoDocumento != 'CNPJ' && (ato.pessoal.estadoCivil == 1 || ato.pessoal.estadoCivil == 2 || ((ato.pessoal.estadoCivil == 10 || ato.pessoal.estadoCivil == 11 || ato.pessoal.estadoCivil == 14 || ato.pessoal.estadoCivil == 15) && ato.pessoal.uniaoEstavel == 1)) && ato.pessoal.conjuge.nome != ''">
                                                                <div class="column is-2" :ref="`parte_ato_conjuge`">
                                                                    <Select 
                                                                        :label="`Classificação`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.conjuge.classificacao"
                                                                        ref="selectClassificacaoAtoConjuge"
                                                                    >
                                                                        <option 
                                                                            v-for="(tipo, idx) in tiposClassificacao" 
                                                                            :value="idx"
                                                                        >{{ tipo }}</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-2">
                                                                    <Select 
                                                                        :label="`Tipo do documento`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.conjuge.tipoDocumento"
                                                                        ref="selectTipoDocumentoAtoConjuge"
                                                                    >
                                                                        <option value="CPF">1 - CPF</option>
                                                                        <option value="CIC">2 - CIC</option>
                                                                        <option value="CNPJ">3 - CNPJ</option>
                                                                        <option value="RG">4 - RG</option>
                                                                        <option value="CGC">5 - CGC</option>
                                                                        <option value="OUTROS">6 - OUTROS</option>
                                                                        <option value="OUTROS PF">7 - OUTROS PF</option>
                                                                        <option value="OUTROS PJ">8 - OUTROS PJ</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-one-fifth">
                                                                    <InputText
                                                                        :label="`Nº do documento`" 
                                                                        :preenchido="true"
                                                                        :mascara="ato.pessoal.conjuge.mascara"
                                                                        :inconsistencia="definirInconsistencia"
                                                                        v-model="ato.pessoal.conjuge.documento"
                                                                        :buscarDocumento="buscarDocumento"
                                                                        :aba="`ato`"
                                                                        :conjuge="true"
                                                                    />
                                                                </div>

                                                                <div class="column is-5">
                                                                    <InputText
                                                                        :label="verificaCpfCnpj(ato.pessoal?.documento) != undefined ? verificaCpfCnpj(ato.pessoal?.documento) ? `Nome` : `Razão social` : `Nome/Razão social`" 
                                                                        :preenchido="true"
                                                                        :loading="ato.pessoal.conjuge.loading ?? false"
                                                                        v-model="ato.pessoal.conjuge.nome"
                                                                    />
                                                                </div>

                                                                <div style="display: flex">             
                                                                    <Button
                                                                        :classes="`is-warning-alt is-outlined btn-small`" 
                                                                        :icone="`danger`" 
                                                                        :titulo="``" 
                                                                        :onclick="definirInconsistenciaConjuge"
                                                                        :parametro1="`ato`"
                                                                        :tabindex="'-1'"
                                                                        style="margin-left: 0;"
                                                                    />
                                                                </div>

                                                                <div class="column is-2" v-show="ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && ato.pessoal.conjuge.tipoDocumento != 'OUTROS PJ'">
                                                                    <Select 
                                                                        :label="`Nacionalidade`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.conjuge.nacionalidade"
                                                                        ref="selectNacionalidadeAtoConjuge"
                                                                    >
                                                                        <option 
                                                                            v-for="(tipo, idx) in tiposNacionalidade" 
                                                                            :value="idx"
                                                                        >{{ tipo }}</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-2" v-show="ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && ato.pessoal.conjuge.tipoDocumento != 'OUTROS PJ'">
                                                                    <Select 
                                                                        :label="`Estado civil`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.conjuge.estadoCivil" 
                                                                        ref="selectEstadoCivilAtoConjuge"                                                                      
                                                                    >
                                                                        <option 
                                                                            v-for="(tipo, idx) in tiposEstadosCivil" 
                                                                            :value="idx"
                                                                        >{{ tipo }}</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-one-third" v-show="(ato.pessoal.conjuge.estadoCivil == 1 || ato.pessoal.conjuge.estadoCivil == 2) && ato.pessoal.conjuge.tipoDocumento != 'CNPJ'">
                                                                    <Select 
                                                                        :label="`Regime de bens`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.conjuge.regimeBens"
                                                                        ref="selectRegimeAtoConjuge"
                                                                    >
                                                                        <option 
                                                                            v-for="(tipo, idx) in tiposRegimeBens" 
                                                                            :value="idx"
                                                                        >{{ tipo }}</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-one-fifth" v-show="(ato.pessoal.conjuge.estadoCivil == '10' || ato.pessoal.conjuge.estadoCivil == '14')">
                                                                    <Select 
                                                                        :label="`União estável`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.conjuge.uniaoEstavel"
                                                                        ref="selectUniaoEstavelAtoConjuge"
                                                                    >
                                                                        <option value="0">0 - Não</option>
                                                                        <option value="1">1 - Sim</option>
                                                                    </Select>
                                                                </div>      

                                                                <div class="column is-2">
                                                                    <InputText
                                                                        :label="`Porcentagem de participação na operação`"
                                                                        :preenchido="true"
                                                                        :mascaraMonetaria="mascaraMonetaria"
                                                                        v-model="ato.pessoal.conjuge.fracao"
                                                                    />
                                                                </div>

                                                                <div class="column is-2">
                                                                    <InputText
                                                                        :label="`Valor em moeda corrente`"
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.conjuge.valorFracional"
                                                                        :mascaraMonetaria="mascaraMonetaria"
                                                                    />
                                                                </div>

                                                                <div 
                                                                    v-if="!(ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && (ato.pessoal.conjuge.estadoCivil == 1 || ato.pessoal.conjuge.estadoCivil == 2))"
                                                                    :class="{
                                                                        column: true,
                                                                        'is-1': ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && (ato.pessoal.conjuge.estadoCivil == 10 || ato.pessoal.conjuge.estadoCivil == 11 || ato.pessoal.conjuge.estadoCivil == 14 || ato.pessoal.conjuge.estadoCivil == 15),
                                                                        'is-3': ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && (ato.pessoal.conjuge.estadoCivil != 1 && ato.pessoal.conjuge.estadoCivil != 2 && ato.pessoal.conjuge.estadoCivil != 10 && ato.pessoal.conjuge.estadoCivil != 14)
                                                                    }"
                                                                ></div>

                                                                <div class="column is-2" v-show="ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && (ato.pessoal.conjuge.estadoCivil == 1 || ato.pessoal.conjuge.estadoCivil == 2 || ((ato.pessoal.conjuge.estadoCivil == 10 || ato.pessoal.conjuge.estadoCivil == 11 || ato.pessoal.conjuge.estadoCivil == 14 || ato.pessoal.conjuge.estadoCivil == 15) && ato.pessoal.conjuge.uniaoEstavel == 1))">
                                                                    <Select 
                                                                        :label="`Tipo do documento do cônjuge`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.conjuge.tipoDocumentoConjuge"
                                                                        ref="selectTipoDocumentoConjugeAtoConjuge"
                                                                    >
                                                                        <option value="CPF">1 - CPF</option>
                                                                        <option value="CIC">2 - CIC</option>
                                                                        <option value="CNPJ">3 - CNPJ</option>
                                                                        <option value="RG">4 - RG</option>
                                                                        <option value="CGC">5 - CGC</option>
                                                                        <option value="OUTROS">6 - OUTROS</option>
                                                                        <option value="OUTROS PF">7 - OUTROS PF</option>
                                                                        <option value="OUTROS PJ">8 - OUTROS PJ</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="column is-2" v-show="ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && (ato.pessoal.conjuge.estadoCivil == 1 || ato.pessoal.conjuge.estadoCivil == 2 || ((ato.pessoal.conjuge.estadoCivil == 10 || ato.pessoal.conjuge.estadoCivil == 11 || ato.pessoal.conjuge.estadoCivil == 14 || ato.pessoal.conjuge.estadoCivil == 15) && ato.pessoal.conjuge.uniaoEstavel == 1))">
                                                                    <InputText
                                                                        :label="`Nº do documento do cônjuge`" 
                                                                        :preenchido="true"
                                                                        :mascara="ato.pessoal.conjuge.mascaraConjuge"
                                                                        v-model="ato.pessoal.conjuge.documentoConjuge"
                                                                        :buscarDocumentoConjuge="buscarDocumentoConjuge"
                                                                        :aba="`ato`"
                                                                        :conjuge="true"
                                                                    />
                                                                </div>

                                                                <div class="column is-two-fifths" v-show="ato.pessoal.conjuge.tipoDocumento != 'CNPJ' && (ato.pessoal.conjuge.estadoCivil == 1 || ato.pessoal.conjuge.estadoCivil == 2 || ((ato.pessoal.conjuge.estadoCivil == 10 || ato.pessoal.conjuge.estadoCivil == 11 || ato.pessoal.conjuge.estadoCivil == 14 || ato.pessoal.conjuge.estadoCivil == 15) && ato.pessoal.conjuge.uniaoEstavel == 1))">
                                                                    <InputText
                                                                        :label="`Nome do cônjuge`" 
                                                                        :preenchido="true"
                                                                        :loading="ato.pessoal.conjuge.loadingConjuge"
                                                                        v-model="ato.pessoal.conjuge.nomeConjuge"
                                                                    />
                                                                </div>

                                                                <div class="column is-12"></div>
                                                                
                                                                <div class="column is-one-quarter" v-show="ato.pessoal.conjuge.opcaoInconsistencia ?? false">
                                                                    <Select 
                                                                        :label="`Inconsistência`" 
                                                                        :preenchido="true"
                                                                        v-model="ato.pessoal.conjuge.inconsistencia"
                                                                        ref="selectInconsistenciaAtoConjuge"
                                                                    >
                                                                        <option value="1">1 - CPF com inconsistência</option>
                                                                        <option value="2">2 - CIC em conjunto</option>
                                                                        <option value="3">3 - Nome diferente</option>
                                                                        <option value="4">4 - CIC dependente</option>
                                                                        <option value="5">5 - CPF dependente</option>
                                                                        <option value="6">6 - CNPJ com inconsistência</option>
                                                                        <option value="7">7 - Sem CPF (Decisão Judicial)</option>
                                                                        <option value="8">8 - Sem CNPJ (Decisão Judicial)</option>
                                                                        <option value="9">9 - Não consta no documento</option>
                                                                    </Select>
                                                                </div>

                                                                <div class="linha-divisoria" v-show="ato.pessoal.conjuge.nomes && ato.pessoal.conjuge.nomes.length > 0"><span>Nomes semelhantes</span></div>

                                                                <div class="column is-one-quarter" ref="nomeSemelhante" v-for="(nomes, indiceNome) in ato.pessoal.conjuge.nomes" style="display: contents;">
                                                                    <InputText
                                                                        :label="`Nome semelhante`"
                                                                        :preenchido="true"
                                                                        :ref="`nomes_ato_conjuge_${indiceNome}`"
                                                                        v-model="ato.pessoal.conjuge.nomes[indiceNome]"
                                                                        style="width: 26%; margin-left: 9px"
                                                                    />
                                                                    
                                                                    <Button
                                                                        :classes="`is-danger is-outlined btn-small`" 
                                                                        :icone="`close`" 
                                                                        :titulo="``" 
                                                                        :onclick="removerNomeConjuge"
                                                                        :parametro1="`ato`"
                                                                        :parametro2="indiceNome"
                                                                        :tabindex="'-1'"
                                                                        style="margin-left: 0px; margin-right: 1%;"
                                                                    />
                                                                </div>

                                                                <div class="column is-full">
                                                                    <Button
                                                                        :classes="`is-primary btn-small`" 
                                                                        :icone="`math-plus`" 
                                                                        :titulo="`Adicionar nome semelhante`" 
                                                                        :onclick="adicionarNomeConjuge"
                                                                        :parametro1="`ato`"
                                                                        :parametro2="`nomes_ato_conjuge`"
                                                                        style="margin-left: -3.3rem; margin-top: 0.3rem; width: 22rem"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="column is-full" :style="`display: flex; justify-content: ${partesAdicionadas.length > 0 ? 'space-between' : 'flex-end'}`"> 
                                                                <div v-show="partesAdicionadas.length > 0" style="margin-left: -3.3rem;">
                                                                    <Button
                                                                        :classes="`is-info is-outlined btn-small`" 
                                                                        :icone="`math-plus`" 
                                                                        :titulo="`Selecionar partes informadas anteriormente`" 
                                                                        :onclick="selecionarPartes"
                                                                        :parametro1="ato.indice"
                                                                        style="margin-left: 0px; width: 22rem"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    :classes="`is-link`" 
                                                                    :icone="`math-plus`" 
                                                                    :titulo="`${ato.pessoal.edicao ? 'Salvar' : 'Adicionar'} parte (ALT + Q)`" 
                                                                    :onclick="adicionarParteAto"
                                                                    v-if="ato.cadastrado != 1"
                                                                    :style="`margin-right: 1rem; margin-top: -0.5rem; margin-bottom: 1rem; width: 30rem`"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="column is-12"> 
                                                <Button
                                                    :classes="`is-purple`" 
                                                    :icone="`math-plus`" 
                                                    :titulo="`${ato.edicao ? 'Salvar' : 'Adicionar'} ato (CTRL + Q)`" 
                                                    :onclick="adicionarAto"
                                                    style="margin-bottom: 1rem; margin-top: -1rem; width: 40rem; float: right;"
                                                    v-if="ato.cadastrado != 1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn-control" style="justify-content: space-between;">
                            <Button :classes="`is-danger is-outlined`" :titulo="`Voltar`" :tabindex="'-1'"
                                :onclick="voltar" :parametro1="1" style="width: 20rem" />

                            <div>
                                <Button :classes="`is-success`" :titulo="`Salvar dados (CTRL + Enter)`"
                                    :onclick="enviar" style="width: 20rem" />
                            </div>
                        </div>
                    </div>

                    <div class="formulario" style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 3rem;
                    ">
                        <Versao :horaAtual="horaAtual" />

                        <Button :classes="`is-${contagemRegressivaClass} btn-small`"
                            style="margin-right: -45px; width: 16rem;" :onclick="refreshToken"
                            :titulo="`${contagemRegressiva}`" />
                    </div>
                    <Button :classes="`btn-focus`"
                        style="background-color: #eee!important; pointer-events: none; border: none" />
                </div>
            </section>
        </div>
        
        <div class="modal" id="modal-copiar-ato" :class="{ 'is-active': modalCopiarAto }">
            <div class="modal-background" @click="modalCopiarAto = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Copiar ato</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalCopiarAto = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        <div class="columns">
                            <div class="column is-3">
                                <InputText
                                    :label="`Nº do ato inicial`"
                                    :preenchido="true"
                                    v-model="numeroAtoInicial"
                                />
                            </div>
                            <div class="column is-1" style="width: 4%; display: flex; transform: translate(-5px, -10px); align-items: flex-end;">
                                a
                            </div>
                            <div class="column is-3">
                                <InputText
                                    :label="`Nº do ato final`"
                                    :preenchido="true"
                                    v-model="numeroAtoFinal"
                                />
                            </div>
                            <div class="column is-1" style="width: 5%; display: flex; transform: translate(-6px, -10px); align-items: flex-end;">
                                de
                            </div>
                            <div class="column is-5">
                                <InputText
                                    :label="`Data do registro`" 
                                    :preenchido="true"
                                    :tipo="`date`"
                                    v-model="dataRegistro"
                                />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-12">
                                <label class="checkbox">
                                    Transferir partes envolvidas
                                    <input type="checkbox" v-model="transferirPartes">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalCopiarAto = false">Cancelar</button>
                    <button class="button is-success is-outlined" @click="copiarAto(indiceAto, false)">Salvar</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" id="modal-remover-ato" :class="{ 'is-active': modalRemoverAto }">
            <div class="modal-background" @click="modalRemoverAto = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Atenção!</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalRemoverAto = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja realmente remover o ato?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalRemoverAto = false">Não</button>
                    <button class="button is-success is-outlined" @click="removerAto(indiceRemoverAto, false)">Sim</button>
                </footer>
            </div>
        </div>

        <div class="modal" id="modal-selecionar-partes" :class="{ 'is-active': modalSelecionarPartes }">
            <div class="modal-background" @click="modalSelecionarPartes = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title"></p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalSelecionarPartes = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        <h5 style="text-align: center">Selecione as partes que você deseja copiar dos atos anteriores</h5>
                        <div style="margin: 0 2rem;">
                            <label class="checkbox" v-for="(parte, idx) in partesAdicionadas">
                                {{  parte.classificacao }} - {{  parte.documento == '' ? 'Documento não informado' : parte.documento }} - {{ parte.nome }}
                                <input type="checkbox" :id="`checkbox-${idx}`" v-model="parte.selecionado">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalSelecionarPartes = false">Cancelar</button>
                    <button class="button is-success is-outlined" @click="partesSelecionadas()">Adicionar</button>
                </footer>
            </div>
        </div>
        
        <div class="modal" :class="{ 'is-active': modalConfimacaoPorcentagem }">
            <div class="modal-background" @click="modalConfimacaoPorcentagem = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Atenção!</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalConfimacaoPorcentagem = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        A porcentagem de participação na operação dos proprietários é maior do que 100%. Deseja salvar o registro mesmo assim?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-danger is-outlined" tabindex="-1" @click="modalConfimacaoPorcentagem = false">Não</button>
                    <button class="button is-success is-outlined" @click="enviar(1)">Sim</button>
                </footer>
            </div>
        </div>

        <div class="loading-container hidden" id="loading">
            <div class="loading-spinner"></div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import bigInt from "big-integer"
import moment from "moment"
import { Buffer } from 'buffer'
import { cpf, cnpj } from 'cpf-cnpj-validator'
import dbPromise from '../../indexedDB.js';
import { autenticar } from '../../services/auth.js'

export default {
    name: "Indicadores",
    components: {},
    data() {
        return {
            mascaraMonetaria: {
                prefix: '',
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false
            },
            ato: {
                indice: 0,
                edicao: false,
                numeroAto: '',
                dataRegistro: '',
                descricao: '',
                tipo: '',
                descricaoAdicional: '',
                dataInstrumento: '',
                tipoMoeda: '',
                formaPagamento: '',
                mesAnoUltimaParcela: '',
                primeiraDataParcela: '',
                numeroParcelas: '',
                folha: '',
                valorPagoAteDataAto: '',
                indicadorPermutaBens: '0',
                indicadorPagamentoDinheiro: '0',
                valorPagoMoedaCorrenteDataAto: '',
                existeDoiAnterior: '',
                naturezaTitulo: '',
                valorDeclarado: '',
                valorBaseCalculoItbiItcmd: '',
                valorFiscal: '',
                onus: '',
                tipoTransacao: '',
                cancelamento: '',
                seloFiscalizacao: '',
                tipoParteTransacionada: '1',
                porcentagemParticipacaoArea: '',
                pessoal: {
                    indice: 0,
                    documento: '',
                    nome: '',
                    nacionalidade: '',
                    fracao: '',
                    valorFracional: '',
                    indicadorConjugeParticipa: '',
                    indicadorCpfConjugeIdentificado: '1',
                    estadoCivil: '',
                    uniaoEstavel: '',
                    regimeBens: '',
                    classificacao: '',
                    tipoDocumento: '',
                    tipoDocumentoConjuge: '',
                    documentoConjuge: '',
                    nomeConjuge: '',
                    inconsistencia: '',
                    conjuge: '',
                    nomes: [],
                    representantes: [],
                    loading: false,
                    loadingConjuge: false,
                    opcaoInconsistencia: false,
                    mascara: false,
                    mascaraConjuge: false,
                    conjuge: {
                        documento: '',
                        nome: '',
                        nacionalidade: '',
                        fracao: '',
                        valorFracional: '',
                        estadoCivil: '',
                        uniaoEstavel: '',
                        regimeBens: '',
                        classificacao: '',
                        tipoDocumento: '',
                        tipoDocumentoConjuge: '',
                        documentoConjuge: '',
                        nomeConjuge: '',
                        inconsistencia: '',
                        conjuge: '',
                        nomes: [],
                        loading: false,
                        loadingConjuge: false,
                        opcaoInconsistencia: false,
                        mascara: false,
                        mascaraConjuge: false
                    }
                }
            },
            listaAtos: [],
            documentosBuscados: [],
            rerenderKey: 0,
            rerenderKeyGeral: 0,
            modalRemoverAto: false,
            form: {
                cns: '',
                cnm: '',
                numeroRegistro: '',
                empreendimentoLoteamento: '',
                letraRegistro: '',
                numeroCedula: '',
                onusAtivo: '',
                l2Vinculado: '',
                numeroMatricula: '',
                numerosMatriculas: [''],
                dataAbertura: '',
                livroVinculado: '0',
                anotacoes: '0',
                descricaoLivroVinculado: '',
                descricaoAnotacoes: '',
                tipoRegistro: '1',
                numeroOficio: '',
                dataOficio: '',
                processo: {
                    numeroProcesso: '',
                    dataEncerramento: '',
                    numeroInscricao: '',
                    livro: ''
                },
                beneficiariosLivroVinculado: [
                    {
                        naturezaTitulo: '',
                        numero: '',
                        documento: '',
                        nome: ''
                    }
                ],
                tipoLivro3: '',
                inscricao: '',
                registroAuxiliar: '',
                livroTranscricao: '',
                folhaTranscricao: '',
                cep: '',
                enderecos: [],
                tipoImovel: '',
                unidade: [],
                bloco: [],
                contribuinte: '',
                fracaoAreaComum: '',
                areaConstruida: '',
                indicadorAreaConstruidaNaoConsta: '',
                indicadorAreaLoteNaoConsta: '',
                fracaoTempo: '',
                vagas: '',
                nomeCondominio: '',
                complemento: '',
                setor: [],
                loteamento: '',
                cidade: 'São Paulo',
                uf: '35',
                inscricaoMunicipal: '',
                nirf: '',
                sigef: '',
                conjunto: '',
                condominioConjunto: '',
                acidenteGeografico: '',
                numeroIncra: '',
                torre: [],
                andar: [],
                andarVaga: [],
                numeroVagas: [],
                tipoVaga: '',
                naturezaJuridica: '',
                subdistrito: '',
                sncrCcir: '',
                car: '',
                denominacaoRural: '',
                cib: '',
                area: '',
                situacaoConstrucao: '',
                localizacao: '0',
                descricaoRegistroAuxiliar: {
                    numeroAto: '',
                    dataRegistro: '',
                    tipoAto: '',
                    tipoRegistro: '',
                    dataInstrumento: '',
                    tipoInstrumento: '',
                    titulo: '',
                    numeroProcesso: '',
                    bensPenhorados: [
                        ''
                    ],
                    objetoGarantia: [
                        ''
                    ],
                    dataVencimento: '',
                    tipoMoeda: '',
                    valor: '',
                    denominacaoEmpreendimento: '',
                    valorAtribuido: '',
                    dataCasamento: '',
                    tabelionato: '',
                    livro: '',
                    folha: '',
                    tipoParteTransacionada: '',
                    porcentagemParticipacaoArea: '',
                    regimeBens: '',
                    contribuintes: [''],
                    cidade: 'São Paulo',
                    uf: '35',
                    subdistrito: '',
                    pessoal: [],
                    averbacoes: [],
                    enderecos: [
                        {
                            tipoImovel: '',
                            setor: [''],
                            bloco: [''],
                            andar: '',
                            loteamentoCondominio: '',
                            nomeCondominio: '',
                            conjuntoCondominio: '',
                            loteamento: '',
                            torreEdificio: [''],
                            conjuntoEmpreeendimento: '',
                            vagas: '',
                            cep: '',
                            tipoLogradouro: '',
                            nomeLogradouro: '',
                            bairro: '',
                            numero: '',
                            complemento: '',
                            complementoQuadra: '',
                            loteQuadras: [
                                {
                                    quadra: '',
                                    lote: ''
                                }
                            ]
                        }
                    ]
                },
                contribuintes: [],
                matriculaNova: '',
                vigenciaRegistro: '',
                tipoDeclaracao: '0',
                ato: [],
                registroAnterior: [],
                indisponibilidade: [],
                rascunho: true
            },
            numeroRegistro: '',
            tipoRegistro: '',
            tiposRegistro: {
                '1': '1 - Matrícula',
                '2': '2 - Matrícula MÃE',
                '3': '3 - Livro 3-reg-aux',
                '4': '4 - Transcrição'
            },
            tiposNaturezaTitulo: {
                '1': '1 - Instrumento particular com força de escritura pública',
                '2': '2 - Escritura Pública',
                '3': '3 - Título Judicial',
                '4': '4 - Contratos ou termos administrativos',
                '5': '5 - Atos autênticos de países estrangeiros'
            },
            tiposAto: {
                '15': '15 - Adjudicação',
                '41': '41 - Arrematação em Hasta Pública',
                '37': '37 - Cessão de Direitos',
                '11': '11 - Compra e Venda',
                '19': '19 - Dação em Pagamento',
                '25': '25 - Desapropriação, exceto para Reforma Agrária',
                '43': '43 - Dissolução de Sociedade',
                '21': '21 - Distrato de Negócio',
                '53': '53 - Doação, exceto em Adiantamento de Legítima',
                '55': '55 - Doação em adiantamento da legítima',
                '27': '27 - Herança, Legado ou Meação (adjudicação)',
                '45': '45 - Incorporação e loteamento',
                '47': '47 - Integralização/Subscrição de capital',
                '49': '49 - Partilha em Separação/Divórcio/União Estável',
                '13': '13 - Permuta',
                '31': '31 - Procuração em Causa Própria',
                '35': '35 - Promessa de Cessão de Direitos',
                '33': '33 - Promessa de Compra e Venda',
                '51': '51 - Retorno de Capital Próprio na Extinção de Pessoa Jurídica',
                '99': '99 - Outros',
                '101': '101 - Usucapião',
                '102': '102 - Alienação Fiduciária',
                '103': '103 - Consolidação da Propriedade em Nome do Fiduciário',
                '104': '104 - Hipoteca',
                '201': '201 - Encerramento da matrícula pelo cartório',
                '202': '202 - Cancelamento da matrícula administrativamente',
                '203': '203 - Cancelamento da matrícula judicialmente',
                '204': '204 - Requalificação da matrícula (Prov. 13 CGJ/PA)',
                '205': '205 - Abertura da matrícula',
                '206': '206 - Averbação',
                '207': '207 - Cancelamento de ônus',
                '208': '208 - Compromisso de Compra e Venda',
                '209': '209 - Sub-rogação',
                '210': '210 - Transporte de ônus',
                '211': '211 - Formal de Partilha',
                '212': '212 - Construção, Reforma ou Ampliação',
                '213': '213 - Demolição',
                '214': '214 - Desmembramento',
                '215': '215 - Atualização de Dados do Imóvel',
                '216': '216 - Retificação de Dados do Imóvel',
                '217': '217 - Ex-Officio',
                '218': '218 - Casamento',
                '219': '219 - Divórcio',
                '220': '220 - Separação',
                '221': '221 - União Estável ',
                '222': '222 - Aditivo',
                '223': '223 - Ratificação',
                '224': '224 - Retificação ',
                '225': '225 - Óbito',
                '226': '226 - Confrontações',
                '227': '227 - Cláusula',
                '228': '228 - Arresto',
                '229': '229 - Assunção de Dívida',
                '230': '230 - Constituição de Sociedade',
                '231': '231 - Transferência de bens',
                '232': '232 - Cisão total ou parcial',
                '233': '233 - Penhora',
                '234': '234 - Usufruto',
                '235': '235 - Endosso ',
                '236': '236 - Locação',
                '237': '237 - Caução',
                '238': '238 - Indisponibilidade de bens',
                '239': '239 - Cédula de Crédito',
                '240': '240 - Instituição de Condomínio',
                '241': '241 - Instituição de Bem de Família',
                '242': '242 - Cessão de Crédito',
                '243': '243 - Bloqueio de Matrícula ',
                '244': '244 - Ação de Execução',
                '245': '245 - Pacto Comissório ',
                '246': '246 - Desdobro',
                '247': '247 - Arrolamento de bens',
                '248': '248 - Divisão ',
                '249': '249 - Incorporação de Condomínio',
                '250': '250 - Sequestro',
                '251': '251 - Servidão',
                '252': '252 - Penhor',
                '253': '253 - Alvará de Execução',
                '254': '254 - Patrimônio de Afetação ',
                '255': '255 - Unificação',
                '256': '256 - Domínio',
                '257': '257 - Nota Promissória ',
                '258': '258 - Transporte de atos',
                '259': '259 - Incomunicabilidade (Consórcio - p.5º do art. 5º da lei 11.795/2008)',
                '260': '260 - Suspensão do procedimento de execução',
                '261': '261 - Transformação de Natureza Jurídica',
                '262': '262 - Endosso para efeito de caução',
                '263': '263 - Concessão de Direito Real de Uso (CDRU)',
                '264': '264 - Auto de Arrecadação',
                '265': '265 - Cláusula de Reversão',
                '266': '266 - Incorporação de Sociedade',
                '267': '267 - Conferência de bens',
                '268': '268 - Existência de Ação',
                '269': '269 - Compra e venda do Usufruto',
                '270': '270 - Termo de Restauração',
                '271': '271 - Alteração do Estado Civil',
                '272': '272 - Retificação do Estado Civil ',
                '273': '273 - Naturalização',
                '274': '274 - Emancipação',
                '275': '275 - Inclusão de Documentação',
                '276': '276 - Retificação de Documentação',
                '277': '277 - Alteração de Razão Social',
                '278': '278 - Atribuição',
                '279': '279 - Cancelamento parcial de ônus',
                '280': '280 - Compra e venda da nua-propriedade',
                '281': '281 - Emissão',
                '282': '282 - Fusão',
                '283': '283 - Tombamento',
                '284': '284 - Cadastro/Cadastro Atual',
                '285': '285 - Decesso',
                '289': '289 - Partilha de Bens',
                '290': '290 - Título de Domínio - TD',
                '291': '291 - Reserva de Usufruto (Ônus)',
                '292': '292 - Renúncia de Usufruto (Ônus)',
                '293': '293 - Cancelamento de Caução (Ônus)',
                '294': '294 - Mudança de Denominação Social/Alteração da Razão Social',
                '295': '295 - Instituição de Usufruto',
                '296': '296 - Proporção Aquisitiva',
                '297': '297 - Proporção Aquisitiva Atual',
                '298': '298 - Levantamento/cancelamento de Indisponibilidade',
                '299': '299 - Decisão Judicial',
                '300': '300 - Cancelamento da Locação',
                '301': '301 - Qualificação Subjetiva',
                '302': '302 - Condição Resolutiva',
                '303': '303 - Desapropriação para fins de Reforma Agrária',
                '304': '304 - Retorno de Capital Próprio, exceto na Extinção de Pessoa Jurídica',
                '305': '305 - Casamento em comunhão universal de bens',
                '306': '306 - Concessão de Uso Especial para Fins de Moradia (CUEM)',
                '307': '307 - Inventário',
                '308': '308 - Aforamento',
                '309': '309 - Alienação por iniciativa particular ou leilão judicial',
                '310': '310 - Compra e venda de imóvel gravado por anfiteuse',
                '311': '311 - Direito de laje',
                '312': '312 - Direito de superficie'
            },
            tiposMoedas: {
                'Real': '1 - (R$) Real',
                'Cruzeiro Real': '2 - (CR$) Cruzeiro Real',
                'Cruzeiro': '3 - (Cr$) Cruzeiro',
                'Cruzado Novo': '4 - (NCz$) Cruzado Novo',
                'Cruzado': '5 - (Cz$) Cruzado',
                'Réis': '6 - (Rs) Réis',
                'Cruzeiro Novo': '7 - (NCr$) Cruzeiro Novo'
            },
            tiposNacionalidade: {
                'Brasileira': '1 - Brasileira',
                'Afegãne': '2 - Afegãne',
                'Alemã': '3 - Alemã',
                'Albanesa': '4 - Albanesa',
                'Angolana': '5 - Angolana',
                'Anguillana': '6 - Anguillana',
                'Antiguana': '7 - Antiguana',
                'Argelina': '8 - Argelina',
                'Argentina': '9 - Argentina',
                'Armênia': '10 - Armênia',
                'Arubana': '11 - Arubana',
                'Australiana': '12 - Australiana',
                'Austríaca': '13 - Austríaca',
                'Azerbaijano': '14 - Azerbaijano',
                'Bahamense': '15 - Bahamense',
                'Barenita': '16 - Barenita',
                'Bengali': '17 - Bengali',
                'Barbadiana': '18 - Barbadiana',
                'Bielo-russa': '19 - Bielo-russa',
                'Belga': '20 - Belga',
                'Belizenha': '21 - Belizenha',
                'Beninense': '22 - Beninense',
                'Bermudiana': '23 - Bermudiana',
                'Boliviana': '24 - Boliviana',
                'Bósnia': '25 - Bósnia',
                'Britânica': '26 - Britânica',
                'Bruneiana': '27 - Bruneiana',
                'Búlgara': '28 - Búlgara',
                'Burquinês': '29 - Burquinês',
                'Burundinesa': '30 - Burundinesa',
                'Butanesa': '31 - Butanesa',
                'Cabo-verdiana': '32 - Cabo-verdiana',
                'Camaronesa': '33 - Camaronesa',
                'Cambojana': '34 - Cambojana',
                'Canadense': '35 - Canadense',
                'Canário': '36 - Canário',
                'Cazaque': '37 - Cazaque',
                'Chilena': '38 - Chilena',
                'Chinesa': '39 - Chinesa',
                'Cipriota': '40 - Cipriota',
                'Cingapuriana': '41 - Cingapuriana',
                'Colombiana': '42 - Colombiana',
                'Comorense': '43 - Comorense',
                'Congolesa': '44 - Congolesa',
                'Costarriquenha': '45 - Costarriquenha',
                'Coreana': '46 - Coreana',
                'Croata': '47 - Croata',
                'Cubana': '48 - Cubana',
                'Curaçauense': '49 - Curaçauense',
                'Chagositano': '50 - Chagositano',
                'Dinamarquesa': '51 - Dinamarquesa',
                'Djibutiana': '52 - Djibutiana',
                'Dominiquense': '53 - Dominiquense',
                'Egípcia': '54 - Egípcia',
                'Salvadorenha': '55 - Salvadorenha',
                'Árabe': '56 - Árabe',
                'Equatoriana': '57 - Equatoriana',
                'Eritreia': '58 - Eritreia',
                'Eslovaco': '59 - Eslovaco',
                'Esloveno': '60 - Esloveno',
                'Espanhola': '61 - Espanhola',
                'Norte-americana': '62 - Norte-americana',
                'Estoniana': '63 - Estoniana',
                'Etíope': '64 - Etíope',
                'Fijiana': '65 - Fijiana',
                'Filipina': '66 - Filipina',
                'Finlandesa': '67 - Finlandesa',
                'Francesa': '68 - Francesa',
                'Gabonesa': '69 - Gabonesa',
                'Gambiana': '70 - Gambiana',
                'Ganense': '71 - Ganense',
                'Georgiana': '72 - Georgiana',
                'Gibraltariana': '73 - Gibraltariana',
                'Inglesa': '74 - Inglesa',
                'Granadina': '75 - Granadina',
                'Grega': '76 - Grega',
                'Groenlandesa': '77 - Groenlandesa',
                'Guadalupense': '78 - Guadalupense',
                'Guamês': '79 - Guamês',
                'Guatemalteca': '80 - Guatemalteca',
                'Guernesiano': '81 - Guernesiano',
                'Guianense': '82 - Guianense',
                'Franco-guianense': '83 - Franco-guianense',
                'Guinéu-equatoriano ou equatoguineana': '84 - Guinéu-equatoriano ou equatoguineana',
                'Guinéu-equatoriano': '85 - Guinéu-equatoriano',
                'Guineense': '86 - Guineense',
                'Haitiana': '87 - Haitiana',
                'Holandês': '88 - Holandês',
                'Hondurenha': '89 - Hondurenha',
                'Hong-konguiana ou chinesa': '90 - Hong-konguiana ou chinesa',
                'Húngara': '91 - Húngara',
                'Iemenita': '92 - Iemenita',
                'Da ilha Bouvet': '93 - Da ilha Bouvet',
                'Da ilha de Ascensão': '94 - Da ilha de Ascensão',
                'Da ilha de Clipperton': '95 - Da ilha de Clipperton',
                'Manês': '96 - Manês',
                'Natalense': '97 - Natalense',
                'Pitcairnense': '98 - Pitcairnense',
                'Reunionense': '99 - Reunionense',
                'Alandês': '100 - Alandês',
                'Caimanês': '101 - Caimanês',
                'Coquense': '102 - Coquense',
                'Cookense': '103 - Cookense',
                'Faroense': '104 - Faroense',
                'Das ilhas Geórgia do Sul e Sandwich do Sul': '105 - Das ilhas Geórgia do Sul e Sandwich do Sul',
                'Das ilhas Heard e McDonald': '106 - Das ilhas Heard e McDonald',
                'Malvinense': '107 - Malvinense',
                'Norte-marianense': '108 - Norte-marianense',
                'Marshallino': '109 - Marshallino',
                'Das ilhas Menores Afastadas': '110 - Das ilhas Menores Afastadas',
                'Norfolquino': '111 - Norfolquino',
                'Salomônico': '112 - Salomônico',
                'Seichelense': '113 - Seichelense',
                'Toquelauano': '114 - Toquelauano',
                'Turquês': '115 - Turquês',
                'Virginense': '116 - Virginense',
                'Indiano': '117 - Indiano',
                'Indonésia': '118 - Indonésia',
                'Iraniano': '119 - Iraniano',
                'Iraquiana': '120 - Iraquiana',
                'Irlandesa': '121 - Irlandesa',
                'Islandesa': '122 - Islandesa',
                'Israelense': '123 - Israelense',
                'Italiana': '124 - Italiana',
                'Jamaicana': '125 - Jamaicana',
                'Japonesa': '126 - Japonesa',
                'Canalina': '127 - Canalina',
                'Jordaniana': '128 - Jordaniana',
                'Kiribatiana': '129 - Kiribatiana',
                'Kosovar': '130 - Kosovar',
                'Kuwaitiano': '131 - Kuwaitiano',
                'Laosiana': '132 - Laosiana',
                'Lesota': '133 - Lesota',
                'Letão': '134 - Letão',
                'Libanesa': '135 - Libanesa',
                'Liberiana': '136 - Liberiana',
                'Líbia': '137 - Líbia',
                'Liechtensteiniense': '138 - Liechtensteiniense',
                'Lituana': '139 - Lituana',
                'Luxemburguesa': '140 - Luxemburguesa',
                'Macauense': '141 - Macauense',
                'Macedônio': '142 - Macedônio',
                'Malgaxe': '143 - Malgaxe',
                'Malaia': '144 - Malaia',
                'Malauiano': '145 - Malauiano',
                'Maldívia': '146 - Maldívia',
                'Malinesa': '147 - Malinesa',
                'Maltesa': '148 - Maltesa',
                'Marroquina': '149 - Marroquina',
                'Martiniquense': '150 - Martiniquense',
                'Mauriciana': '151 - Mauriciana',
                'Mauritana': '152 - Mauritana',
                'Maiotense': '153 - Maiotense',
                'Mexicana': '154 - Mexicana',
                'Micronésia': '155 - Micronésia',
                'Moçambicana': '156 - Moçambicana',
                'Moldavo': '157 - Moldavo',
                'Monegasca': '158 - Monegasca',
                'Mongol': '159 - Mongol',
                'Montenegra': '160 - Montenegra',
                'Montserratiano': '161 - Montserratiano',
                'Birmanês': '162 - Birmanês',
                'Namíbia': '163 - Namíbia',
                'Nauruana': '164 - Nauruana',
                'Nepalesa': '165 - Nepalesa',
                'Nicaraguense': '166 - Nicaraguense',
                'Nigerina': '167 - Nigerina',
                'Nigeriana': '168 - Nigeriana',
                'Niueana': '169 - Niueana',
                'Norueguesa': '170 - Norueguesa',
                'Caledônia': '171 - Caledônia',
                'Neozelandesa': '172 - Neozelandesa',
                'Omani': '173 - Omani',
                'Dos países Baixos Caribenhos': '174 - Dos países Baixos Caribenhos',
                'Palauense': '175 - Palauense',
                'Palestino': '176 - Palestino',
                'Zona do Canal do Panamá': '177 - Zona do Canal do Panamá',
                'Pauásia': '178 - Pauásia',
                'Paquistanesa': '179 - Paquistanesa',
                'Paraguaia': '180 - Paraguaia',
                'Peruana': '181 - Peruana',
                'Franco-polinésia': '182 - Franco-polinésia',
                'Polonesa': '183 - Polonesa',
                'Portorriquenha': '184 - Portorriquenha',
                'Portuguesa': '185 - Portuguesa',
                'Catarense': '186 - Catarense',
                'Queniano': '187 - Queniano',
                'Quirguiz': '188 - Quirguiz',
                'Centro-africana': '189 - Centro-africana',
                'Dominicana': '190 - Dominicana',
                'Tcheco': '191 - Tcheco',
                'Romena': '192 - Romena',
                'Ruandesa': '193 - Ruandesa',
                'Russa': '194 - Russa',
                'Saariano': '195 - Saariano',
                'Pedro-miquelonense': '196 - Pedro-miquelonense',
                'Samoana': '197 - Samoana',
                'Samoano': '198 - Samoano',
                'Samarinês': '199 - Samarinês',
                'Helenense': '200 - Helenense',
                'Santa-lucense': '201 - Santa-lucense',
                'São-bartolomeense': '202 - São-bartolomeense',
                'São-cristovense': '203 - São-cristovense',
                'São-martinhense': '204 - São-martinhense',
                'São-tomense': '205 - São-tomense',
                'Sao-vicentino': '206 - Sao-vicentino',
                'Senegalesa': '207 - Senegalesa',
                'Leonesa': '208 - Leonesa',
                'Sérvia': '209 - Sérvia',
                'Síria': '210 - Síria',
                'Somali': '211 - Somali',
                'Cingalesa': '212 - Cingalesa',
                'Suazi': '213 - Suazi',
                'Sudanesa': '214 - Sudanesa',
                'Sul-sudanês': '215 - Sul-sudanês',
                'Sueca': '216 - Sueca',
                'Suíça': '217 - Suíça',
                'Surinamesa': '218 - Surinamesa',
                'Svalbardense': '219 - Svalbardense',
                'Tadjique': '220 - Tadjique',
                'Tailandesa': '221 - Tailandesa',
                'Taiwanês': '222 - Taiwanês',
                'Tanzaniana': '223 - Tanzaniana',
                'Do Território Britânico do Oceano Índico': '224 - Do Território Britânico do Oceano Índico',
                'Do Territórios do Sul da França': '225 - Do Territórios do Sul da França',
                'Timorense': '226 - Timorense',
                'Togolesa': '227 - Togolesa',
                'Tonganesa': '228 - Tonganesa',
                'Trinitário-tobagense': '229 - Trinitário-tobagense',
                'Tristanita': '230 - Tristanita',
                'Tunisiana': '231 - Tunisiana',
                'Turcomana': '232 - Turcomana',
                'Turca': '233 - Turca',
                'Tuvaluana': '234 - Tuvaluana',
                'Ucraniana': '235 - Ucraniana',
                'Ugandense': '236 - Ugandense',
                'Uruguaia': '237 - Uruguaia',
                'Uzbeque': '238 - Uzbeque',
                'Vanuatuense': '239 - Vanuatuense',
                'Vaticano': '240 - Vaticano',
                'Venezuelana': '241 - Venezuelana',
                'Vietnamita': '242 - Vietnamita',
                'Wallis-futunense': '243 - Wallis-futunense',
                'Zambiana': '244 - Zambiana',
                'Zimbabuana': '245 - Zimbabuana'
            },
            tiposCns: {
                '111179': '111179 - 11\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                '111211': '111211 - 14\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                '111450': '111450 - 1\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                '113282': '113282 - 3\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                '113746': '113746 - 8\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
                '142935': '142935 - 6\u00ba Oficial de Registro de Im\u00f3veis da Comarca de S\u00e3o Paulo - SP',
            },
            tiposClassificacao: {
                'Proprietário': '0 - Proprietário',
                'Adquirente': '1 - Adquirente',
                'Transmitente': '2 - Transmitente',
                'Devedor': '3 - Devedor',
                'Credor': '4 - Credor',
                'Anuente': '5 - Anuente',
                'Interveniente': '6 - Interveniente',
                'Fiador': '7 - Fiador',
                'Avalista': '8 - Avalista',
                'Requerente': '9 - Requerente',
                'Autor': '10 - Autor',
                'Réu': '11 - Réu',
                'Exequente': '12 - Exequente',
                'Executado': '13 - Executado',
                'Instituição de condomínio': '14 - Instituição de condomínio',
                'Convenção de Condomínio': '15 - Convenção de Condomínio',
                'Especificação de Condomínio': '16 - Especificação de Condomínio',
                'Retificação de instituição': '17 - Retificação de instituição',
                'Retificação de convenção': '18 - Retificação de convenção',
                'Retificação de Especificação': '19 - Retificação de Especificação',
                'Cônjuge': '20 - Cônjuge',
                'Convivente': '21 - Convivente',
                'Endossatário': '22 - Endossatário',
                'Endossante': '23 - Endossante',
                'Outorgante': '24 - Outorgante',
                'Outorgado': '25 - Outorgado',
                'Emissor': '26 - Emissor',
                'Emissora': '27 - Emissora',
                'Agente fiduciário': '28 - Agente fiduciário',
                'Interveniente garantidor': '29 - Interveniente garantidor',
                'Garantidor': '30 - Garantidor',
                'Cedente': '31 - Cedente',
                'Cessionário': '32 - Cessionário',
                'Promitente vendedor': '33 - Promitente vendedor',
                'Promitente comprador': '34 - Promitente comprador',
                'Expropriante': '35 - Expropriante',
                'Expropriado': '36 - Expropriado',
                'Interessado': '37 - Interessado',
                'Nu-proprietário': '38 - Nu-proprietário',
                'Usufrutuário': '39 - Usufrutuário',
                'Incorporadora': '40 - Incorporadora',
                'Incorporada': '41 - Incorporada',
                'Instituição Custodiante': '42 - Instituição Custodiante',
                'Agente de Garantia': '43 - Agente de Garantia',
                'Agente de Cobrança': '44 - Agente de Cobrança',
                'Inventariante': '45 - Inventariante',
                'Representante': '46 - Representante'
            },
            tiposEstadosCivil: {
                '1': '1 - Casada',
                '2': '2 - Casado',
                '3': '3 - Divorciada',
                '4': '4 - Divorciado',
                '5': '5 - Espólio',
                '6': '6 - Separada',
                '7': '7 - Separada judicialmente',
                '8': '8 - Separado',
                '9': '9 - Separado judicialmente',
                '10': '10 - Solteira',
                '11': '11 - Solteira, maior',
                '12': '12 - Solteira, menor impúbere',
                '13': '13 - Solteira, menor púbere',
                '14': '14 - Solteiro',
                '15': '15 - Solteiro, maior',
                '16': '16 - Solteiro, menor impúbere',
                '17': '17 - Solteiro, menor púbere',
                '18': '18 - Viúva',
                '19': '19 - Viúvo',
                '20': '20 - Menor emancipado',
                '21': '21 - Outros',
                '22': '22 - Desquitado',
                '23': '23 - Desquitada'
            },
            tiposEstadosCivilOposto: {
                '1': '2',
                '2': '1',
                '3': '4',
                '4': '3',
                '5': '5',
                '6': '8',
                '7': '9',
                '8': '6',
                '9': '7',
                '10': '14',
                '11': '15',
                '12': '16',
                '13': '17',
                '14': '10',
                '15': '11',
                '16': '12',
                '17': '13',
                '18': '19',
                '19': '18',
                '20': '20',
                '21': '21',
                '22': '23',
                '23': '22'
            },
            tiposEstados: {
                '11': '11 - RO',
                '12': '12 - AC',
                '13': '13 - AM',
                '14': '14 - RR',
                '15': '15 - PA',
                '16': '16 - AP',
                '17': '17 - TO',
                '21': '21 - MA',
                '22': '22 - PI',
                '23': '23 - CE',
                '24': '24 - RN',
                '25': '25 - PB',
                '26': '26 - PE',
                '27': '27 - AL',
                '28': '28 - SE',
                '29': '29 - BA',
                '31': '31 - MG',
                '32': '32 - ES',
                '33': '33 - RJ',
                '35': '35 - SP',
                '41': '41 - PR',
                '42': '42 - SC',
                '43': '43 - RS',
                '50': '50 - MS',
                '51': '51 - MT',
                '52': '52 - GO',
                '53': '53 - DF'
            },
            tiposRegimeBens: {
                '1': '1 - Comunhão de bens',
                '2': '2 - Comunhão de bens vigente na Venezuela',
                '3': '3 - Comunhão parcial de bens',
                '4': '4 - Comunhão parcial de bens às Leis da Noruega',
                '5': '5 - Comunhão parcial de bens, à Lei Suiça',
                '6': '6 - Comunhão parcial de bens, antes da vigência da Lei 6.515/77',
                '7': '7 - Comunhão parcial de bens, na vigência da Lei 6.515/77',
                '8': '8 - Comunhão universal de bens',
                '9': '9 - Comunhão universal de bens, antes da vigência da Lei 6.515/77',
                '10': '10 - Comunhão universal de bens, às Leis de Ângola',
                '11': '11 - Comunhão universal de bens, às Leis italianas',
                '12': '12 - Comunhão universal de bens, na vigência da Lei 6.515/77',
                '13': '13 - Conforme a lei vigente em Israel',
                '14': '14 - Leis da Alemanha',
                '15': '15 - Leis da Argentina',
                '16': '16 - Leis da Austrália',
                '17': '17 - Leis da Bolívia',
                '18': '18 - Leis da China',
                '19': '19 - Leis da Colômbia',
                '20': '20 - Leis da Costa do Marfim',
                '21': '21 - Leis da Costa Rica',
                '22': '22 - Leis da Dinamarca',
                '23': '23 - Leis da Espanha',
                '24': '24 - Leis da Finlândia',
                '25': '25 - Leis da França',
                '26': '26 - Leis da Guatemala',
                '27': '27 - Leis da Holanda',
                '28': '28 - Leis da Inglaterra',
                '29': '29 - Leis da Itália',
                '30': '30 - Leis da Islândia',
                '31': '31 - Leis da Jordânia',
                '32': '32 - Leis da Polonia',
                '33': '33 - Leis da República da Coréia',
                '34': '34 - Leis da Suíça',
                '35': '35 - Leis de Angola',
                '36': '36 - Leis de Cuba',
                '37': '37 - Leis de Moscou',
                '38': '38 - Leis de Taiwan',
                '39': '39 - Leis do Canadá',
                '40': '40 - Leis do Japão',
                '41': '41 - Leis do Líbano',
                '42': '42 - Leis do Paraguai',
                '43': '43 - Leis do Uruguai',
                '44': '44 - Leis dos Estados Unidos',
                '45': '45 - Leis Egípcias',
                '46': '46 - Leis Portuguesas',
                '47': '47 - Participação final nos aquestos',
                '48': '48 - Regime de bens conforme as Leis americanas',
                '49': '49 - Regime vigente no Chile',
                '50': '50 - Separação de bens',
                '51': '51 - Separação de bens conforme as Leis da Áustria',
                '52': '52 - Separação de bens, antes da vigência da Lei 6.515/77',
                '53': '53 - Separação de bens, na vigência da Lei 6.515/77',
                '54': '54 - Separação obrigatória de bens',
                '55': '55 - Separação parcial, antes da vigência da Lei 6.515/77',
                '56': '56 - Separação parcial, na vigência da Lei 6.515/77',
                '57': '57 - Separação total de bens, na vigência da Lei 6.515/77'
            },
            numeroAto: '',
            indiceAto: '',
            indiceRemoverParte: '',
            carregando: false,
            edicao: false,
            copia: false,
            modalCopiarAto: false,
            modalSelecionarPartes: false,
            modalConfimacaoPorcentagem: false,
            numeroMatriculaInicial: '',
            numeroMatriculaFinal: '',
            numeroAtoInicial: '',
            numeroAtoFinal: '',
            dataRegistro: '',
            transferirPartes: true,
            partesAdicionadas: [],
            autenticacao: {
                autenticacao: null
            },
            token: {},
            loading: {
                numeroRegistro: false
            },
            expiracaoInterval: null,
            horaAtual: moment().format('HH:mm'),
            contagemRegressiva: '00:00:00',
            contagemRegressivaClass: 'default'
        }
    },
    async created() {
        /**
         * Realizar a autenticação
         */
        await autenticar(this)

        this.tipoRegistro = sessionStorage.getItem('tipoRegistro')
        this.numeroRegistro = sessionStorage.getItem('numeroRegistro')

        /**
         * Define o focus no input
         *
         * @var {Object} oInputNumeroRegistro
         */
        const oInputNumeroRegistro = this.$refs.numeroRegistro

        if (oInputNumeroRegistro && this.form.tipoRegistro != 3) {
            oInputNumeroRegistro.$el.querySelector('input').focus()
        }
    },
    async mounted() {
        /**
         * Realizar a autenticação
         */
        await autenticar(this)

        /**
         * Realiza a contagem da expiração
         */
        this.calcularExpiracao()
        this.expiracaoInterval = setInterval(this.calcularExpiracao, 1000)

        this.verificarRegistro();

        /**
         * Evento para capturar os comandos
         */
        document.addEventListener('keydown', async (event) => {
            /**
             * Realizar a autenticação
             */
            await autenticar(this)

            if (event.altKey && (event.key === 'Q' || event.key === 'q')) {
                this.adicionarParteAto()
                document.querySelector('button.btn-focus').focus()
            }
            if (event.ctrlKey && (event.key === 'Q' || event.key === 'q')) {
                this.adicionarAto()
                document.querySelector('button.btn-focus').focus()
            }
            
            if (event.ctrlKey && event.key === 'Enter') {
                this.enviar()
            }
        })
    },
    watch: {
        form: {
            deep: true,
            immediate: false,
            async handler(novoValor, valorAntigo) {
                if ((novoValor.tipoRegistro != '' || novoValor.numeroRegistro != '') && !this.edicao) {
                    await this.setItem('form', novoValor)
                }
            }
        }
    },
    methods: {
        /**
         * Função para selecionar o ato
         */
        async selecionarAto(iIndice) {
            if (iIndice == '') {
                return
            }

            await this.cancelarEdicaoAto()
     
            this.ato = {
                indice: parseInt(iIndice),
                edicao: true,
                cadastrado: this.form.ato[iIndice].cadastrado ?? 0,
                numeroAto: this.form.ato[iIndice].numeroAto,
                dataRegistro: this.form.ato[iIndice].dataRegistro,
                descricao: this.form.ato[iIndice].descricao,
                tipo: this.form.ato[iIndice].tipo,
                descricaoAdicional: this.form.ato[iIndice].descricaoAdicional,
                dataInstrumento: this.form.ato[iIndice].dataInstrumento,
                tipoMoeda: this.form.ato[iIndice].tipoMoeda,
                formaPagamento: this.form.ato[iIndice].formaPagamento,
                mesAnoUltimaParcela: this.form.ato[iIndice].mesAnoUltimaParcela,
                primeiraDataParcela: this.form.ato[iIndice].primeiraDataParcela,
                numeroParcelas: this.form.ato[iIndice].numeroParcelas,
                folha: this.form.ato[iIndice].folha,
                valorPagoAteDataAto: this.form.ato[iIndice].valorPagoAteDataAto,
                indicadorPermutaBens: this.form.ato[iIndice].indicadorPermutaBens,
                indicadorPagamentoDinheiro: this.form.ato[iIndice].indicadorPagamentoDinheiro,
                valorPagoMoedaCorrenteDataAto: this.form.ato[iIndice].valorPagoMoedaCorrenteDataAto,
                existeDoiAnterior: this.form.ato[iIndice].existeDoiAnterior,
                naturezaTitulo: this.form.ato[iIndice].naturezaTitulo,
                valorDeclarado: this.form.ato[iIndice].valorDeclarado,
                valorBaseCalculoItbiItcmd: this.form.ato[iIndice].valorBaseCalculoItbiItcmd,
                valorFiscal: this.form.ato[iIndice].valorFiscal,
                onus: this.form.ato[iIndice].onus,
                tipoTransacao: this.form.ato[iIndice].tipoTransacao,
                cancelamento: this.form.ato[iIndice].cancelamento,
                seloFiscalizacao: this.form.ato[iIndice].seloFiscalizacao,
                tipoParteTransacionada: parseInt(this.form.ato[iIndice].tipoParteTransacionada ?? '1'),
                porcentagemParticipacaoArea: this.form.ato[iIndice].porcentagemParticipacaoArea,
                pessoal: {
                    indice: 0,
                    documento: '',
                    nome: '',
                    nacionalidade: '',
                    fracao: '',
                    valorFracional: '',
                    indicadorConjugeParticipa: '',
                    indicadorCpfConjugeIdentificado: '1',
                    estadoCivil: '',
                    uniaoEstavel: '',
                    regimeBens: '',
                    classificacao: '',
                    tipoDocumento: '',
                    tipoDocumentoConjuge: '',
                    documentoConjuge: '',
                    nomeConjuge: '',
                    inconsistencia: '',
                    conjuge: '',
                    nomes: [],
                    representantes: [],
                    loading: false,
                    loadingConjuge: false,
                    opcaoInconsistencia: false,
                    mascara: false,
                    mascaraConjuge: false,
                    conjuge: {
                        documento: '',
                        nome: '',
                        nacionalidade: '',
                        fracao: '',
                        valorFracional: '',
                        estadoCivil: '',
                        uniaoEstavel: '',
                        regimeBens: '',
                        classificacao: '',
                        tipoDocumento: '',
                        tipoDocumentoConjuge: '',
                        documentoConjuge: '',
                        nomeConjuge: '',
                        inconsistencia: '',
                        conjuge: '',
                        nomes: [],
                        loading: false,
                        loadingConjuge: false,
                        opcaoInconsistencia: false,
                        mascara: false,
                        mascaraConjuge: false
                    }
                }
            }

            this.cancelarEdicaoParteAto(false)

            this.$forceUpdate()
        },
        /**
         * Função para adicionar uma nova parte no ato
         */
        adicionarParteAto(bExibirAlerta = true) {
            if(this.ato.pessoal.documento == ''){
                if(bExibirAlerta){
                    toastAlert('Informe o documento da parte')
                }

                return
            }

            if(
                (this.ato.pessoal.tipoDocumento == 'CPF' || this.ato.pessoal.tipoDocumento == 'CNPJ') && 
                !cpf.isValid(this.ato.pessoal.documento.replace(/[^\w\s]/gi, "")) && 
                !cnpj.isValid(this.ato.pessoal.documento.replace(/[^\w\s]/gi, "")) &&
                (this.ato.pessoal.documento.replace(/[^\w\s]/gi, "").length == 11 || this.ato.pessoal.documento.replace(/[^\w\s]/gi, "").length == 14)
            ){
                if(bExibirAlerta){
                    toastAlert('CPF/CNPJ inválido')
                }

                return
            }
            
            if(this.ato.pessoal.nome == ''){
                if(bExibirAlerta){
                    toastAlert('Informe o nome da parte')
                }

                return
            }

            if (!this.form.ato[this.ato.indice]?.pessoal) {
                this.form.ato[this.ato.indice] = {
                    pessoal: []
                }
            }

            if (this.form.ato[this.ato.indice].pessoal[this.ato.pessoal.indice]) {
                this.form.ato[this.ato.indice].pessoal[this.ato.pessoal.indice] = {
                    documento: this.ato.pessoal.documento,
                    nome: this.ato.pessoal.nome,
                    nacionalidade: this.ato.pessoal.nacionalidade,
                    fracao: this.ato.pessoal.fracao,
                    valorFracional: this.ato.pessoal.valorFracional,
                    indicadorConjugeParticipa: this.ato.pessoal.indicadorConjugeParticipa,
                    indicadorCpfConjugeIdentificado: this.ato.pessoal.indicadorCpfConjugeIdentificado,
                    classificacao: this.ato.pessoal.classificacao,
                    tipoDocumento: this.ato.pessoal.tipoDocumento,
                    tipoDocumentoConjuge: this.ato.pessoal.tipoDocumentoConjuge,
                    documentoConjuge: this.ato.pessoal.documentoConjuge,
                    nomeConjuge: this.ato.pessoal.nomeConjuge,
                    inconsistencia: this.ato.pessoal.inconsistencia,
                    conjuge: this.ato.pessoal.conjuge,
                    estadoCivil: this.ato.pessoal.estadoCivil,
                    uniaoEstavel: this.ato.pessoal.uniaoEstavel,
                    regimeBens: this.ato.pessoal.regimeBens,
                    nomes: this.ato.pessoal.nomes,
                    representantes: this.ato.pessoal.representantes
                }

                if (bExibirAlerta) {
                    toastAlert(`Parte alterada com sucesso`, 'success')
                }
            } else {
                this.form.ato[this.ato.indice].pessoal.push({
                    documento: this.ato.pessoal.documento,
                    nome: this.ato.pessoal.nome,
                    nacionalidade: this.ato.pessoal.nacionalidade,
                    fracao: this.ato.pessoal.fracao,
                    valorFracional: this.ato.pessoal.valorFracional,
                    indicadorConjugeParticipa: this.ato.pessoal.indicadorConjugeParticipa,
                    indicadorCpfConjugeIdentificado: this.ato.pessoal.indicadorCpfConjugeIdentificado,
                    classificacao: this.ato.pessoal.classificacao,
                    tipoDocumento: this.ato.pessoal.tipoDocumento,
                    tipoDocumentoConjuge: this.ato.pessoal.tipoDocumentoConjuge,
                    documentoConjuge: this.ato.pessoal.documentoConjuge,
                    nomeConjuge: this.ato.pessoal.nomeConjuge,
                    inconsistencia: this.ato.pessoal.inconsistencia,
                    conjuge: this.ato.pessoal.conjuge,
                    estadoCivil: this.ato.pessoal.estadoCivil,
                    uniaoEstavel: this.ato.pessoal.uniaoEstavel,
                    regimeBens: this.ato.pessoal.regimeBens,
                    nomes: this.ato.pessoal.nomes,
                    representantes: this.ato.pessoal.representantes
                })

                if (this.ato.pessoal.conjuge.classificacao && this.ato.pessoal.conjuge.classificacao != '') {
                    if (!this.form.ato[this.ato.indice]?.pessoal) {
                        this.form.ato[this.ato.indice] = {
                            pessoal: []
                        }
                    }

                    this.form.ato[this.ato.indice].pessoal.push({
                        documento: this.ato.pessoal.conjuge.documento,
                        nome: this.ato.pessoal.conjuge.nome,
                        nacionalidade: this.ato.pessoal.conjuge.nacionalidade,
                        fracao: this.ato.pessoal.conjuge.fracao,
                        valorFracional: this.ato.pessoal.conjuge.valorFracional,
                        indicadorConjugeParticipa: this.ato.pessoal.conjuge.indicadorConjugeParticipa,
                        classificacao: this.ato.pessoal.conjuge.classificacao,
                        tipoDocumento: this.ato.pessoal.conjuge.tipoDocumento,
                        tipoDocumentoConjuge: this.ato.pessoal.conjuge.tipoDocumentoConjuge,
                        documentoConjuge: this.ato.pessoal.conjuge.documentoConjuge,
                        nomeConjuge: this.ato.pessoal.conjuge.nomeConjuge,
                        inconsistencia: this.ato.pessoal.conjuge.inconsistencia,
                        conjuge: this.ato.pessoal.conjuge.conjuge,
                        estadoCivil: this.ato.pessoal.conjuge.estadoCivil,
                        uniaoEstavel: this.ato.pessoal.conjuge.uniaoEstavel,
                        regimeBens: this.ato.pessoal.conjuge.regimeBens,
                        nomes: this.ato.pessoal.conjuge.nomes
                    })
                }

                if (bExibirAlerta) {
                    toastAlert(`Parte adicionada com sucesso`, 'success')
                }
            }
            
            if(this.ato.pessoal.representantes && this.ato.pessoal.representantes.length > 0){
                for (const iIndiceRepresentante in this.ato.pessoal.representantes){
                    if(Object.values(this.form.ato[this.ato.indice].pessoal).some(pessoa => pessoa.documento === this.ato.pessoal.representantes[iIndiceRepresentante].documento)){
                        continue;
                    }

                    this.form.ato[this.ato.indice].pessoal.push({	
                        documento: this.ato.pessoal.representantes[iIndiceRepresentante].documento,
                        nome: this.ato.pessoal.representantes[iIndiceRepresentante].nome,
                        nacionalidade: 'Brasileira',
                        classificacao: this.ato.pessoal.representantes[iIndiceRepresentante].classificacao,
                        tipoDocumento: 'CPF',
                        opcaoInconsistencia: false,
                        inconsistencia: '',
                        fracao: '',
                        valorFracional: '',
                        indicadorConjugeParticipa: '',
                        indicadorCpfConjugeIdentificado: '',
                        tipoDocumentoConjuge: '',
                        documentoConjuge: '',
                        nomeConjuge: '',
                        conjuge: {
                            documento: '',
                            nome: '',
                            nacionalidade: '',
                            fracao: '',
                            valorFracional: '',
                            estadoCivil: '',
                            uniaoEstavel: '',
                            regimeBens: '',
                            classificacao: '',
                            tipoDocumento: '',
                            tipoDocumentoConjuge: '',
                            documentoConjuge: '',
                            nomeConjuge: '',
                            inconsistencia: '',
                            conjuge: '',
                            nomes: [],
                            loading: false,
                            loadingConjuge: false,
                            opcaoInconsistencia: false,
                            mascara: false,
                            mascaraConjuge: false                        
                        },
                        estadoCivil: '',
                        uniaoEstavel: '',
                        regimeBens: '',
                        nomes: []
                    })
                }
            }
         
            this.cancelarEdicaoParteAto()
        },
        /**
         * Função para remover a parte do ato
         */
        removerParteAto(bAbrirModal = true) {
            this.modalRemoverParte = false

            if (this.form.ato[this.ato.indice].pessoal[this.ato.pessoal.indice]) {
                this.form.ato[this.ato.indice].pessoal = [...this.form.ato[this.ato.indice].pessoal]
                this.form.ato[this.ato.indice].pessoal.splice(this.ato.pessoal.indice, 1)
            }

            this.cancelarEdicaoParteAto(false)

            toastAlert('Parte removida com sucesso', 'success')
        },
        /**
         * Função para adicionar um novo ato
         */
        adicionarAto(bExibirAlerta = true){
            if(this.ato.numeroAto == ''){
                if(bExibirAlerta){
                    toastAlert('Informe o número do ato')
                }

                return
            }

            if(this.ato.dataRegistro == ''){
                if(bExibirAlerta){
                    toastAlert('Informe a data do registro do ato')
                }

                return
            }

            if(this.ato.descricao == ''){
                if(bExibirAlerta){
                    toastAlert('Informe o tipo do ato')
                }

                return
            }

            if(this.ato.tipo == ''){
                if(bExibirAlerta){
                    toastAlert('Informe o título do ato')
                }

                return
            }

            if(this.ato.dataInstrumento == ''){
                if(bExibirAlerta){
                    toastAlert('Informe a data do instrumento do ato')
                }

                return
            }

            if(!this.form.ato){
                this.form.ato = []
            }
            
            if(this.ato.cancelamento.length > 0){
                for(const iNumeroAto of this.ato.cancelamento){
                    if(iNumeroAto == ''){
                        continue
                    }
            
                    this.form.ato[this.form.ato.findIndex(ato => ato.numeroAto === iNumeroAto)].onus = '0'
                }
            }

            if(this.form.ato[this.ato.indice]){
                this.form.ato[this.ato.indice] = {
                    numeroAto: this.ato.numeroAto,
                    dataRegistro: this.ato.dataRegistro,
                    descricao: this.ato.descricao,
                    tipo: this.ato.tipo,
                    descricaoAdicional: this.ato.descricaoAdicional,
                    dataInstrumento: this.ato.dataInstrumento,
                    tipoMoeda: this.ato.tipoMoeda,
                    formaPagamento: this.ato.formaPagamento,
                    mesAnoUltimaParcela: this.ato.mesAnoUltimaParcela,
                    primeiraDataParcela: this.ato.primeiraDataParcela,
                    numeroParcelas: this.ato.numeroParcelas,
                    folha: this.ato.folha,
                    valorPagoAteDataAto: this.ato.valorPagoAteDataAto,
                    indicadorPermutaBens: this.ato.indicadorPermutaBens,
                    indicadorPagamentoDinheiro: this.ato.indicadorPagamentoDinheiro,
                    valorPagoMoedaCorrenteDataAto: this.ato.valorPagoMoedaCorrenteDataAto,
                    existeDoiAnterior: this.ato.existeDoiAnterior,
                    naturezaTitulo: this.ato.naturezaTitulo,
                    valorDeclarado: this.ato.valorDeclarado,
                    valorBaseCalculoItbiItcmd: this.ato.valorBaseCalculoItbiItcmd,
                    valorFiscal: this.ato.valorFiscal,
                    onus: this.ato.onus,
                    tipoTransacao: this.ato.tipoTransacao,
                    cancelamento: this.ato.cancelamento,
                    seloFiscalizacao: this.ato.seloFiscalizacao,
                    tipoParteTransacionada: this.ato.tipoParteTransacionada,
                    porcentagemParticipacaoArea: this.ato.porcentagemParticipacaoArea,
                    pessoal: this.form.ato[this.ato.indice].pessoal ?? []
                }

                this.listaAtos[this.ato.indice] = {
                    numeroAto: this.ato.numeroAto,
                    descricao: this.ato.descricao,
                    tipo: this.ato.tipo,
                    descricaoAdicional: this.ato.descricaoAdicional,
                    onus: this.ato.onus
                }

                if(bExibirAlerta){
                    toastAlert(`Ato alterado com sucesso`, 'success')
                }
            }else{
                this.form.ato.push({
                    numeroAto: this.ato.numeroAto,
                    dataRegistro: this.ato.dataRegistro,
                    descricao: this.ato.descricao,
                    tipo: this.ato.tipo,
                    descricaoAdicional: this.ato.descricaoAdicional,
                    dataInstrumento: this.ato.dataInstrumento,
                    tipoMoeda: this.ato.tipoMoeda,
                    formaPagamento: this.ato.formaPagamento,
                    mesAnoUltimaParcela: this.ato.mesAnoUltimaParcela,
                    primeiraDataParcela: this.ato.primeiraDataParcela,
                    numeroParcelas: this.ato.numeroParcelas,
                    folha: this.ato.folha,
                    valorPagoAteDataAto: this.ato.valorPagoAteDataAto,
                    indicadorPermutaBens: this.ato.indicadorPermutaBens,
                    indicadorPagamentoDinheiro: this.ato.indicadorPagamentoDinheiro,
                    valorPagoMoedaCorrenteDataAto: this.ato.valorPagoMoedaCorrenteDataAto,
                    existeDoiAnterior: this.ato.existeDoiAnterior,
                    naturezaTitulo: this.ato.naturezaTitulo,
                    valorDeclarado: this.ato.valorDeclarado,
                    valorBaseCalculoItbiItcmd: this.ato.valorBaseCalculoItbiItcmd,
                    valorFiscal: this.ato.valorFiscal,
                    onus: this.ato.onus,
                    tipoTransacao: this.ato.tipoTransacao,
                    cancelamento: this.ato.cancelamento,
                    seloFiscalizacao: this.ato.seloFiscalizacao,
                    tipoParteTransacionada: this.ato.tipoParteTransacionada,
                    porcentagemParticipacaoArea: this.ato.porcentagemParticipacaoArea,
                    pessoal: []
                })

                this.listaAtos.push({
                    numeroAto: this.ato.numeroAto,
                    descricao: this.ato.descricao,
                    tipo: this.ato.tipo,
                    descricaoAdicional: this.ato.descricaoAdicional,
                    onus: this.ato.onus
                })

                if(bExibirAlerta){
                    toastAlert(`Ato adicionado com sucesso`, 'success')
                }
            }

            this.cancelarEdicaoAto()
        },
        /**
         * Função para selecionar a parte no ato
         */
        async selecionarParteAto(iIndice) {
            if (iIndice == '') {
                return
            }

            await this.cancelarEdicaoParteAto()

            /**
             * Define o select pela referencia
             *
             * @var {object} oSelectInconsistencia
             */
             const oSelectInconsistencia = this.$refs.selectInconsistenciaAto
            oSelectInconsistencia.clearSelectize()

            this.ato.pessoal = {
                indice: parseInt(iIndice),
                edicao: true,
                documento: this.form.ato[this.ato.indice].pessoal[iIndice].documento,
                nome: this.form.ato[this.ato.indice].pessoal[iIndice].nome,
                nacionalidade: this.form.ato[this.ato.indice].pessoal[iIndice].nacionalidade,
                fracao: this.form.ato[this.ato.indice].pessoal[iIndice].fracao,
                valorFracional: this.form.ato[this.ato.indice].pessoal[iIndice].valorFracional,
                indicadorConjugeParticipa: this.form.ato[this.ato.indice].pessoal[iIndice].indicadorConjugeParticipa,
                indicadorCpfConjugeIdentificado: this.form.ato[this.ato.indice].pessoal[iIndice].indicadorCpfConjugeIdentificado ?? '1',
                classificacao: this.form.ato[this.ato.indice].pessoal[iIndice].classificacao,
                tipoDocumento: this.form.ato[this.ato.indice].pessoal[iIndice].tipoDocumento,
                tipoDocumentoConjuge: this.form.ato[this.ato.indice].pessoal[iIndice].tipoDocumentoConjuge,
                documentoConjuge: this.form.ato[this.ato.indice].pessoal[iIndice].documentoConjuge,
                nomeConjuge: this.form.ato[this.ato.indice].pessoal[iIndice].nomeConjuge,
                inconsistencia: this.form.ato[this.ato.indice].pessoal[iIndice].inconsistencia,
                conjuge: this.form.ato[this.ato.indice].pessoal[iIndice].conjuge,
                estadoCivil: this.form.ato[this.ato.indice].pessoal[iIndice].estadoCivil,
                uniaoEstavel: this.form.ato[this.ato.indice].pessoal[iIndice].uniaoEstavel,
                regimeBens: this.form.ato[this.ato.indice].pessoal[iIndice].regimeBens,
                nomes: this.form.ato[this.ato.indice].pessoal[iIndice].nomes,
                representantes: this.form.ato[this.ato.indice].pessoal[iIndice].representantes,
                loading: false,
                loadingConjuge: false,
                opcaoInconsistencia: this.form.ato[this.ato.indice].pessoal[iIndice].inconsistencia != '',
                mascara: false,
                mascaraConjuge: false,
                conjuge: {
                    documento: '',
                    nome: '',
                    nacionalidade: '',
                    fracao: '',
                    valorFracional: '',
                    estadoCivil: '',
                    uniaoEstavel: '',
                    regimeBens: '',
                    classificacao: '',
                    tipoDocumento: '',
                    tipoDocumentoConjuge: '',
                    documentoConjuge: '',
                    nomeConjuge: '',
                    inconsistencia: '',
                    conjuge: '',
                    nomes: [],
                    loading: false,
                    loadingConjuge: false,
                    opcaoInconsistencia: false,
                    mascara: false,
                    mascaraConjuge: false
                }
            }

            this.$forceUpdate()
        },
        atualizarPartesSelecionadas() {
            this.partesAdicionadas = []
            this.listaAtos = []

            Object.keys(this.form.ato).map((idx) => {
                if (this.form.ato[idx].numeroAto && this.form.ato[idx].numeroAto != '') {
                    this.listaAtos.push({
                        numeroAto: this.form.ato[idx].numeroAto,
                        descricao: this.form.ato[idx].descricao,
                        tipo: this.form.ato[idx].tipo,
                        descricaoAdicional: this.form.ato[idx].descricaoAdicional,
                        onus: this.form.ato[idx].onus
                    })

                    if (this.form.ato[idx]?.pessoal) {
                        Object.keys(this.form.ato[idx].pessoal).map((indice) => {
                            if (this.partesAdicionadas.filter(obj => obj.nome == this.form.ato[idx].pessoal[indice].nome).length == 0) {
                                this.partesAdicionadas.push({
                                    ato: idx,
                                    indice: indice,
                                    classificacao: this.form.ato[idx].pessoal[indice].classificacao,
                                    documento: this.form.ato[idx].pessoal[indice].documento,
                                    nome: this.form.ato[idx].pessoal[indice].nome,
                                    selecionado: false
                                })
                            } else {
                                this.partesAdicionadas[this.partesAdicionadas?.findIndex(obj => obj.nome == this.form.ato[idx].pessoal[indice].nome)] = {
                                    ato: idx,
                                    indice: indice,
                                    classificacao: this.form.ato[idx].pessoal[indice].classificacao,
                                    documento: this.form.ato[idx].pessoal[indice].documento,
                                    nome: this.form.ato[idx].pessoal[indice].nome,
                                    selecionado: false
                                }
                            }
                        })
                    }
                }
            })
        },
        /**
         * Função para cancelar a edição do ato
         */
        cancelarEdicaoAto(bRemover = false) {
            if (bRemover && !this.ato.edicao) {
                if (this.form.ato[this.ato.indice]?.pessoal) {
                    this.form.ato[this.ato.indice].pessoal = []
                }
            }

            this.ato = {
                indice: this.listaAtos.length,
                edicao: false,
                cadastrado: 0,
                numeroAto: '',
                dataRegistro: '',
                descricao: '',
                tipo: '',
                descricaoAdicional: '',
                dataInstrumento: '',
                tipoMoeda: '',
                formaPagamento: '',
                mesAnoUltimaParcela: '',
                primeiraDataParcela: '',
                numeroParcelas: '',
                folha: '',
                valorPagoAteDataAto: '',
                indicadorPermutaBens: '0',
                indicadorPagamentoDinheiro: '0',
                valorPagoMoedaCorrenteDataAto: '',
                existeDoiAnterior: '',
                naturezaTitulo: '',
                valorDeclarado: '',
                valorBaseCalculoItbiItcmd: '',
                valorFiscal: '',
                onus: '',
                tipoTransacao: '',
                cancelamento: '',
                seloFiscalizacao: '',
                tipoParteTransacionada: '1',
                porcentagemParticipacaoArea: '',
                pessoal: {
                    indice: 0,
                    edicao: false,
                    documento: '',
                    nome: '',
                    nacionalidade: '',
                    fracao: '',
                    valorFracional: '',
                    indicadorConjugeParticipa: '',
                    indicadorCpfConjugeIdentificado: '1',
                    classificacao: '',
                    tipoDocumento: '',
                    tipoDocumentoConjuge: '',
                    documentoConjuge: '',
                    nomeConjuge: '',
                    inconsistencia: '',
                    conjuge: '',
                    estadoCivil: '',
                    uniaoEstavel: '',
                    regimeBens: '',
                    nomes: '',
                    representantes: [],
                    loading: false,
                    loadingConjuge: false,
                    opcaoInconsistencia: false,
                    mascara: false,
                    mascaraConjuge: false
                }
            }

            this.$forceUpdate()

            /**
             * Define o select pela referencia
             *
             * @var {object} oSelectTipoAto
             * @var {object} oSelectTituloAto
             * @var {object} oSelectTipoMoeda
             * @var {object} oSelectOnus
             * @var {object} oSelectTipoTransacao
             * @var {object} oSelectFormaPagamento
             * @var {object} oSelectTipoParteTransacionada
             * @var {object} oSelectExisteDOIAnterior
             * @var {object} oSelectNaturezaTitulo
             */
                const oSelectTipoAto = this.$refs.selectTipoAto
            oSelectTipoAto.clearSelectize()
            const oSelectTituloAto = this.$refs.selectTituloAto
            oSelectTituloAto.clearSelectize()
            const oSelectTipoMoeda = this.$refs.selectTipoMoeda
            oSelectTipoMoeda.clearSelectize()
            const oSelectOnus = this.$refs.selectOnus
            oSelectOnus.clearSelectize()
            const oSelectTipoTransacao = this.$refs.selectTipoTransacao
            oSelectTipoTransacao.clearSelectize()
            const oSelectFormaPagamento = this.$refs.selectFormaPagamento
            oSelectFormaPagamento.clearSelectize()
            const oSelectExisteDOIAnterior = this.$refs.selectExisteDOIAnterior
            oSelectExisteDOIAnterior.clearSelectize()
            const oSelectNaturezaTitulo = this.$refs.selectNaturezaTitulo
            oSelectNaturezaTitulo.clearSelectize()

            /**
             * Define o select pela referencia
             *
             * @var {object} oSelect
             */
            const oSelect = this.$refs.selectAto

            /**
             * Reestrutura os atos adicionados
             *
             * @var {object} aDadosAto
             */
            const aDadosAto = Object.entries(this.form.ato).filter(([_, valor]) => valor.numeroAto !== undefined && valor.numeroAto != '').map(([chave, atoUnico]) => {
                return `${atoUnico.numeroAto} ${atoUnico.descricao != '' ? '-' : ''} ${atoUnico.descricao} ${this.tiposAto[atoUnico.tipo] ? ` - ${this.tiposAto[atoUnico.tipo].split(' - ')[1]}` : ''} ${atoUnico.descricaoAdicional ? ` - ${atoUnico.descricaoAdicional}` : ''} ${atoUnico.onus == 1 ? ` - Ônus ativo` : ''}`
            })

            oSelect.clearSelectize()
            oSelect.updateOptions(aDadosAto)
            oSelect.clearSelectize()

            this.cancelarEdicaoParteAto(false)
        },
        /**
         * Função para cancelar a edição da parte do ato
         */
        cancelarEdicaoParteAto(bFocar = true) {
            /**
             * Define o select pela referencia
             *
             * @var {object} oSelectClassificacao
             * @var {object} oSelectTipoDocumento
             * @var {object} oSelectNacionalidade
             * @var {object} oSelectEstadoCivil
             * @var {object} oSelectRegime
             * @var {object} oSelectUniaoEstavel
             * @var {object} oSelectTipoDocumentoConjuge
             * @var {object} oSelectIndicadorConjugeParticipa
             * @var {object} oSelectInconsistencia
             */
            const oSelectClassificacao = this.$refs.selectClassificacaoAto
            oSelectClassificacao.clearSelectize()
            const oSelectTipoDocumento = this.$refs.selectTipoDocumentoAto
            oSelectTipoDocumento.clearSelectize()
            const oSelectNacionalidade = this.$refs.selectNacionalidadeAto
            oSelectNacionalidade.clearSelectize()
            const oSelectEstadoCivil = this.$refs.selectEstadoCivilAto
            oSelectEstadoCivil.clearSelectize()
            const oSelectRegime = this.$refs.selectRegimeAto
            oSelectRegime.clearSelectize()
            const oSelectUniaoEstavel = this.$refs.selectUniaoEstavelAto
            oSelectUniaoEstavel.clearSelectize()
            const oSelectTipoDocumentoConjuge = this.$refs.selectTipoDocumentoConjugeAto
            oSelectTipoDocumentoConjuge.clearSelectize()
            const oSelectIndicadorConjugeParticipa = this.$refs.selectIndicadorConjugeParticipa
            oSelectIndicadorConjugeParticipa.clearSelectize()
            const oSelectInconsistencia = this.$refs.selectInconsistenciaAto
            oSelectInconsistencia.clearSelectize()
            
            this.ato.pessoal = {
                indice: this.form.ato[this.ato.indice]?.pessoal.length ?? 0,
                edicao: false,
                documento: '',
                nome: '',
                nacionalidade: '',
                fracao: '',
                valorFracional: '',
                indicadorConjugeParticipa: '',
                indicadorCpfConjugeIdentificado: '1',
                classificacao: '',
                tipoDocumento: '',
                tipoDocumentoConjuge: '',
                documentoConjuge: '',
                nomeConjuge: '',
                inconsistencia: '',
                conjuge: '',
                estadoCivil: '',
                uniaoEstavel: '',
                regimeBens: '',
                nomes: '',
                representantes: [],
                loading: false,
                loadingConjuge: false,
                opcaoInconsistencia: false,
                mascara: false,
                mascaraConjuge: false,
                conjuge: {
                    documento: '',
                    nome: '',
                    nacionalidade: '',
                    fracao: '',
                    valorFracional: '',
                    estadoCivil: '',
                    uniaoEstavel: '',
                    regimeBens: '',
                    classificacao: '',
                    tipoDocumento: '',
                    tipoDocumentoConjuge: '',
                    documentoConjuge: '',
                    nomeConjuge: '',
                    inconsistencia: '',
                    conjuge: '',
                    nomes: [],
                    loading: false,
                    loadingConjuge: false,
                    opcaoInconsistencia: false,
                    mascara: false,
                    mascaraConjuge: false
                }
            }

            this.$forceUpdate()

            /**
             * Reestrutura as averbações adicionadas
             *
             * @var {object} aDadosPartesAto
             */
            const aDadosPartesAto = this.form.ato[this.ato.indice]?.pessoal.map((pessoal, idx) => {
                return `${idx + 1} - ${pessoal.classificacao} ${pessoal.documento != '' ? ` - ${pessoal.documento}` : ''} ${pessoal.nome != '' ? ` - ${pessoal.nome}` : ''}`
            })

            /**
             * Define o select pela referencia
             *
             * @var {object} oSelectParteAto
             */
            const oSelectParteAto = this.$refs.selectParteAto

            oSelectParteAto.clearSelectize()
            oSelectParteAto.updateOptions(aDadosPartesAto)
            oSelectParteAto.clearSelectize()

            this.atualizarPartesSelecionadas()

            this.$nextTick(() => {
                /**
                 * Define o focus no input
                 *
                 * @var {Object} oInput
                 */
                const oInput = this.$refs.parte_ato;

                if (oInput && bFocar) {
                    oInput.querySelector('input').focus();
                }
            })
        },
        /**
         * Função para voltar a última página
         */
        voltar() {
            window.history.back()
        },
        /**
         * Função para enviar o registro
         */
        async enviar(iEnviar = 0) {
            this.form.adicionarAtos = true
            this.buscarMatricula = false

            /**
             * Realiza a contagem da porcentagem
             */
            const iContagemPorcentagem = await this.processarProprietarios(this.form.ato)
            
            this.modalConfimacaoPorcentagem = false

            if(iContagemPorcentagem > 100 && iEnviar == 0){
                this.modalConfimacaoPorcentagem = true

                return 
            }

            this.adicionarParteAto(false)
            this.adicionarAto(false)

            this.form.cns = `${process.env.VUE_APP_CODIGO_CNS}`
            this.form.usuario = this.autenticacao.usuario

            this.carregando = true

            /**
             * Define os atos
             *
             * @var {array} aAtos
             */
            const aAtos = this.form.ato

            this.form.ato = []

            /**
             * Realizar a autenticação
             */
            await autenticar(this)

            await axios.request({
                method: 'post',
                url: `${process.env.VUE_APP_ENDPOINT_API}/v1/indicadores`,
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                },
                data: {
                    dataBase64: Buffer.from(JSON.stringify(this.form)).toString('base64')
                }
            })
                .then(async (response) => {
                    /**
                     * Salva os atos a cada 100
                     */
                    for (let i = 0; i < aAtos.length; i += 100) {
                        await axios.request({
                            method: 'post',
                            url: `${process.env.VUE_APP_ENDPOINT_API}/v1/atos/${response.data.indicador}?naoRemover=1&parte=${i}&ultimo=${(i + 100 >= aAtos.length)}`,
                            headers: {
                                'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                            },
                            data: {
                                dataBase64: Buffer.from(JSON.stringify(aAtos.slice(i, i + 100)).toString('base64'))
                            }
                        })
                    }

                    toastAlert('Registro atualizado com sucesso', 'success')

                    this.form.ato = aAtos

                    await this.removeItem('form')

                    setTimeout(() => {
                        window.location.reload()
                    }, 250);
                })
                .catch((error) => {
                    /**
                     * Define a mensagem do alerta
                     *
                     * @var {string} sMensagem
                     */
                    let sMensagem = 'Erro ao salvar os atos'

                    this.form.ato = aAtos
                    this.carregando = false
                    this.buscarMatricula = true

                    toastAlert(`${sMensagem}${error.response.data.descricao ? `: ${error.response.data.descricao}` : ''}`)
                });
        },
        /**
         * Função para ir para a pesquisa
         */
        pesquisa() {
            window.location.href = '/pesquisa'
        },
        /**
         * Função para ir para a exportacao
         */
        exportacao() {
            window.location.href = '/exportacao'
        },
        /**
         * Função para ir para o cadastro
         */
        indicador() {
            window.location.href = '/indicadores'
        },
        /**
         * Função para ir para o cadastro
         */
        complemento() {
            window.location.href = '/complemento'
        },
        /**
         * Função para ir para os documentos
         */
        documentos() {
            window.location.href = '/documentos'
        },
        /**
         * Função para ir para os documentos
         */
        enderecos() {
            window.location.href = '/enderecos'
        },
        /**
         * Função para ir para os usuarios
         */
        usuarios() {
            window.location.href = '/usuarios'
        },
        /**
         * Função para ir para as estatisticas
         */
        estatisticas() {
            window.location.href = '/estatisticas'
        },
        /**
         * Função para sair da aplicação
         */
        sair() {
            sessionStorage.removeItem('access_token')

            window.location.href = '/'
        },
        /**
         * Função para verificar se a matrícula já está cadastrada
         */
        async verificarRegistro() {
            this.carregando = true

            /**
             * Realizar a autenticação
             */
            await autenticar(this)

            axios.request({
                method: 'get',
                url: `${process.env.VUE_APP_ENDPOINT_API}/v1/verificarRegistro/${sessionStorage.getItem('numeroRegistro')}?tipoRegistro=${sessionStorage.getItem('tipoRegistro')}`,
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                }
            })
                .then(async (response) => {
                    response.data.ato = []
                    response.data.quantidadeAtos = response.data.quantidadeAtos ?? 0

                    for (var iPagina = 0; iPagina < Math.ceil(response.data.quantidadeAtos / 100); iPagina++) {
                        await axios.request({
                            method: 'get',
                            url: `${process.env.VUE_APP_ENDPOINT_API}/v1/atos/${response.data._id}?pagina=${iPagina}&registrosPorPagina=100`,
                            headers: {
                                'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                            }
                        })
                            .then((responseAto) => {
                                response.data.ato = [...response.data.ato, ...responseAto.data.dados]
                            })
                    }

                    if (response.data.ato.length > 0) {
                        response.data.ato.sort((a, b) => a.numeroAto - b.numeroAto)

                        
                        response.data.ato = response.data.ato.map(oAto => ({
                            ...oAto,
                            cadastrado: 1
                        }))
                    }
                 
                    this.$set(this, 'form', JSON.parse(JSON.stringify(response.data)))

                    this.cancelarEdicaoAto(false)
                    this.cancelarEdicaoParteAto(false)

                    this.ato.indice = this.listaAtos.length

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelect
                     */
                    const oSelect = this.$refs.select;

                    for (const sChave in oSelect) {
                        if (oSelect.hasOwnProperty(sChave)) {
                            /**
                             * Define o selectize e o valor
                             *
                             * @var {object} oSelectize
                             * @var {string} sValor
                             */
                            const oSelectize = oSelect[sChave][0].selectize;
                            const sValor = this.form[sChave];

                            oSelectize.setValue(sValor);
                        }
                    }

                    this.loading.numeroRegistro = false
                    this.carregando = false
                    this.rerenderKey = this.rerenderKey + 1

                    this.$forceUpdate()
                })
                .catch((error) => {
                    this.loading.numeroRegistro = false
                    this.carregando = false
                    this.form.cnm = ''
                    this.listaAtos = []
                });
        },
        /**
         * Função para cancular a expiracao
         */
        calcularExpiracao() {
            const dataAtualUnix = Math.floor(Date.now() / 1000)
            const diferencaSegundos = this.autenticacao.exp - dataAtualUnix

            if (diferencaSegundos <= 10) {
                this.contagemRegressivaClass = 'danger'
            } else {
                this.contagemRegressivaClass = 'default'
            }

            if (diferencaSegundos <= 0) {
                clearInterval(this.expiracaoInterval)
                this.contagemRegressiva = "Sessão expirada"
            } else {
                const horas = Math.floor((diferencaSegundos % 86400) / 3600)
                const minutos = Math.floor((diferencaSegundos % 3600) / 60)
                const segundos = diferencaSegundos % 60

                this.contagemRegressiva = `A sessão expira em ${horas < 10 ? '0' + horas : horas}:${minutos < 10 ? '0' + minutos : minutos}:${segundos < 10 ? '0' + segundos : segundos}`
            }
        },
        /**
         * Função para atualizar o token
         */
        async refreshToken() {
            /**
            * Define os headers da requisição
            * 
            * @var {object} oHeaders
            */
            const oHeaders = new Headers()
            oHeaders.append("Content-Type", "application/json")

            /**
            * Define os opções da requisição
            * 
            * @var {object} oOpcoes
            */
            const oOpcoes = {
                method: 'POST',
                headers: oHeaders,
                mode: 'cors',
                redirect: 'follow',
                body: JSON.stringify({ token: this.autenticacao.refresh_token })
            }

            /**
            * Realiza a requisição para buscar os detalhes do usuario
            * 
            * @var {object} oLogin
            */
            const oLogin = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/refreshToken`, oOpcoes)
                .then(function (response) {
                    if (!response.ok) {
                        return response.json().then(error => {
                            if (typeof error.codigo == 'string') {
                                error.codigo = `${error.codigo} -`
                            } else {
                                error.codigo = ''
                            }

                            toastAlert(`${error.codigo} ${error.descricao}`)

                            return false
                        });
                    }

                    return response.json()
                })
                .then(function (response) {
                    if (!response) {
                        return false
                    }

                    return response
                })

            if (!oLogin) {
                toastAlert('Sua sessão expirou, redirecionando para a autenticação...')

                setTimeout(() => {
                    /**
                    * Desloga da aplicação
                    */
                    this.sair()
                }, 2000)

                /**
                * Define os dados nas variaveis
                */
                this.autenticacao.autenticado = false

                return false
            }

            if (oLogin?.AccessToken) {
                sessionStorage.setItem('access_token', oLogin.AccessToken)
            }

            /**
            * Realizar a autenticação
            */
            await autenticar(this)

            /**
            * Realiza a contagem da expiração
            */
            this.calcularExpiracao()
            this.expiracaoInterval = setInterval(this.calcularExpiracao, 1000)
        },
        /**
         * Grava os dados do indexedDb
         */
        async setItem(id, dados) {
            try {
                const db = await dbPromise
                const tx = db.transaction('dados', 'readwrite')
                const store = tx.objectStore('dados')
                await store.put({ id, valor: dados })
                await tx.done
            } catch (error) {
                console.error('Erro ao armazenar dados no IndexedDB', error)
            }
        },
        /**
         * Pega os dados do indexedDb
         */
        async getItem(id) {
            try {
                const db = await dbPromise
                const tx = db.transaction('dados', 'readonly')
                const store = tx.objectStore('dados')
                const data = await store.get(id)
                await tx.done
                return data ? JSON.parse(JSON.stringify(data.valor)) : false
            } catch (error) {
                console.error('Erro ao recuperar dados do IndexedDB', error)
                return false
            }
        },
        /**
         * Remove os dados do indexedDb
         */
        async removeItem(id) {
            try {
                const db = await dbPromise
                const tx = db.transaction('dados', 'readwrite')
                const store = tx.objectStore('dados')
                await store.delete(id)
                await tx.done
            } catch (error) {
                console.error('Erro ao remover dados do IndexedDB', error)
            }
        },
        /**
         * Função para definir a inconsistência da parte
         */
        definirInconsistencia(sTipo = 'ato', iDesativar = 0){
            /**
             * Define o select pela referencia
             *
             * @var {object} oSelect
             */
             const oSelect = this.$refs.selectInconsistenciaAto

            oSelect.clearSelectize()
            oSelect.updateOptions({
                '1': '1 - CPF com inconsistência',
                '2': '2 - CIC em conjunto',
                '3': '3 - Nome diferente',
                '4': '4 - CIC dependente',
                '5': '5 - CPF dependente',
                '6': '6 - CNPJ com inconsistência',
                '7': '7 - Sem CPF (Decisão Judicial)',
                '8': '8 - Sem CNPJ (Decisão Judicial)',
                '9': '9 - Não consta no documento'
            })
            oSelect.clearSelectize()

            /**
             * Define os dados do formulario
             * 
             * @var object oDados
             */
                let oDados = {}

            if(sTipo == 'descricaoRegistroAuxiliar'){
                oDados = this.averbacao
            }else if(sTipo == 'registroAuxiliar'){
                oDados = this.descricaoRegistroAuxiliar
                sTipo = 'descricaoRegistroAuxiliar'
            }else{
                oDados = this.ato
            }

            oDados.pessoal.inconsistencia = ''

            if(iDesativar == 1){
                oDados.pessoal.opcaoInconsistencia = true
            }else if(iDesativar == 2){
                oDados.pessoal.opcaoInconsistencia = false
            }else{
                oDados.pessoal.opcaoInconsistencia = !oDados.pessoal.opcaoInconsistencia
            }
        },
        /**
         * Função para definir a inconsistência da parte
         */
        definirInconsistenciaConjuge(sTipo = 'ato', iDesativar = 0){
            /**
             * Define os dados do formulario
             * 
             * @var object oDados
             */
                let oDados = {}

            if(sTipo == 'descricaoRegistroAuxiliar'){
                oDados = this.averbacao
            }else if(sTipo == 'registroAuxiliar'){
                oDados = this.descricaoRegistroAuxiliar
                sTipo = 'descricaoRegistroAuxiliar'
            }else{
                oDados = this.ato
            }

            oDados.pessoal.conjuge.inconsistencia = ''

            if(iDesativar == 1){
                oDados.pessoal.conjuge.opcaoInconsistencia = true
            }else if(iDesativar == 2){
                oDados.pessoal.conjuge.opcaoInconsistencia = false
            }else{
                oDados.pessoal.conjuge.opcaoInconsistencia = !oDados.pessoal.conjuge.opcaoInconsistencia
            }
        },
        /**
         * Função para verificar se o documento é um CPF
         */      
        verificaCpfCnpj(sValor) {
            if(!sValor || sValor.length == 0){
                return undefined
            }

            if(sValor.length < 14){
                return true
            }

            return /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(sValor)
        },
        /**
         * Função para adicionar um novo representante da parte
         */
        adicionarRepresentante(){
            if(!this.ato.pessoal.representantes){
                this.ato.pessoal.representantes = []
            }
            
            this.ato.pessoal.representantes.push({
                classificacao: '',
                documento: '',
                nome: '',
                loading: false
            })
        },
        /**
         * Função para remover um representante da parte
         */
        removerRepresentante(iIndice){
            this.ato.pessoal.representantes = [...this.ato.pessoal.representantes]
            this.ato.pessoal.representantes.splice(iIndice, 1)
        },
        /**
         * Função para buscar o documento digitado
         */
        async buscarDocumentoRepresentante(sDocumento, iIndice){                
            if(/\./.test(sDocumento) && (sDocumento.replace(/[^\w\s]/gi, "").length == 11 || sDocumento.replace(/[^\w\s]/gi, "").length == 14)){
                this.ato.pessoal.representantes[iIndice].loading = true

                await this.$forceUpdate()
                
                /**
                 * Consulta o documento
                 *
                 * @var {Object} oDadosDocumento
                 */
                const oDadosDocumento = await this.consultarDocumento(sDocumento.replace(/[^\w\s]/gi, ""))
                
                if(oDadosDocumento != null){
                    this.ato.pessoal.representantes[iIndice].nome = oDadosDocumento.nome ?? oDadosDocumento.nomeEmpresarial
                    this.documentosBuscados[sDocumento.replace(/[^\w\s]/gi, "")] = this.ato.pessoal.representantes[iIndice].nome
                }else{
                    this.ato.pessoal.representantes[iIndice].nome = ''
                }

                this.ato.pessoal.representantes[iIndice].loading = false
            }
        },
        /**
         * Função para adicionar um novo nome da parte
         */
        adicionarNome(sTipo, sRef){
            /**
             * Define os dados do formulario
             *
             * @var {Object} oDados
             */
            let oDados = {}

            if(sTipo == 'descricaoRegistroAuxiliar'){
                oDados = this.averbacao.pessoal
            }else if(sTipo == 'registroAuxiliar'){
                oDados = this.descricaoRegistroAuxiliar.pessoal
                sTipo = 'descricaoRegistroAuxiliar'
            }else{
                oDados = this.ato.pessoal
            }

            if(!oDados.nomes){
                oDados.nomes = []
            }
            
            oDados.nomes.push(oDados.nome)

            setTimeout(() => {
                /**
                 * Define o focus no input
                 *
                 * @var {Object} oInput
                 */
                const oInput = this.$refs[`${sRef}_${oDados.nomes.length - 1}`][0].$refs.input

                if (oInput) {
                    oInput.focus();
                }
            }, 100)
        },
        /**
         * Função para remover um nome da parte
         */
        removerNome(sTipo, iIndiceNome){
            /**
             * Define os dados do formulario
             *
             * @var {Object} oDados
             */
            let oDados = {}

            if(sTipo == 'descricaoRegistroAuxiliar'){
                oDados = this.averbacao.pessoal
            }else if(sTipo == 'registroAuxiliar'){
                oDados = this.descricaoRegistroAuxiliar.pessoal
                sTipo = 'descricaoRegistroAuxiliar'
            }else{
                oDados = this.ato.pessoal
            }

            oDados.nomes = [...oDados.nomes]
            oDados.nomes.splice(iIndiceNome, 1)
        },
        /**
         * Função para adicionar um novo nome do conjuge
         */
        adicionarNomeConjuge(sTipo, sRef){
            /**
             * Define os dados do formulario
             *
             * @var {Object} oDados
             */
            let oDados = {}

            if(sTipo == 'descricaoRegistroAuxiliar'){
                oDados = this.averbacao.pessoal.conjuge
            }else if(sTipo == 'registroAuxiliar'){
                oDados = this.descricaoRegistroAuxiliar.pessoal.conjuge
                sTipo = 'descricaoRegistroAuxiliar'
            }else{
                oDados = this.ato.pessoal.conjuge
            }

            if(!oDados.nomes){
                oDados.nomes = []
            }
            
            oDados.nomes.push(oDados.nome)

            setTimeout(() => {
                /**
                 * Define o focus no input
                 *
                 * @var {Object} oInput
                 */
                const oInput = this.$refs[`${sRef}_${oDados.nomes.length - 1}`][0].$refs.input

                if (oInput) {
                    oInput.focus();
                }
            }, 100)
        },
        /**
         * Função para remover um nome do conjuge
         */
        removerNomeConjuge(sTipo, iIndiceNome){
            /**
             * Define os dados do formulario
             *
             * @var {Object} oDados
             */
            let oDados = {}

            if(sTipo == 'descricaoRegistroAuxiliar'){
                oDados = this.averbacao.pessoal.conjuge
            }else if(sTipo == 'registroAuxiliar'){
                oDados = this.descricaoRegistroAuxiliar.pessoal.conjuge
                sTipo = 'descricaoRegistroAuxiliar'
            }else{
                oDados = this.ato.pessoal.conjuge
            }

            oDados.nomes = [...oDados.nomes]
            oDados.nomes.splice(iIndiceNome, 1)
        },
        /**
         * Função para buscar o documento digitado
         */
        async buscarDocumento(sDocumento, sTipo = 'ato', bConjuge = false, bAlertar = false){
            /**
             * Define os dados do formulario
             * 
             * @var object oDados
             */
            let oDados = {}

            if(sTipo == 'descricaoRegistroAuxiliar'){
                oDados = this.averbacao
            }else if(sTipo == 'registroAuxiliar'){
                oDados = this.descricaoRegistroAuxiliar
                sTipo = 'descricaoRegistroAuxiliar'
            }else if(sTipo == 'indisponibilidade'){
                oDados = this.indisponibilidade
                sTipo = 'indisponibilidade'

                oDados.tipoDocumento = 'CPF'
            }else{
                oDados = this.ato
            }

            if(bConjuge){
                oDados = oDados.pessoal.conjuge
            }else{
                if(sTipo != 'indisponibilidade'){
                    oDados = oDados.pessoal
                }
            }

            // Define a máscara com base no comprimento do documento digitado
            if(oDados.tipoDocumento == 'CPF' && sDocumento.replace(/[^\w\s]/gi, "").length == 11){
                oDados.mascara = '###.###.###-##'
            }else if(oDados.tipoDocumento == 'CNPJ' && sDocumento.replace(/[^\w\s]/gi, "").length == 14){
                oDados.mascara = '##.###.###/####-##'
            }else if(oDados.tipoDocumento == 'RG' || oDados.tipoDocumento == 'OUTROS'){
                oDados.mascara = 'XXXXXXXXXXXXXXXXXXXXXXXXX'
            }else{
                oDados.mascara = '###################'
            }
            
            if(oDados.tipoDocumento == 'CPF' || oDados.tipoDocumento == 'CNPJ'){
                oDados.loading = true

                /**
                 * Consulta o documento
                 *
                 * @var {Object} oDadosDocumento
                 */
                const oDadosDocumento = await this.consultarDocumento(sDocumento.replace(/[^\w\s]/gi, ""))
                
                if(oDadosDocumento != null){
                    oDados.nome = oDadosDocumento.nome ?? oDadosDocumento.nomeEmpresarial
                    this.documentosBuscados[sDocumento.replace(/[^\w\s]/gi, "")] = oDados.nome
                }else{
                    if(sTipo != 'indisponibilidade'){
                        oDados.nome = ''
                    }
                }
            
                if(sDocumento.replace(/[^\w\s]/gi, "").length != 11 && sDocumento.replace(/[^\w\s]/gi, "").length != 14 && bAlertar){
                    toastAlert('Documento não encontrado', 'warning')
                    this.definirInconsistencia('ato')
                }

                oDados.loading = false
            }
        },
        /**
         * Função para buscar o documento do conjuge digitado
         */
        async buscarDocumentoConjuge(sDocumento, sTipo = 'ato', bConjuge = false, bAlertar = false){
            /**
             * Define os dados do formulario
             * 
             * @var object oDados
             * @var object oForm
             */
            let oDados = {}
            let oForm = {}

            if(sTipo == 'descricaoRegistroAuxiliar'){
                oDados = this.averbacao
                oForm = this.form.descricaoRegistroAuxiliar.averbacoes[this.averbacao.indice]
            }else if(sTipo == 'registroAuxiliar'){
                oDados = this.descricaoRegistroAuxiliar
                sTipo = 'descricaoRegistroAuxiliar'
                oForm = this.form.descricaoRegistroAuxiliar[this.descricaoRegistroAuxiliar.indice]
            }else{
                oDados = this.ato
                oForm = this.form.ato[this.ato.indice]
            }

            if(bConjuge){
                oDados = oDados.pessoal.conjuge
            }else{
                oDados = oDados.pessoal
            }

            // Define a máscara com base no comprimento do documento digitado
            if(oDados.tipoDocumentoConjuge == 'CPF' && sDocumento.replace(/[^\w\s]/gi, "").length == 11){
                oDados.mascaraConjuge = '###.###.###-##'
            }else if(oDados.tipoDocumento == 'RG' || oDados.tipoDocumento == 'OUTROS'){
                oDados.mascara = 'XXXXXXXXXXXXXXXXXXXXXXXXX'
            }else{
                oDados.mascaraConjuge = '###################'
            }

            if(oDados.tipoDocumentoConjuge == 'CPF'){
                oDados.loadingConjuge = true

                /**
                 * Consulta o documento
                 *
                 * @var {Object} oDadosDocumento
                 */
                const oDadosDocumento = await this.consultarDocumento(sDocumento.replace(/[^\w\s]/gi, ""))
                
                if(oDadosDocumento != null){
                    oDados.nomeConjuge = oDadosDocumento.nome ?? oDadosDocumento.nomeEmpresarial

                    if(!bConjuge){
                        if(sTipo == 'descricaoRegistroAuxiliar'){
                            // this.adicionarConjugeAverbacao()
                        }else if(sTipo == 'registroAuxiliar'){
                            // this.adicionarConjugeRA()
                        }else{
                            this.adicionarConjugeAto()
                        }
                    }
                }else{
                    oDados.nomeConjuge = ''
                }
            
                if(sDocumento.replace(/[^\w\s]/gi, "").length != 11 && bAlertar){
                    toastAlert('Documento não encontrado', 'warning')
                }

                oDados.loadingConjuge = false

                if(oDados.conjuge){
                    return
                }
                
                if(oForm?.pessoal && oForm.pessoal.filter(obj => oDados.documentoConjuge == obj.documento).length > 0){
                    return
                }
            }
        },
        /**
         * Função para adicionar o conjuge manualmente
         */
        adicionarConjugeAto(){
            if(this.ato.pessoal.edicao){
                return
            } 

            this.ato.pessoal.conjuge = {
                classificacao: this.ato.pessoal.classificacao, 
                nacionalidade: this.ato.pessoal.nacionalidade,
                fracao: '',
                valorFracional: '',
                indicadorConjugeParticipa: '',
                uniaoEstavel: this.ato.pessoal.uniaoEstavel, 
                inconsistencia: '',
                regimeBens: this.ato.pessoal.regimeBens, 
                estadoCivil: this.tiposEstadosCivilOposto[this.ato.pessoal.estadoCivil], 
                tipoDocumento: this.ato.pessoal.tipoDocumentoConjuge, 
                documento: this.ato.pessoal.documentoConjuge, 
                nome: this.ato.pessoal.nomeConjuge, 
                tipoDocumentoConjuge: this.ato.pessoal.tipoDocumento, 
                documentoConjuge: this.ato.pessoal.documento, 
                nomeConjuge: this.ato.pessoal.nome,
                conjuge: true,
                nomes: [],
                loading: false,
                loadingConjuge: false,
                opcaoInconsistencia: false,
                mascara: false,
                mascaraConjuge: false
            }
        },
        /**
         * Função para consultar os dados do documento
         */
        async consultarDocumento(sValor){
            if(!cpf.isValid(sValor) && !cnpj.isValid(sValor)){
                return null
            }

            if(this.documentosBuscados[sValor]){
                return {
                    nome: this.documentosBuscados[sValor],
                    nomeEmpresarial: this.documentosBuscados[sValor]
                }
            }

            return await axios.request({
                method: 'get',
                url: `${process.env.VUE_APP_ENDPOINT_CONSULTA_DOCUMENTO}/v1/consultar/${sValor}`,
                headers: { 
                    'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                }
            })
            .then((response) => {
                if(response.data.ni){
                    return response.data
                }   

                return null
            })
            .catch((error) => {
                return null
            })                   
        },
        /**
         * Função para definir o mês e ano da ultima parcela
         */
        definirUltimaParcela(){
            if(this.ato.primeiraDataParcela == '' || this.ato.numeroParcelas == ''){
                return
            }

            this.ato.mesAnoUltimaParcela = moment(this.ato.primeiraDataParcela, "DD/MM/YYYY").add((this.ato.numeroParcelas - 1), 'months').format('DD/MM/YYYY')
        },
        /**
         * Função para copiar o ato
         */
        copiarAto(iIndiceAto, bAbrirModal = true){
            if(bAbrirModal){
                this.indiceAto = iIndiceAto
                this.modalCopiarAto = true
                return
            }

            if(this.numeroAtoInicial == ''){
                toastAlert('Informe o número do ato inicial')
                return
            }

            if(this.numeroAtoFinal == ''){
                toastAlert('Informe o número do ato final')
                return
            }

            if(parseInt(this.numeroAtoInicial) > parseInt(this.numeroAtoFinal)){
                toastAlert('O número do ato inicial não pode ser maior que o número do ato final')
                return
            }

            if(parseInt(this.numeroAtoFinal) - parseInt(this.numeroAtoInicial) > 1000){
                toastAlert('É permitido apenas a cópia de 1000 atos por vez')
                return
            }

            if(this.dataRegistro == ''){
                toastAlert('Informe a data de registro')
                return
            }
                
            this.carregando = true
            this.modalCopiarAto = false    

            const worker = new Worker('../../copiarAto.js')
            const that = this

            worker.addEventListener('message', function (e) {
                const { form, listaAtos, ato } = e.data

                /**
                 * Define o select pela referencia
                 *
                 * @var {object} oSelectAto
                 */
                const oSelectAto = that.$refs.selectAto

                /**
                 * Reestrutura as averbações adicionadas
                 *
                 * @var {object} aDadosAto
                 */
                const aDadosAto = listaAtos.map((atoUnico, idx) => {
                    const option = document.querySelectorAll("div.column.is-half.listaAtos > div > div > div.selectize-dropdown.single > div > div")
            
                    if(atoUnico.onus == 1 && option[idx]){
                        option[idx].classList.add('onusAtivo')
                    }

                    return `${ atoUnico.numeroAto } ${ atoUnico.descricao != '' ? '-' : '' } ${ atoUnico.descricao } ${ that.tiposAto[atoUnico.tipo] ? ` - ${that.tiposAto[atoUnico.tipo].split(' - ')[1]}` : '' } ${ atoUnico.descricaoAdicional ? ` - ${atoUnico.descricaoAdicional}` : '' } ${ atoUnico.onus == 1 ? ` - Ônus ativo` : '' }`
                })
                        
                oSelectAto.clearSelectize()
                oSelectAto.updateOptions(aDadosAto)
                oSelectAto.clearSelectize()

                if(!that.listaAtos[that.indiceAto]){
                    ato.indice = form.ato.length
                }

                that.$set(that, 'form', form)
                that.$set(that, 'listaAtos', listaAtos)
                that.$set(that, 'ato', ato)
                that.$set(that, 'numeroAtoInicial', '')
                that.$set(that, 'numeroAtoFinal', '')
                that.$set(that, 'dataRegistro', '')
                that.$set(that, 'transferirPartes', true)
                that.$set(that, 'carregando', false)

                that.cancelarEdicaoAto()
            })            
            
            worker.postMessage({ sTipo: 'ato', oForm: this.form, ato: this.ato, listaAtos: this.listaAtos, indiceAto: this.indiceAto, numeroAtoInicial: this.numeroAtoInicial, numeroAtoFinal: this.numeroAtoFinal, dataRegistro: this.dataRegistro, transferirPartes: this.transferirPartes }) 
        },
        /**
         * Função para remover um ato
         */
        removerAto(iIndice, bAbrirModal = false){
            if(bAbrirModal){
                this.modalRemoverAto = true

                return
            }
                
            this.modalRemoverAto = false

            this.form.ato.splice(this.ato.indice, 1)
            this.listaAtos.splice(this.ato.indice, 1)

            toastAlert('Ato removido com sucesso', 'success')

            this.cancelarEdicaoAto(false)
        },
        /**
         * Função para selecionar a parte informada anteriormente
         */
        selecionarPartes(iIndiceAto) {
            this.indiceAto = iIndiceAto
            this.modalSelecionarPartes = true
        },
        /**
         * Função para selecionar a parte informada anteriormente
         */
        partesSelecionadas() {
            if(this.partesAdicionadas.filter(parte => parte.selecionado).length == 0){
                toastAlert('Selecione pelo menos uma parte')
                return
            }

            this.partesAdicionadas.forEach((parte, idx) => {
                if(parte.selecionado){
                    if(!this.form.ato[this.ato.indice]?.pessoal){
                        this.form.ato[this.ato.indice] = {
                            pessoal: []
                        }
                    }

                    this.form.ato[this.ato.indice].pessoal.push({
                        classificacao: this.form.ato[parte.ato].pessoal[parte.indice]?.classificacao ?? '', 
                        nacionalidade: this.form.ato[parte.ato].pessoal[parte.indice]?.nacionalidade ?? '',
                        fracao: this.form.ato[parte.ato].pessoal[parte.indice]?.fracao ?? '',
                        valorFracional: this.form.ato[parte.ato].pessoal[parte.indice]?.valorFracional ?? '',
                        indicadorConjugeParticipa: this.form.ato[parte.ato].pessoal[parte.indice]?.indicadorConjugeParticipa ?? '',
                        indicadorCpfConjugeIdentificado: this.form.ato[parte.ato].pessoal[parte.indice]?.indicadorCpfConjugeIdentificado ?? '',
                        uniaoEstavel: this.form.ato[parte.ato].pessoal[parte.indice]?.uniaoEstavel ?? '', 
                        inconsistencia: this.form.ato[parte.ato].pessoal[parte.indice]?.inconsistencia ?? '',
                        regimeBens: this.form.ato[parte.ato].pessoal[parte.indice]?.regimeBens ?? '', 
                        estadoCivil: this.form.ato[parte.ato].pessoal[parte.indice]?.estadoCivil ?? '', 
                        tipoDocumento: this.form.ato[parte.ato].pessoal[parte.indice]?.tipoDocumento ?? '', 
                        documento: this.form.ato[parte.ato].pessoal[parte.indice]?.documento ?? '', 
                        nome: this.form.ato[parte.ato].pessoal[parte.indice]?.nome ?? '', 
                        nomes: this.form.ato[parte.ato].pessoal[parte.indice]?.nomes ?? [], 
                        representantes: this.form.ato[parte.ato].pessoal[parte.indice]?.representantes ?? [], 
                        tipoDocumentoConjuge: this.form.ato[parte.ato].pessoal[parte.indice]?.tipoDocumentoConjuge ?? '', 
                        documentoConjuge: this.form.ato[parte.ato].pessoal[parte.indice]?.documentoConjuge ?? '', 
                        nomeConjuge: this.form.ato[parte.ato].pessoal[parte.indice]?.nomeConjuge ?? '',
                        conjuge: this.form.ato[parte.ato].pessoal[parte.indice]?.conjuge ?? false
                    })

                    this.$forceUpdate()                        

                    /**
                     * Reestrutura as averbações adicionadas
                     *
                     * @var {object} aDadosPartesAto
                     */
                    const aDadosPartesAto = this.form.ato[this.ato.indice].pessoal.map((pessoal, indiceParte) => {
                        return `${indiceParte+1} - ${pessoal.classificacao} ${ pessoal.documento != '' ? ` - ${pessoal.documento}` : ''} ${ pessoal.nome != '' ? ` - ${pessoal.nome}` : ''}`
                    })

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelectParteAto
                     */
                    const oSelectParteAto = this.$refs.selectParteAto
                    
                    oSelectParteAto.clearSelectize()
                    oSelectParteAto.updateOptions(aDadosPartesAto)
                    oSelectParteAto.clearSelectize()

                    this.ato.pessoal.indice = parseInt(this.ato.pessoal.indice) + parseInt(1)

                    document.getElementById(`checkbox-${idx}`).click()
                }
            })

            this.modalSelecionarPartes = false

            toastAlert('Partes adiconadas com sucesso', 'success')
        },
        /**
         * Função para definir o ato
         */
        definirAto(){
            /**
             * Define os atos
             * 
             * @var {array} aAtos
             */
            const aAtos = ['11','15','41','37','19','53','55','27','13','101','102','45','49','242','209','252']

            if(aAtos.includes(this.ato.tipo) && this.ato.tipoTransacao == ''){
                this.ato.tipoTransacao = 'Total'
            }
        },
        /**
         * Função para definir a classificação
         */
        definirClassificacao(){
            /**
             * Define os atos
             * 
             * @var {array} aClassificacoes
             */
            const aClassificacoes = ['Proprietário','Adquirente','Transmitente']

            if(aClassificacoes.includes(this.ato.pessoal.classificacao) && (this.ato.pessoal.fracao == '' || this.ato.pessoal.fracao == '0,00')){
                this.ato.pessoal.fracao = 10000
            }
        },
        /**
         * Função para verificar os proprietários
         */
        async processarProprietarios(oAtos) {
            /**
             * Define as variaveis do processamento
             *
             * @var array aTransmitentes
             * @var array aAdquirentes
             * @var array aProprietarios
             */
            const aTransmitentes = {}
            const aAdquirentes = {}
            const aProprietarios = []
            const oAtosProprietarios = JSON.parse(JSON.stringify(oAtos))
            
            oAtosProprietarios.reverse().forEach((oAtoUnico) => {
                /**
                 * Define a parte trancionada
                 * 
                 * @var float fParteTrancionada
                 */
                let fParteTrancionada = 1

                if(oAtoUnico.porcentagemParticipacaoArea && oAtoUnico.porcentagemParticipacaoArea != '' && oAtoUnico.porcentagemParticipacaoArea != '0,00'){
                    fParteTrancionada = oAtoUnico.porcentagemParticipacaoArea.replace(',', '.')/100
                }

                oAtoUnico.pessoal.forEach((oParteUnica) => {
                    if(oParteUnica.classificacao == 'Transmitente'){
                        if(oParteUnica.fracao == ''){
                            oParteUnica.fracao = parseFloat(oAtoUnico.tipoTransacao == 'Parcial' ? 0 : 100) 
                        }

                        if (Number.isInteger(oParteUnica.fracao)) {
                            oParteUnica.fracao = String(oParteUnica.fracao)
                        }

                        if(aTransmitentes[`${oParteUnica.documento}${oParteUnica.nome}`]){
                            /**
                             * Calcula o valor da operação
                             *
                             * @var float fValor
                             */
                            let fValor = parseFloat(aTransmitentes[`${oParteUnica.documento}${oParteUnica.nome}`]) + (oAtoUnico.tipoTransacao == 'Total' ? 100 : parseFloat(oParteUnica.fracao.replace('%', '').replace(',', '.') * fParteTrancionada))

                            if(fValor > 100){
                                fValor = 100
                            }

                            aTransmitentes[`${oParteUnica.documento}${oParteUnica.nome}`] = fValor
                        }else{
                            aTransmitentes[`${oParteUnica.documento}${oParteUnica.nome}`] = oAtoUnico.tipoTransacao == 'Total' ? 100 : parseFloat(oParteUnica.fracao.replace('%', '').replace(',', '.') * fParteTrancionada)
                        }                        
                    }

                    if(oParteUnica.classificacao == 'Proprietário' || oParteUnica.classificacao == 'Adquirente'){
                        if(oParteUnica.fracao == ''){
                            oParteUnica.fracao = parseFloat(100)
                        }
                        
                        if (Number.isInteger(oParteUnica.fracao)) {
                            oParteUnica.fracao = String(oParteUnica.fracao)
                        }

                        if(aAdquirentes[`${oParteUnica.documento}${oParteUnica.nome}`]){
                            /**
                             * Define os proprietarios
                             *
                             * @var float fValor
                             */
                            let fValor = parseFloat(aAdquirentes[`${oParteUnica.documento}${oParteUnica.nome}`]) + (parseFloat(oParteUnica.fracao.replace('%', '').replace(',', '.') * fParteTrancionada))

                            if(fValor > 100){
                                fValor = 100
                            }

                            aAdquirentes[`${oParteUnica.documento}${oParteUnica.nome}`] = fValor
                        }else{
                            aAdquirentes[`${oParteUnica.documento}${oParteUnica.nome}`] = parseFloat(oParteUnica.fracao.replace('%', '').replace(',', '.') * fParteTrancionada)
                        }   
                    }
                })
            })
        
            for (const sDocumento in aAdquirentes) {
                if(aAdquirentes[sDocumento] - (aTransmitentes[sDocumento] ?? 0) > 0){
                    oAtos.forEach((oAtoUnico) => {
                        /**
                         * Define a parte trancionada
                         * 
                         * @var float fParteTrancionada
                         */
                        let fParteTrancionada = 1

                        if(oAtoUnico.porcentagemParticipacaoArea && oAtoUnico.porcentagemParticipacaoArea != '' && oAtoUnico.porcentagemParticipacaoArea != '0,00'){
                            fParteTrancionada = oAtoUnico.porcentagemParticipacaoArea.replace(',', '.')/100
                        }
                        
                        oAtoUnico.pessoal.forEach((oParteUnica) => {
                            if (`${oParteUnica.documento}${oParteUnica.nome}` == sDocumento && (oParteUnica.classificacao == 'Proprietário' || oParteUnica.classificacao == 'Adquirente')) {
                                if (!aProprietarios.some(proprietario => `${proprietario.documento}${proprietario.nome}` == `${oParteUnica.documento}${oParteUnica.nome}`)) {
                                    aProprietarios.push({
                                        nome: oParteUnica.nome,
                                        documento: oParteUnica.documento,
                                        fracao: aAdquirentes[sDocumento] - (aTransmitentes[sDocumento] ?? 0)
                                    })
                                }
                            }
                        })
                    })
                }
            }
            
            /**
             * Realiza a contagem da porcentagem
             */
            return aProprietarios.reduce((acc, item) => acc + item.fracao, 0) ?? 0
        }
    }
}
</script>

<style>
html {
    overflow: auto !important;
    background-color: #eee !important;
}

.title:not(:last-child),
.subtitle:not(:last-child) {
    margin-bottom: 0.5em;
}

.div-principal {
    height: 100vh;
    overflow: hidden;
}

.barra-superior {
    margin: 0;
    margin-top: -1px !important;
    height: 6rem !important;
    background: #fff !important;
    border-right: 1px solid #E1E1E1;
    border-bottom: 1px solid rgb(200, 200, 200);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.barra-superior .gg-search {
    position: absolute;
    margin: 9px 27px;
    transform: scale(var(--ggs, 0.6));
}

.barra-superior input {
    width: 100%;
    border: none;
    margin: 0;
    height: 100%;
    outline: none;
    padding-left: 50px;
    color: gray;
}

.barra-superior-filtro {
    height: 35px;
}

.barra-superior button {
    margin: 0 !important;
    font-size: 0.8rem;
}

.barra-superior span {
    line-height: 50px;
    margin-left: 25px;
    font-weight: 500;
}

.indicador {
    top: -16px !important;
    padding-top: 12px;
    padding: 0;
    background: #eee !important;
    position: relative;
    height: 100vh !important;
}

.indicadores {
    height: 83vh;
    margin: 10px 0;
    overflow-y: auto;
    display: grid;
}

.columns:last-child {
    margin: 0 -8px;
}

.section {
    padding: unset;
}

.container {
    max-width: unset;
    overflow-x: hidden;
    padding-bottom: 40px;
}

.lista-indicadores {
    display: none;
    justify-content: flex-end;
    flex-direction: column;
}

.formulario {
    margin: 0 auto;
    margin-top: 1rem !important;
    position: relative;
    width: 97vw !important;
    background: #fff;
    padding: 1rem 1rem;
    border-radius: 5px !important;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 0rem !important;
}

select,
.select {
    width: 100%;
}

.label {
    margin-top: 0.8rem
}

.btn-control {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    border-top: 1px solid #ddd;
    width: 97vw !important;
    padding: 1rem 2rem 0rem 2rem;
    margin-left: -1rem;
}

.selectize-input {
    padding: 10px 8px !important;
}

.selectize-control.multi>.selectize-input>* {
    width: 32%;
}

.full-multiselect>.control>.selectize-control.multi>.selectize-input>* {
    width: 75%;
}

.selectize-input>* {
    text-overflow: ellipsis;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    margin: -4px 0px;
}

.formulario .columns {
    flex-wrap: wrap;
    padding: 0.5rem;
    margin-top: -0.5rem !important;
    align-items: flex-end;
    padding-bottom: 15px;
}

.formulario .column {
    padding: 0 0.5rem !important;
    margin-top: 0rem !important;
}

.formulario .column.is-one-tenth,
.column.is-one-tenth-tablet {
    flex: none;
    width: 10%
}

.formulario .label {
    margin-top: 0.5rem;
    margin-bottom: 0.2rem !important;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}

.tabs {
    align-items: flex-end;
    margin-bottom: 0px;
    margin-left: -1rem;
    width: 97vw !important;
}

.tabs ul {
    padding-left: 2rem;
}

.formulario .numero {
    position: absolute;
    right: 25px;
    top: 1rem;
    font-size: 18px;
}

.formulario .registroAnterior,
.formulario .indisponibilidade {
    border-bottom: 1px solid #eee;
}

.btn-rotate>.icon {
    transform: rotate(270deg);
}

.modal-card {
    top: 70px;
    position: absolute;
}

.accordion {
    width: 100%;
}

.accordion-item {
    border: 1px solid #ddd;
    margin-bottom: 1rem;
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background-color: #f1f1f1;
    word-break: break-all;
}

.accordion-icon {
    font-size: 1.2rem;
}

.accordion-content {
    padding: 0.5rem;
    display: none;
    border-top: 1px solid #ddd;
}

.accordion-content.hide {
    display: none;
}

.accordion-content.show {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.linha-divisoria {
    border-top: 1px solid #ccc;
    padding-left: 1.5rem;
    line-height: 0rem;
    margin: 20px 0 10px;
    width: 100%;
}

.linha-divisoria span {
    background-color: white;
    padding: 0 10px;
}

.optionEndereco:hover {
    background-color: #f5fafd;
    color: #495c68;
    cursor: pointer;
}

.optionEndereco {
    padding: 5px 8px;
}

.onusAtivo {
    color: #ff5a5a !important;
    font-weight: bold;
}

.checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    line-height: 1.5;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

.checkbox:hover input~.checkmark {
    background-color: #ccc;
}

.checkbox input:checked~.checkmark {
    background-color: #006598;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked~.checkmark:after {
    display: block;
}

.checkbox .checkmark:after {
    left: 10px;
    top: 5px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.modal-card-body {
    max-height: 70vh;
}

.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Certifique-se de que a tela de loading está acima de outros elementos */
}

.loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    /* Cor do spinner */
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    /* Animação de rotação */
}

.hidden {
    display: none !important;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.is-2>div>.selectize-control.multi .selectize-input>div {
    width: 96%;
}

.dropdownContribuintes {
    display: none;
}

.remover {
    color: #FF5A5A;
    padding: 5px 8px;
}

.tabs.is-boxed li.is-active a {
    background-color: #3b85b4;
}

.tabs li.is-active a {
    color: #fff;
}
</style>